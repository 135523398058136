import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Footer from '../../components/ui/Footer';
import { goHome } from '../../actions/navigator';

class FooterContainer extends Component {
  render() {
    return <Footer goHome={this.props.goHome} />;
  }
}

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ goHome }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterContainer);
