import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setActivityDone } from "../../actions/activities";
import { withRouter } from "react-router-dom";
import Activity from "../../components/courses/Activity";

class ActivityContainer extends Component {
  state = {
    done: false
  };

  markActivityDone = () => {
    if (!this.lockClick) {
      this.lockClick = true;
      const {
        onActivityDone,
        setActivityDone,
        activity,
        courseId,
        preview
      } = this.props;
      if (!preview) {
        setActivityDone(courseId, activity.id).then(updatedBlocks => {
          if (updatedBlocks) {
            onActivityDone(activity.id, updatedBlocks);
          } else {
            this.lockClick = false;
          }
        });
      } else {
        this.setState({ done: true }, () => {
          this.lockClick = false;
        });
      }
    }
  };

  resolveActivityDone = activity => {
    const { preview } = this.props;
    if (preview) {
      return this.state.done;
    }
    return (
      activity &&
      activity.user_activities &&
      activity.user_activities.length > 0 &&
      activity.user_activities[0].done !== 0
    );
  };

  render() {
    const { activity, loading } = this.props;
    activity.done = this.resolveActivityDone(activity);
    return (
      <Activity
        activity={activity}
        loading={loading}
        onClick={() => this.markActivityDone()}
      />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setActivityDone }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActivityContainer)
);
