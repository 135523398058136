import React from 'react';
import H5pIframe from '../../../courses/H5pIframe';

const ActivityModuleH5pContent = ({ content }) => (
  <React.Fragment>
    <H5pIframe activity={content} />
  </React.Fragment>
);

export default ActivityModuleH5pContent;
