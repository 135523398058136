import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCurrent } from "../../actions/user";
import { appLoadToken } from "../../actions/app";
import { goHome } from "../../actions/navigator";
import { saveSurvey, nextStep, prevStep, resetSteps } from "../../actions/survey";
import SurveyStepper from "../../components/surveys/SurveyStepper";

class SurveyContainer extends Component {

  sendSurvey = (data) => {
    this.props.saveSurvey(data);
  };


  handleBackTohome = () => {
    this.props.goHome();
    this.props.resetSteps();
  };

  handleBack = () => {
    this.props.prevStep();
  };

  handleSkip = () => {
    this.props.nextStep();
  };

  handleReset = () => {
    this.props.resetSteps();
  };

  render() {
    const { step, error, loading } = this.props;
    return  <SurveyStepper onBack={this.handleBack} onSkip={this.handleSkip} onReset={this.handleReset} onBackToHome={this.handleBackTohome}  onSaveSurvey={this.sendSurvey} activeStep={step} error={error} loading={loading}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.surveyReducer
  }
};


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getCurrent, appLoadToken, goHome, saveSurvey, nextStep, prevStep, resetSteps  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyContainer);
