import {
  USER_LOGIN_END,
  USER_LOGIN_START,
  USER_READ_START,
  USER_READ_END,
  USER_LOGIN_FAILED,
  USER_LOGOUT_START,
  USER_LOGOUT_END,
  USER_LOGOUT_FAILED,
  USER_READ_FAILED,
  MY_COURSES_IN_PROGRESS_READ_START,
  MY_COURSES_IN_PROGRESS_READ_END,
  MY_COURSES_IN_PROGRESS_READ_FAILED,
  MY_COURSES_FINISHED_READ_START,
  MY_COURSES_FINISHED_READ_END,
  MY_COURSES_FINISHED_READ_FAILED,
  REGISTER_START,
  REGISTER_FAILED, REGISTER_END,
  USER_LOGIN_ERROR, APP_LOAD_TOKEN,
} from './index';
import api from '../api/api';
import { push } from 'connected-react-router';
import { HOME } from '../config/routes';
import {appLoadToken} from "./app";

export function idForwardingLogin(token) {
  return async dispatch => {
    dispatch({ type: USER_LOGIN_START });
    try {
      dispatch({ type: USER_LOGIN_END, payload: { token: token } });
      return true;
    } catch (e) {
      dispatch({ type: USER_LOGIN_FAILED, payload: e });
      return false;
    }
  };
}

export function wordpressLogin() {
  return async dispatch => {
    try {
      await api.Wordpress.login();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
    ;
  }
}


export function getCurrent() {
  return async dispatch => {
    try {
      dispatch({ type: USER_READ_START });
      const response = await api.withDispatch(dispatch).Auth.getLoggedUser();
      dispatch({ type: USER_READ_END, payload: { user: response.data } });
    } catch (e) {
      dispatch({ type: USER_READ_FAILED, payload: e });
    }
  };
}

export function logout() {
  return async dispatch => {
    dispatch({ type: USER_LOGOUT_START });
    try {
      dispatch({ type: USER_LOGOUT_END, payload: {} });
      dispatch({ type: 'RESET' });
      dispatch(push(HOME));
    } catch (e) {
      dispatch({ type: USER_LOGOUT_FAILED, payload: e });
    }
  };
}

export function loadMyInProgress(){
  return async dispatch => {
    dispatch({ type: MY_COURSES_IN_PROGRESS_READ_START });
    try{
      const response =  await api.withDispatch(dispatch).My.Courses.inProgress();
      dispatch({ type: MY_COURSES_IN_PROGRESS_READ_END, payload: { items: response.data } });
    }catch (e) {
      dispatch({
        type: MY_COURSES_IN_PROGRESS_READ_FAILED, payload: { message: e.toString() }
      });
    }
  };
}

export function loadMyFinished(){
  return async dispatch => {
    dispatch({ type: MY_COURSES_FINISHED_READ_START });
    try{
      const response =  await api.withDispatch(dispatch).My.Courses.completed();
      dispatch({ type: MY_COURSES_FINISHED_READ_END, payload: { items: response.data } });
    }catch (e) {
      dispatch({
        type: MY_COURSES_FINISHED_READ_FAILED, payload: { message: e.toString() }
      });
    }
  };
}

export function login(data) {
  return async dispatch => {
    dispatch({ type: USER_LOGIN_START });
    try {
      const response = await api.Auth.session(data);
      api.setToken(response.data.ott);
      await (wordpressLogin())();
      dispatch({ type: USER_LOGIN_END, payload: { token: response.data.ott }});
    }
    catch (e) {
      dispatch({ type: USER_LOGIN_ERROR, payload: e.data })
      console.error(e);
    }
  };
}

export function registerUser(data){
  return async dispatch => {
    dispatch({ type: REGISTER_START });
    try {
      const response = await api.Auth.registerUser(data);
      dispatch({ type: REGISTER_END, payload: { data: response.data } });
      return true;
    } catch (e) {
      dispatch({ type: REGISTER_FAILED,  payload: { message: e.message } });
    }
    return false;
  };
}

export function sendSms(email,phone){
  return async dispatch => {
    try {
      const response = await api.Auth.sendSms(email,phone);
      return response.data;
    } catch (e) {
      dispatch({ type: REGISTER_FAILED,  payload: { message: e.message } });
    }
    return false;
  };
}

export function reset(){
  return async dispatch => {
      dispatch({ type: REGISTER_FAILED,  payload: { message: false } });
  };
}
