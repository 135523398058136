import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import validator from 'validator';
import { CourseFormBasicData } from '../../../components/admin/course';
import { addSnackbar } from '../../../actions/snackbar';
import {
  createResource,
  updateResource,
  getResource,
  getResources,
  deleteResource
} from '../../../actions/admin';
import { goTo } from '../../../actions/navigator';
import {
  ADMIN_COURSES_BLOCK,
  ADMIN_EDIT,
  ADMIN_COURSES,
  router
} from '../../../config/routes';
import { SnackbarType } from '../../../config/constants';

class AdminCoursesBasicFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      required: false,
      recommended: false,
      comments_enabled: false,
      tags_list: false
    },
    errors: {}
  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData();
      this.setState({ isValid: true });
    }
  };

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.item && nextProps.item) {
      this.setState(
        {
          form: {
            ...nextProps.item,
            tags_list:
              nextProps.item &&
              nextProps.item.course_tags &&
              nextProps.item.course_tags.length
                ? nextProps.item.course_tags.map(tag => ({
                    id: tag.tag.id,
                    name: tag.tag.name
                  }))
                : [],
            categories:
              nextProps.item &&
              nextProps.item.course_categories &&
              nextProps.item.course_categories.length
                ? nextProps.item.course_categories.map(category => ({
                    id: category.category.id,
                    name: category.category.name
                  }))
                : []
          }
        },
        this.validateForm
      );
    }
  };

  validateBasicForm = () => {
    const { form } = this.state;
    let error = false;
    const errors = {
      image: '',
      title: '',
      description: '',
      categories: ''
    };

    if (!form.image) {
      errors.image = 'Debes completar este campo';
      error = true;
    }
    if (!form.title || validator.isEmpty(form.title.trim())) {
      errors.title = 'Debes completar este campo';
      error = true;
    } else if (!validator.isLength(form.title.trim(), { min: 2, max: 80 })) {
      errors.title = 'Este campo debe tener entre 2 y 80 caracteres.';
      error = true;
    }

    if (!form.description || validator.isEmpty(form.description.trim())) {
      errors.description = 'Debes completar este campo';
      error = true;
    } else if (
      !validator.isLength(form.description.trim(), {
        min: 2,
        max: 300
      })
    ) {
      errors.description = 'Este campo debe tener entre 2 y 300 caracteres.';
      error = true;
    }
    if (!(form.categories && form.categories.length)) {
      errors.categories = 'Debes completar este campo';
      error = true;
    }

    this.setState({ errors: errors, isValid: !error });
  };

  onChange = form => {
    this.setState({ form }, this.validateBasicForm);
  };

  handleSubmit = async () => {
    const {
      type,
      addSnackbar,
      createResource,
      isEdit,
      updateResource,
      item,
      goTo
    } = this.props;
    const { form } = this.state;
    const image = form.image && form.image.file ? form.image.file : undefined;
    const data = {
      ...form,
      image,
      file_extension: image ? form.image.file_extension : undefined,
      categories: form.categories.map(cat => cat.id),
      tags_list:
        form.tags_list && form.tags_list.length
          ? form.tags_list.map(tag => tag.name).join(',')
          : ''
    };
    this.setState({ isValid: false });
    try {
      const result = isEdit
        ? await updateResource(type, item.id, data)
        : await createResource(type, data);
      addSnackbar(
        `Curso ${isEdit ? 'editado' : 'creado'} exitosamente.`,
        SnackbarType.Success
      );
      goTo(
        router.getRoute(ADMIN_COURSES_BLOCK + ADMIN_EDIT, { id: result.id })
      );
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error);
    }
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_COURSES);
  };

  render() {
    const { form, ...results } = this.state;

    const props = {
      onSubmit: this.handleSubmit,
      onAttributeChange: this.onChange,
      results,
      form,
      onGoBack: this.onGoBack,
      ...this.props
    };

    return (
      <CourseFormBasicData
        {...props}
        title={this.props.isEdit ? 'EDITAR CURSO' : 'CREAR CURSO'}
      />
    );
  }
}

AdminCoursesBasicFormContainer.defaultProps = {
  type: 'courses'
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return { ...states[ownProps.keyName] };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResource,
      getResources,
      createResource,
      updateResource,
      deleteResource,
      addSnackbar,
      goTo
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCoursesBasicFormContainer);
