import React from 'react';
import { Preview } from '../../inputs/Dropzone';

const ActivityModuleImageContent = ({ content }) => (
  <div style={{ height: '22em' }}>
    <Preview contentFile={content.url} mainImage />
  </div>
);

export default ActivityModuleImageContent;
