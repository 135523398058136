import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RequiredActivitiesDialog from '../../components/courses/RequiredActivitiesDialog';

class RequiredActivitiesDialogContainer extends Component {
  render() {
    const { open, onDismiss = () => {}, headerColor , activity} = this.props;
    return (
      <RequiredActivitiesDialog
        activity={activity}
        open={open}
        onDismiss={onDismiss}
        headerColor={headerColor}
      />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequiredActivitiesDialogContainer)
);
