import {
  CATEGORY_READ_END,
  CATEGORY_READ_FAILED,
  CATEGORY_READ_START, OCCUPATIONS_READ_END, OCCUPATIONS_READ_FAILED, OCCUPATIONS_READ_START
} from '../actions';

let initialState = {
  error: null,
  loading: false,
  items: []
};


export default (state = initialState, action) => {
  switch (action.type) {
    case OCCUPATIONS_READ_END:
      const { items } = action.payload;
      return {
        loading: false,
        items: items,
        error: null
      };
    case OCCUPATIONS_READ_START:
      return {
        items: [],
        error: null,
        loading: true
      };
    case OCCUPATIONS_READ_FAILED:
      const { message } = action.payload;
      return {
        items: [],
        error: message,
        loading: false
      };
    default:
      return { ...state, error: null };
  }
};
