import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AdminResourceForm from '../AdminResourceForm';
import { EditInputType, EditInputObjectModel } from '../../../config/constants';

const MAX_SIZE = 10;

const styles = {
  button: {
    margin: '1em',
    padding: '1em 2em',
    borderRadius: '5px',
    border: '1px solid #575454',
    maxWidth: '16em'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '4em 0'
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  containerTextInput: {
    maxWidth: '60%'
  },
  epigraph: {
    color: '#ada9a9',
    margin: '1em auto'
  },
  mainContentContainer: {
    width: '92%',
    display: 'flex',
    flexDirection: 'column'
  },
  inputsContainer: {
    padding: '0 8rem'
  },
  mainImg: {
    width: '100%',
    height: '16em',
    background: '#969696'
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'start',
    width: '16em',
    marginTop: '2em'
  },
  switchContainer: {
    margin: '2em 0'
  },
  textInput: {
    marginTop: '2em',
    padding: '1.4em 0',
    boxSizing: 'border-box'
  },
  // Form classes
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  formContainer: {
    boxShadow: 'none'
  },
  input: {
    margin: '2em 4em',
    boxSizing: 'border-box'
  },
  switchInput: {
    marginLeft: '4em',
    boxSizing: 'border-box'
  }
};

const getColumns = classes => [
  {
    source: 'image',
    editable: true,
    editConfig: {
      type: EditInputType.Dropzone,
      name: 'image',
      dropDetail: 'JPG, PNG',
      accept: 'image/png, image/jpg, image/jpeg',
      multiple: false,
      maxSize: MAX_SIZE
    }
  },
  {
    title: 'Título',
    source: 'title',
    editable: true,
    editConfig: {
      type: EditInputType.Text,
      name: 'title',
      className: classes.input
    }
  },
  {
    title: 'Descripción',
    source: 'description',
    editable: true,
    editConfig: {
      type: EditInputType.Text,
      name: 'description',
      className: classes.input
    }
  },
  {
    source: 'required',
    editable: true,
    value: 'required',
    editConfig: {
      name: 'required',
      type: EditInputType.Switch,
      label: 'Obligatorio',
      className: classes.switchInput
    }
  },
  {
    source: 'recommended',
    editable: true,
    value: 'recommended',
    editConfig: {
      name: 'recommended',
      type: EditInputType.Switch,
      label: 'Recomendado',
      className: classes.switchInput
    }
  },
  {
    source: 'comments_enabled',
    editable: true,
    value: 'comments_enabled',
    editConfig: {
      name: 'comments_enabled',
      type: EditInputType.Switch,
      label: 'Habilitar comentarios',
      className: classes.switchInput
    }
  },
  {
    source: 'categories',
    editable: true,
    value: 'Categoría',
    editConfig: {
      name: 'categories',
      type: EditInputType.Object,
      model: EditInputObjectModel.Categories,
      placeholder: 'Categoría',
      multiple: true,
      className: classes.input
    }
  },
  {
    source: 'tags_list',
    editable: true,
    value: 'Etiqueta',
    editConfig: {
      name: 'tags_list',
      type: EditInputType.Object,
      model: EditInputObjectModel.Tags,
      placeholder: 'Etiqueta',
      multiple: true,
      creatable: true,
      className: classes.input
    }
  }
];

const CourseFormBasicData = ({
  classes,
  form,
  results,
  onSubmit,
  onAttributeChange,
  title,
  loading,
  onGoBack
}) => {
  const handleFormChanges = async (name, value) => {
    onAttributeChange({
      ...form,
      [name]: name === 'image' ? value.acceptedFiles[0] : value,
      fileError:
        name === 'image' &&
        value &&
        value.failedFiles &&
        value.failedFiles.length
          ? `Debes subir un archivo menor a ${MAX_SIZE} mb`
          : undefined
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainContentContainer}>
        <AdminResourceForm
          title={title}
          keyName={'admin-course-basic-data-form'}
          form={{
            ...form,
            image: form.image ? form.image.preview : form.image
          }}
          isValid={results.isValid}
          errors={results.errors}
          loading={loading}
          attributes={{ keys: getColumns(classes) }}
          onSubmit={onSubmit}
          onGoBack={onGoBack}
          onAttributeChange={handleFormChanges}
          customClasses={classes}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(CourseFormBasicData);
