import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import config from "../../config";
import Loading from "../ui/Loading";

const styles = () => ({

    iframeContainer:{
        width:'100%',
        height:'100%',
        position: 'relative'
    },

    hide:{
        display: 'none'
    }

});

class ScormIframe extends Component {

    constructor(props){
        super(props);
        this.myIframe = React.createRef();
    }


    handleOnLoad = () =>{
        this.setState({loading:false});
    };

    state = {
        loading:true
    };

    render() {
        const { activity,classes} = this.props;
        return (<div className={classes.iframeContainer}>{this.state.loading && <Loading dark={true} fullsize={true} />} <iframe  allowFullScreen={"allowFullScreen"} className={this.state.loading ? classes.hide : ''} ref={this.myIframe} onLoad={this.handleOnLoad} title={'SCORM'} frameBorder="0" src={geScormURL(activity)} style={{display:'block',margin: 'auto',minHeight:600}} width={'100%'} height={'100%'}  /></div>);
    }
}


export function geScormURL(activity){
    return `${config.WP_ROOT}/wp-admin/admin-ajax.php?action=embed_scorm&id=${activity.external}&activity_id=${activity.id}&width=100%&height=100%`
}

export default withStyles(styles)(ScormIframe);
