import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  Input,
  FormGroup,
} from '@material-ui/core';
import config from '../../config';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Snackbar from '@material-ui/core/Snackbar'
import {login_theme} from '../../themes/login_theme';
import validator from 'validator';
import {withRouter} from 'react-router-dom';
import {SnackbarType} from '../../config/constants';
import {parseQuery} from '../../utils/UrlHelper';
import PreLoginLinks from './PreLoginLinks';
import {login} from '../../actions/user';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SnackbarMessage from '../ui/SnackbarMessage'
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';

const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  const mediaSm = theme.breakpoints.down(theme.mediaQueries.sm);
  return {
    formContainer: {
      minWidth: 300,
      [mediaSm]: {
        minWidth: 200,
      },
    },
    login: {
      position: 'relative',
      height: '100vh',
      backgroundSize: 'cover',
      background: `no-repeat center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    loginContent: {
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: '4em',
      justifyContent: 'center',
      flexGrow: 2,
    },
    logoAc: {
      width: '80%',
      maxWidth: 357,
    },

    buttonsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 340,
      [mediaLg]: {
        flexDirection: 'column',
        minWidth: 300,
      },
      [mediaSm]: {
        minWidth: 200,
      },
      marginTop: 16,
    },
    button: {
      margin: '2px 16px',
      width: '110%',
      height: 56,
      borderRadius: 4,
      background: '#b7366c',
      boxShadow: '0 5px 8.2px 0 rgba(183, 54, 108, 0.25)',
      [mediaLg]: {
        marginTop: 16,
      },
      '&-disabled': {
        background: 'linear-gradient(150deg, #757070, #383232 100%)',
        boxShadow: 'none',
        color: '#444444 !important',
      },
    },
    buttonText: {
      color: '#fff',
      fontWeight: 700,
      fontSize: 20,
    },
    iconButton: {
      color: '#fff',
      marginRight: 16,
      fontSize: 40,
    },
    linkButton: {},
    linkButtonText: {
      color: '#fff',
      fontWeight: 500,
      fontSize: 16,
    },
    surveyButton: {
      backgroundColor: '#FFC300',
      '&:hover': {
        backgroundColor: '#cc9800',
      },
    },
    surveyButtonText: {
      color: '#1b1b1b',
      fontWeight: 700,
      fontSize: 16,
    },
    error: {
      color: '#ff0000',
    },

    linksContainer: {
      flexDirection: 'row',
      display: 'flex',
      flexGrow: 1,
      flexShrink: 0,
      alignItems: 'flex-end',
    },
  };
};

class Login extends Component {
  state = {
    username: '',
    password: '',
    showPassword: false,
    isValid: false,
    isReCaptchaValid: false,
    token: '',
    showMessage: false
  };

  handleUsernameChange = (event) => {
    this.setState({username: event.target.value}, () => this.validate());
  };

  handlePasswordChange = (event) => {
    this.setState({password: event.target.value}, () => this.validate());
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(
      (state) => ({showPassword: !state.showPassword}),
      () => this.validate(),
    );
  };

  validate = () => {
    const {username, password, isReCaptchaValid} = this.state;

    const isValid =
      username !== null &&
      validator.isEmail(username) &&
      password !== null &&
      !validator.isEmpty(password);

    this.setState({isValid});

    if (isValid && !isReCaptchaValid) this.handleVerifyRecaptcha();

    return isValid;
  };

  handleVerifyRecaptcha = async () => {
    const {executeRecaptcha} = this.props.googleReCaptchaProps;
    if (!executeRecaptcha) {
      this.setState({isReCaptchaValid: false});
      return;
    }

    const tokenGoogle = await executeRecaptcha('homepage');
    this.setState({isReCaptchaValid: true, token: tokenGoogle});
  };


  handelSubmitLogin = (event) => {
    event.preventDefault()
    const {username, password, token} = this.state;
    this.props.login({username, password, token})
  }

  render() {
    const {classes, onGoToResetPassword, onGoToRegister, onGoToSurvey, error, loading} =
      this.props;
    const {username, password, showPassword} = this.state;

    return (
      <div className={classes.login}>
        <div className={classes.loginContent}>
          <img
            src={config.BASE_URL + 'img/ic_banner_academia_2.png'}
            alt="logo banner"
            className={classes.logoAc}
          />
          <div className={classes.formContainer}>
            <MuiThemeProvider theme={login_theme}>
              <form
                onSubmit={this.handelSubmitLogin}
                id={'login-form'}
              >
                <FormGroup>
                  <FormControl>
                    <InputLabel htmlFor="username">Email</InputLabel>
                    <Input
                      disableUnderline={true}
                      name="username"
                      value={username}
                      onChange={this.handleUsernameChange}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="password">Contraseña</InputLabel>
                    <Input
                      disableUnderline={true}
                      name="password"
                      value={password}
                      type={showPassword ? 'text' : 'password'}
                      onChange={this.handlePasswordChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            color={'primary'}
                            aria-label="Cambiar visibilidad de la contraseña"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}>
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{onKeyPress: this.handleOnKeyPress}}
                    />
                  </FormControl>
                </FormGroup>
              </form>
            </MuiThemeProvider>
          </div>
          {error ?
            <SnackbarMessage
              type={1}
              open={!!error}
              message={(error.error === '1') ? 'Usuario/contraseña inválido' : 'Usuario bloqueado, contacte al administrador'}
            /> : <></>
          }
          <div className={classes.buttonsContainer}>
            <Button
              disabled={!this.state.isValid || loading}
              type={'submit'}
              className={
                classes.button +
                (!this.state.isValid ? ' ' + classes.button + '-disabled' : '')
              }
              variant="outlined"
              form="login-form">
              <Typography className={classes.buttonText}>INGRESAR</Typography>
            </Button>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.linkButton}
              variant="text"
              onClick={() => onGoToRegister()}>
              <Typography className={classes.linkButtonText}>
                Registrarme
              </Typography>
            </Button>
            <Button
              className={classes.linkButton}
              variant="text"
              onClick={() => onGoToResetPassword()}>
              <Typography className={classes.linkButtonText}>
                RECUPERAR CONTRASEÑA
              </Typography>
            </Button>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.surveyButton}
              variant="text"
              onClick={() => onGoToSurvey()}>
              <Typography className={classes.surveyButtonText}>
                ¡CONTANOS QUÉ TE GUSTARÍA APRENDER!
              </Typography>
            </Button>
          </div>
        </div>
        <div className={classes.linksContainer}>
          <PreLoginLinks />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { error, loading } = state.userReducer
  return { error, loading };
}

function  mapDispachToProps(dispatch) {
  return bindActionCreators(
      { login },
      dispatch
  );
}

export default connect(mapStateToProps, mapDispachToProps)(withGoogleReCaptcha(withRouter(withStyles(styles)(Login))));
