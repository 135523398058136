import React from 'react';
import { BASE_ADMIN } from '../config/routes';
import { AdminItemViewType, EditInputType } from '../config/constants';
import moment from 'moment';
import {
  Avatar,
  Button,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip
} from '@material-ui/core';
import Popover from '../components/admin/inputs/Popover';
import TextInput from '../components/admin/inputs/TextInput';
import SelectInput from '../components/admin/inputs/SelectInput';
import SwitchInput from '../components/admin/inputs/SwitchInput';
import FileInput from '../components/admin/inputs/FileInput';
import ColorInput from '../components/admin/inputs/ColorInput';
import BackgroundInput from '../components/admin/inputs/BackgroundInput';
import BooleanInput from '../components/admin/inputs/BooleanInput';
import AutocompleteSimpleObjectInput from '../components/admin/inputs/AutocompleteSimpleObjectInput';
import CssBackgroundPreview from '../components/ui/CssBackgroundPreview';
import Dropzone from '../components/admin/inputs/Dropzone';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import CKEditor from 'ckeditor4-react';
import api from "../api/api";

const format = require('string-format');

export function isAdmin(user) {
  return user != null && user.is_admin;
}

export function isAdminLocation(location) {
  return location.pathname.includes(BASE_ADMIN);
}

export function getValue(path, obj, separator = '.') {
  var properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

export function resolve(column, obj, separator = '.') {
  let values = [];
  if (column.type === AdminItemViewType.Fixed) {
    values =
      typeof column.value !== 'undefined' && column.value instanceof Array
        ? column.value.map(v => '' + v)
        : ['' + column.value];
  } else {
    let paths = column.source.split(' ');
    values = paths.map(p => getValue(p, obj, separator));
    if (column.type === AdminItemViewType.Date) {
      const { format = 'DD/MM/YYYY' } = column;
      values = values.map(v => (v ? moment(v).format(format) : '-'));
    } else if (column.type === AdminItemViewType.Formatted) {
      const columnFormat = column.format;
      return format(columnFormat, ...values);
    } else if (column.type === AdminItemViewType.Boolean) {
      values = values.map(v => {
        return v === null || v === undefined ? '' : v ? 'Sí' : 'No';
      });
    } else if (column.type === AdminItemViewType.Conditional) {
      values = values.map(v => {
        if (v !== undefined) {
          const condition = column.conditions.find(
            cond => '' + v === cond.condition
          );
          return condition ? condition.result : '';
        } else {
          return '';
        }
      });
    }
  }

  return values.join(' ');
}

export function itemView(column, item) {
  const value = resolve(column, item);
  switch (column.type) {
    case AdminItemViewType.Image:
      return (
        value && (
          <img
            src={api.signUrl(value)}
            style={{
              maxWidth: 50,
              maxHeight: 50,
              borderRadius: 3,
              background: '#c5c5c5'
            }}
            alt={'Image for ' + column.source}
          />
        )
      );
    case AdminItemViewType.Background:
      return <CssBackgroundPreview value={value} />;
    case AdminItemViewType.Avatar:
      return <Avatar src={api.signUrl(value)} />;
    case AdminItemViewType.Action:
      return itemAction(item, column.action, column.source);
    case AdminItemViewType.Custom:
      return column.renderItem ? column.renderItem(item) : value;
    default:
      return <Typography style={{ fontSize: 14 }}>{value}</Typography>;
  }
}

export function itemAction(item, action, key) {
  if (action.icon) {
    return (
      <Tooltip title={action.title} key={'tooltip-action-' + key}>
        <IconButton
          onClick={() => {
            action.action(item);
          }}
          label={action.title}
        >
          {action.icon}
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Button
        key={'action-' + key}
        onClick={() => {
          action.action(item);
        }}
      >
        {action.title}
      </Button>
    );
  }
}

export function itemEdit(editConfig, value, onChange) {
  switch (editConfig.type) {
    case EditInputType.Text:
      return (
        <TextInput
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          multiline={editConfig.multiline || false}
        />
      );
    case EditInputType.Number:
      return (
        <TextInput
          name={editConfig.name}
          type={'number'}
          min={'0'}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      );
    case EditInputType.Date:
      return (
        <TextInput
          name={editConfig.name}
          type={'date'}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      );
    case EditInputType.Password:
      return (
        <TextInput
          name={editConfig.name}
          type={'password'}
          autoComplete={'new-password'}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      );
    case EditInputType.Object: {
      return (
        <AutocompleteSimpleObjectInput
          model={editConfig.model}
          name={editConfig.name}
          creatable={editConfig.creatable}
          value={value}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          multiple={editConfig.multiple}
          autoFocus={(editConfig.autoFocus && true) || false}
          displayAttribute={editConfig.displayAttribute || 'name'}
        />
      );
    }
    case EditInputType.Select:
      return (
        <SelectInput
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          options={editConfig.options}
        />
      );
    case EditInputType.MultipleSelect:
      return (
        <SelectInput
          name={editConfig.name}
          value={value || []}
          max={editConfig.max || false}
          placeholder={editConfig.placeholder}
          onChange={onChange}
          options={editConfig.options}
          multiple={true}
        />
      );
    case EditInputType.File:
      return (
        <FileInput
          name={editConfig.name}
          value={value || ''}
          accept={editConfig.accept || '*/*'}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      );
    case EditInputType.Color:
      return (
        <ColorInput
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      );
    case EditInputType.Background:
      return (
        <BackgroundInput
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      );
    case EditInputType.Boolean:
      return (
        <BooleanInput
          name={editConfig.name}
          value={value || ''}
          placeholder={editConfig.placeholder}
          onChange={onChange}
        />
      );
    case EditInputType.RadioGroup:
      return (
        <React.Fragment>
          {editConfig.title && <Typography>{editConfig.title}</Typography>}
          <RadioGroup row={editConfig.row} value={value}>
            {' '}
            {editConfig.options.map(option => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FormControlLabel
                  value={option.id}
                  control={
                    editConfig.renderControl ? (
                      editConfig.renderControl(option)
                    ) : (
                      <Radio color="primary" />
                    )
                  }
                  label={option.description}
                  labelPlacement="end"
                  checked={value === option.id}
                  onChange={e => onChange(editConfig.name, option.id)}
                  key={option.id}
                />
                {option.helperContent && <Popover option={option} />}
              </div>
            ))}
          </RadioGroup>
        </React.Fragment>
      );
    case EditInputType.Switch:
      return (
        <SwitchInput
          value={editConfig.name || ''}
          checked={value || false}
          onChange={() => onChange(editConfig.name, !value)}
          label={editConfig.label || ''}
        />
      );
    case EditInputType.Dropzone:
      return (
        <Dropzone
          dropDetail={editConfig.dropDetail || ''}
          accept={editConfig.accept || '*/*'}
          maxSize={editConfig.maxSize ? editConfig.maxSize : 3}
          multiple={editConfig.multiple ? editConfig.multiple : false}
          icon={<ImageIcon />}
          key="dropzone-file"
          text={editConfig.text || ''}
          onDrop={(acceptedFiles, failedFiles) =>
            onChange(editConfig.name, { acceptedFiles, failedFiles })
          }
          onRemove={file =>
            onChange(editConfig.name, {
              acceptedFiles: editConfig.multiple
                ? value.filter(item => item.name !== file.name)
                : []
            })
          }
          currentFiles={value}
        />
      );
    case EditInputType.TextEditor:
      return (
        <CKEditor
            type="classic"
          onChange={(e) => onChange(editConfig.name, e.editor.getData())}
          height="25em"
          data={value || ''}
        />
      );
    case EditInputType.Custom:
      return editConfig.renderItem
        ? editConfig.renderItem(value, onChange)
        : value;
    default:
      return null;
  }
}
