import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdminResourceIndex from '../AdminResourceIndex';
import { getResources, updateResource,refreshResources } from '../../../actions/admin';
import { goTo } from '../../../actions/navigator';
import { addSnackbar } from '../../../actions/snackbar';
import {ADMIN_EDIT, ADMIN_USERS, ADMIN_VIEW, router} from '../../../config/routes';
import {
  AdminItemViewType,
  FiltersFormat,
  EditInputType, SnackbarType
} from '../../../config/constants';
import ViewIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/LockOutlined';
import UnblockIcon from '@material-ui/icons/LockOpenOutlined';

class AdminUsersContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size);
  };

  getColumns = () => {
    return [
      {
        title: 'Nombre y Apellido',
        source: 'first_name last_name',
        type: AdminItemViewType.Formatted,
        format: '{1}, {0}'
      },
      { title: 'Email', source: 'email' },
      {
        type: AdminItemViewType.Text,
        title: 'Teléfono',
        source: 'phone'
      },
      {
        title: 'Es admin?',
        source: 'is_admin',
        type: AdminItemViewType.Boolean
      },
      {
        type: AdminItemViewType.Boolean,
        title: 'Bloqueado?',
        source: 'blocked'
      }
    ];
  };

  getActions = item => {
    return [
      {
        title: 'VER',
        action: this.onShowItem,
        icon: <ViewIcon />
      },
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />
      },
      {
        title: item.blocked ? 'DESBLOQUEAR' : 'BLOQUEAR',
        action: this.onBlockUser,
        icon:  item.blocked ? <UnblockIcon /> : <BlockIcon />
      }
    ];
  };

  onShowItem = item => {
    this.props.goTo(router.getRoute(ADMIN_USERS + ADMIN_VIEW, { id: item.id }));
  };

  onEditItem = item => {
    this.props.goTo(router.getRoute(ADMIN_USERS + ADMIN_EDIT, { id: item.id }));
  };

  onBlockUser = (item) => {
    const { updateResource, refreshResources, addSnackbar, type } = this.props;
    updateResource(type, item.id, {blocked: (item.blocked ? 0 : 1)})
        .then(() => {
          addSnackbar((item.blocked ?  'Usuario desbloqueado':'Usuario bloqueado') , SnackbarType.Success);
          refreshResources(this.getKeyName());
        })
        .catch(err => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        placeholder: 'Nombre, Apellido o Email',
        format: FiltersFormat.FullSearch,
        fsModel: 'user',
        fields: ['firstName', 'lastName', 'username']
      },
      {
        type: EditInputType.Boolean,
        placeholder: 'Es admin?',
        format: FiltersFormat.Plain,
        name: 'user.isAdmin'
      }
    ];
  };

  getKeyName = () => 'admin-users';

  render() {
    return (
      <AdminResourceIndex
        keyName={this.getKeyName()}
        title={'USUARIOS'}
        getFilterConfig={this.getFilterConfig}
        getActions={this.getActions}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={null}
      />
    );
  }
}

AdminUsersContainer.defaultProps = {
  type: 'users'
};

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResources, goTo, updateResource, addSnackbar,refreshResources }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersContainer);
