import { createMuiTheme } from '@material-ui/core';

export const chips_theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiChip: {
      root: {
        height: 14
      },
      label: {
        lineHeight: 12,
        paddingRight: 4,
        paddingLeft: 4,
        fontSize: 6
      }
    }
  }
});
