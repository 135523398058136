import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import config from '../../config';

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
     header: {
      position: 'relative',
      backgroundSize: 'cover',
      background: `center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      height: '10%',
      minHeight: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1
    },
    headerContent: {
      paddingTop: 50,
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 24
    },
    title: {
      maxWidth: 400,
      [mediaLg]: {
        height: 'auto',
        maxWidth: '80%'
      }
    },
    subtitle: {
      marginTop: 16,
      fontSize: 22,
      fontWeight: 100,
      color: '#fff',
      position: 'relative',
      [mediaLg]: {
        maxWidth: '90%',
        fontSize: 16
      }
    },
    lineGlow: {
      content: `''`,
      display: 'block',
      width: '104%',
      border: '1px solid #b7366c',
      height: 0,
      [mediaLg]: {
        maxWidth: '90%',
        display: 'none'
      },
    }
  };
};

class CategoriesHeader extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <img
            className={classes.title}
            alt="Logo Explora"
            src={config.BASE_URL + 'img/explora_white.png'}
          />
          <Typography className={classes.subtitle}>
            CAPACITATE PARA AFRONTAR ESTE DESAFÍO JUNTOS
          </Typography>
          <div className={classes.lineGlow} />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CategoriesHeader);
