import React, { Component } from 'react';
import {Select, MenuItem}from '@material-ui/core';
import { withStyles,  createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import classnames from 'classnames';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#000000'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  overrides: {
    MuiInput: {
      formControl: {
        marginTop: '0px !important'
      }
    }
  }
});

const styles = () => ({
  select: {
    color: '#7f7f7f',
    float: 'left',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 0,
    width: 'calc(100% - 16px)',
    fontWeight: 400,
    fontSize: 14
  },
  selectField: {
    fontSize: 14
  },
  placeholder: {
    opacity: 0.42
  },
  input: {
    '&:focus': {
      backgroundColor: 'transparent'
    }
  }
});

class SelectInput extends Component {
  state = {
    open: false
  };

  setOpen = open => {
    this.setState({ open });
  };

  handleOnChange = event => {
    const { name, onChange, multiple = false, max = false } = this.props;
    const value = event.target.value;
    if (!multiple || !max || (max && value.length <= max)) {
      if (value && value !== '') onChange(name, value);
      if (max && value.length === max) {
        this.setOpen(false);
      }
    } else {
      this.setOpen(false);
    }
  };

  render() {
    const {
      name,
      value,
      placeholder,
      options = [],
      classes,
      multiple = false
    } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Select
          multiple={multiple}
          className={classes.select}
          open={this.state.open}
          onClose={() => this.setOpen(false)}
          onOpen={() => this.setOpen(true)}
          value={value}
          displayEmpty
          renderValue={v =>
            value !== null && value === ''
              ? placeholder
              : options.filter(o => o.id === value).pop().description
          }
          onChange={this.handleOnChange}
          inputProps={{
            className: classnames(classes.input, {
              [classes.placeholder]: value === ''
            }),
            name: name
          }}
          placeholder={placeholder}
        >
          {options.map(option => (
            <MenuItem
              className={classes.selectField}
              key={'menu-option-' + option.id}
              value={option.id}
            >
              {option.description}
            </MenuItem>
          ))}
        </Select>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(SelectInput);
