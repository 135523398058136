import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, IconButton, Tooltip, Typography, Card, CardContent } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import DoneIcon from "@material-ui/icons/CheckCircleOutline";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { ActivityType, ExternActivityTargetType } from "../../config/constants";
import RequiredActivitiesDialog from "../../containers/courses/RequiredActivitiesDialog";
import {
  activityTypeIcon,
  activityTypeText
} from "../../utils/ActivitiesHelper";
import FullScreenDialog from "../ui/FullScreenDialog";
import H5pIframe from "./H5pIframe";
import ScormIframe, { geScormURL } from "./ScormIframe";
import ActivityModuleFileContent from "../admin/course/ActivityModule/ActivityModuleFileContent";
import api from "../../api/api";

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    card: {
      minHeight: 210,
      margin: 32,
      marginTop: 16,
      marginBottom: 16,
      borderRadius: 1,
      position: "relative",
      display: "flex",
      transition: "all 450ms",
      "&-closed": {
        minHeight: 210
      },
      "&-open": {
        height: "100%"
      },
      [mediaLg]: {
        margin: 16,
        flexDirection: "column"
      }
    },
    cardContent: {
      padding: 32,
      paddingTop: 24,
      flex: "1 0 auto"
    },
    cardContentWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    iconWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    coverIcon: {
      color: "white",
      fontSize: 100,
      textAlign: "center",
      [mediaLg]: {
        fontSize: 32
      }
    },
    activityType: {
      fontWeight: 700,
      fontSize: 16,
      color: "#434343",
      textTransform: "uppercase",
      paddingBottom: 16,
      display: "flex"
    },
    iconWrapped: {
      marginRight: 8,
      [mediaLg]: {}
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      color: "#434343"
    },
    titleIcon: {
      marginBottom: -2,
      fontWeight: 700,
      fontSize: 16
    },
    shortDescription: {
      fontSize: 18,
      lineHeight: 1.2,
      color: "#777777"
    },
    content: {
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 0,
      paddingRight: 0,
      position: "relative"
    },
    imageWrapper: {
      width: "100%",
      textAlign: "center"
    },
    imageContent: {
      margin: "auto",
      maxHeight: 300,
      [mediaLg]: {
        width: "100%"
      }
    },
    cardMedia: {
      width: "100%",
      height: "100%"
    },
    cardCover: {
      position: "relative",
      marginTop: 30,
      width: 150,
      height: 150,
      display: "flex",
      flexShrink: 0,
      [mediaLg]: {
        display: "none"
      }
    },
    cardCoverIcon: {},
    embeddedContent: {
      width: "100%",
      maxHeight: 300,
      border: "none",
      overflow: "scroll"
    },
    iframeOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0)",
      zIndex: 1000
    },
    textContentWrapper: {
      transition: "max-height 0.8s ease-in"
    },
    text: {
      fontSize: 12,
      color: "#777777"
    },
    viewMore: {
      textAlign: "center",
      width: "100%",
      color: "#000000",
      textDecoration: "underline",
      cursor: "pointer"
    },
    blocked: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(156,156,156,0.87)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1001,
      cursor: "pointer"
    },
    lock: {
      textAlign: "center",
      color: "white",
      fontSize: 100
    },
    lockDescription: {
      textAlign: "center",
      color: "white",
      fontSize: 14
    },
    done: {
      position: "absolute",
      top: 16,
      right: 16,
      color: "#34cb62",
      fontSize: 32,
      zIndex: 4
    },
    fullscreen: {
      float: "right"
    },
    buttonOpen: {
      position: "absolute",
      bottom: 32,
      right: 32
    },
    buttonPlaceholder: {
      height: 48
    },
    openNewTabButton: {
      float: "right"
    }
  };
};

class Activity extends Component {
  renderActivityMedia = () => {
    const { classes, activity } = this.props;
    return (
      <div className={classes.cardCover}>
        <div
          className={classes.iconWrapper}
          style={{ background: activity.color }}
        >
          {activityTypeIcon(activity.type, classes.coverIcon)}
        </div>
      </div>
    );
  };

  renderActivityHeader = () => {
    const { activity, classes } = this.props;
    let icon = activityTypeIcon(activity.type, classes.titleIcon);
    let iconWrapped = <span className={classes.iconWrapped}>{icon}</span>;
    let activityType = activityTypeText(activity.type);
    return (
      <React.Fragment>
        <Typography key={"title"} className={classes.title}>
          {activity.name || ""}
        </Typography>
        <Typography key={"description"} className={classes.shortDescription}>
          {activity.description || ""}
        </Typography>
        {!this.state.open && (
          <Button
            key={"button-open"}
            className={classes.buttonOpen}
            variant={"outlined"}
          >
            {"ABRIR"}
          </Button>
        )}
        {!this.state.open && <div className={classes.buttonPlaceholder} />}
      </React.Fragment>
    );
  };

  renderActivityContent = () => {
    const { classes, activity } = this.props;
    switch (activity.type) {
      case ActivityType.File:
        return <div className={classes.content} style={{ float: 'right' }}>
          <ActivityModuleFileContent content={activity} color={activity.color} />
        </div>
        break;
      case ActivityType.Audio:
      case ActivityType.Video:
      case ActivityType.Trivia:
      case ActivityType.Presentation:
      case ActivityType.Timeline:
        return (
          <div className={classes.content}>
            <H5pIframe activity={activity} />
          </div>
        );

      case ActivityType.Text:
        const html = activity.text;
        const self = this;
        return (
          <div className={classes.content}>
            <div
              className={classes.textContentWrapper}
              style={{
                maxHeight: this.state.expanded ? "10000px" : "200px",
                overflow: this.state.expanded ? "scroll" : "hidden"
              }}
            >
              {!this.state.expanded && (
                <HTMLEllipsis
                  className={classes.text}
                  unsafeHTML={html}
                  maxLine="7"
                  ellipsis="..."
                  basedOn="letters"
                  onReflow={htmlState => {
                    if (!self.state.showExpand && !this.state.reflowed) {
                      self.setState({
                        showExpand: htmlState.clamped,
                        reflowed: true
                      });
                    }
                  }}
                />
              )}
              {this.state.expanded && (
                <div
                  className={classes.text}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              )}
            </div>
            {this.state.showExpand && (
              <Typography
                className={classes.viewMore}
                style={{ color: activity.color }}
                onClick={() =>
                  this.setState({ expanded: !this.state.expanded })
                }
              >
                {this.state.expanded ? "Leer menos" : "Leer más"}
              </Typography>
            )}
          </div>
        );
      case ActivityType.Scorm:
      case ActivityType.External: {
        const isScorm = activity.type === ActivityType.Scorm;
        const done =
          activity &&
          activity.user_activities &&
          activity.user_activities.length > 0 &&
          activity.user_activities[0].done !== 0;

        return (
          <div className={classes.content}>
            {activity.target_type === ExternActivityTargetType.NewTab ? (
              <Button
                className={classes.openNewTabButton}
                onClick={() => this.handleOpenTabUrl()}
                variant={"outlined"}
              >
                {"ABRIR"}
              </Button>
            ) : (
                <React.Fragment>
                  {!done && <div className={classes.iframeOverlay} />}
                  {activity.target_type === ExternActivityTargetType.Embedded &&
                    (isScorm ? (
                      <ScormIframe activity={activity} />
                    ) : (
                        <iframe
                          title={activity.name}
                          src={activity.url}
                          height={400}
                          className={classes.embeddedContent}
                        />
                      ))}
                  {activity.target_type !== ExternActivityTargetType.NewTab &&
                    done ? (
                      <Tooltip title={"EXPANDIR"}>
                        <IconButton
                          className={classes.fullscreen}
                          onClick={() => this.handleFullscreenDialog(true)}
                        >
                          <FullscreenIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  <FullScreenDialog
                    open={this.state.fullScreenOpen}
                    onClose={() => this.handleFullscreenDialog(false)}
                  >
                    {this.state.fullScreenOpen &&
                      (isScorm ? (
                        <ScormIframe activity={activity} />
                      ) : (
                          <iframe
                            width={"100%"}
                            height={"100%"}
                            title={activity.name}
                            src={activity.url}
                          />
                        ))}
                  </FullScreenDialog>
                </React.Fragment>
              )}
          </div>
        );
      }
      case ActivityType.Image:
        return (
          <div className={classes.content}>
            <div className={classes.imageWrapper}>
              <img
                alt={activity.name}
                src={api.signUrl(activity.url)}
                className={classes.imageContent}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  state = {
    open: false,
    reflowed: false,
    showExpand: false,
    expanded: false,
    dialogOpen: false,
    fullScreenOpen: false
  };

  onClickWrapper = () => {
    const { onClick, activity } = this.props;
    if (!activity.done && activity.blocked === 0) {
      onClick();
      this.handleOpenActivity();
    } else if (activity.blocked === 0) {
      this.handleOpenActivity();
    }
  };

  handleOpenActivity = () => {
    if (!this.state.open) {
      this.setState({ open: true }, () => {
        const { activity } = this.props;
        if (
          activity.type === ActivityType.File ||
          activity.type === ActivityType.External ||
          activity.type === ActivityType.Scorm
        ) {
          if (activity.type === ActivityType.File || activity.target_type === ExternActivityTargetType.NewTab) {
            this.handleOpenTabUrl();
          } else if (
            activity.target_type === ExternActivityTargetType.FullScreen
          ) {
            this.handleFullscreenDialog(true);
          }
        }
      });
    }
  };

  handleFullscreenDialog = open => {
    this.setState({ fullScreenOpen: open });
  };

  handleOpenTabUrl = () => {
    const { activity } = this.props;
    if (activity.type === ActivityType.Scorm) {
      window.open(geScormURL(activity), "_blank");
    } else {
      window.open(api.signUrl(activity.url), "_blank");
    }
  };

  render() {
    const { classes, activity } = this.props;
    return (
      <Card
        className={
          classes.card +
          " " +
          classes.card +
          "-" +
          (this.state.open ? "open" : "closed")
        }
        onClick={() => this.onClickWrapper()}
      >
        {this.renderActivityMedia()}
        <div className={classes.cardContentWrapper}>
          <CardContent className={classes.cardContent}>
            {activity.blocked !== 0 && (
              <div
                className={classes.blocked}
                onClick={() => {
                  this.setState({ dialogOpen: true });
                }}
              >
                <LockIcon className={classes.lock} />
              </div>
            )}
            {activity.done && <DoneIcon className={classes.done} />}
            {this.renderActivityHeader()}
            {this.state.open && this.renderActivityContent()}
          </CardContent>
        </div>
        {activity.blocked !== 0 && (
          <RequiredActivitiesDialog
            activity={activity}
            open={this.state.dialogOpen}
            onDismiss={() => {
              this.setState({ dialogOpen: false });
            }}
            headerColor={activity.color}
          />
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(Activity);
