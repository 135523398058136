import React, { Component } from 'react';
import AutocompleteSearchSimpleObject from '../../../containers/ui/AutocompleteSearchSimpleObject';
import TextChip from '../../ui/TextChip';

class AutocompleteSimpleObjectInput extends Component {
  handleOnItemSelected = item => {
    const { name, onChange } = this.props;
    onChange(name, item);
  };

  render() {
    const { multiple = false, ...props } = this.props;
    return (
      <div key={'AutocompleteSimpleObjectInput'}>
        {multiple ? (
          <AutocompleteMultipleValues
            {...props}
            onChange={this.handleOnItemSelected}
          />
        ) : (
          <AutocompleteSingleValue
            {...props}
            onChange={this.handleOnItemSelected}
          />
        )}
      </div>
    );
  }
}

const AutocompleteSingleValue = ({
  value,
  name,
  model,
  placeholder,
  onChange,
  creatable,
  displayAttribute
}) => {
  if (value) {
    return <TextChip text={(displayAttribute ? (  typeof displayAttribute === "function" ? displayAttribute(value) : value[displayAttribute] ): value.name)} onDeleteChip={() => onChange(null)} />;
  } else {
    return (
      <AutocompleteSearchSimpleObject
        model={model}
        keyName={'autocomplete-simple-object-input-' + name}
        onItemSelected={onChange}
        value={value}
        placeholder={placeholder}
        creatable={creatable}
        displayAttribute={displayAttribute}
      />
    );
  }
};

const AutocompleteMultipleValues = ({
  value,
  name,
  model,
  placeholder,
  onChange,
  creatable,
  autoFocus,
  displayAttribute
}) => (
  <div>
    {value &&
      value.length > 0 &&
      value.map(textValue => (
        <TextChip
          text={(displayAttribute ? (  typeof displayAttribute === "function" ? displayAttribute(textValue) : textValue[displayAttribute] ): textValue.name)}
          key={textValue.id}
          onDeleteChip={() =>
            onChange(value.filter(item => item.id !== textValue.id))
          }
        />
      ))}
    <AutocompleteSearchSimpleObject
      model={model}
      keyName={'autocomplete-simple-object-input-' + name}
      onItemSelected={selectedValue =>
        value ? onChange([...value, selectedValue]) : onChange([selectedValue])
      }
      value={value}
      placeholder={placeholder}
      multiple={true}
      creatable={creatable}
      autoFocus={autoFocus}
      displayAttribute={displayAttribute}
    />
  </div>
);

export default AutocompleteSimpleObjectInput;
