import {
 REGISTER_FAILED, REGISTER_START, REGISTER_END
} from "../actions";

let initialState = {
  loading: false,
  error: false,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_START:
      return {
          ...state,
          loading: true,
          error: false
      };
    case REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message
      };
    case REGISTER_END:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return {...state};
  }
};
