import {
  CATEGORY_READ_END,
  CATEGORY_READ_FAILED,
  CATEGORY_READ_START
} from '../actions';

let initialState = {
  error: null,
  loading: false,
  category: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_READ_END:
      const { category } = action.payload;
      return {
        loading: false,
        category: category,
        error: null
      };
    case CATEGORY_READ_START:
      return {
        category: null,
        error: null,
        loading: true
      };
    case CATEGORY_READ_FAILED:
      const { message } = action.payload;
      return {
        category: null,
        error: message,
        loading: false
      };
    default:
      return { ...state, error: null };
  }
};
