const styles = {
  popupContent: {
    height: '16em'
  },
  formContainer: {
    boxShadow: 'none'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2em'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  input: {
    width: '100%',
    minHeight: '8em'
  },
  formRoot: {
    marginTop: 'unset',
    marginLeft: 'unset',
    maxWidth: 'unset'
  },
  wrapper: {
    margin: 0
  },
  wrapperStyle: {
    padding: 'none',
    minHeight: 'unset',
    margin: 'unset'
  },
  form: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column'
  },
  radioButtonsLabelContainer: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  radioGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  resourceFormInputContainer: {
    marginTop: '2em'
  },
  dropzoneContainer: {
    padding: '0',
    minWidth: 'auto',
    height: 'auto'
  },
  worldIcon: {
    width: '80%',
    height: '80%',
    color: '#707070'
  },
  imgBlockType: {
    width: '6em',
    padding: '.1em'
  },
  imgBlockTypeSelected: {
    width: '6em',
    padding: '.1em',
    border: '2px solid red',
    borderRadius: 4
  }
};

export default styles;
