import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadCourseById } from '../../actions/courses';
import { appLoadToken } from "../../actions/app";
import { withRouter } from 'react-router-dom';
import Course from '../../components/courses/Course';

class CourseContainer extends Component {

  componentDidMount() {
    const { courseId,loadCourseById,appLoadToken } = this.props;
    appLoadToken().then(() => {
      loadCourseById(courseId)
    });
  }


  render() {
    const { course, loading } = this.props;
    return <Course course={course} loading={loading}  />;
  }
}

const mapStateToProps = state => {
  return {
    ...state.courseReducer
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadCourseById,appLoadToken }, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CourseContainer)
);
