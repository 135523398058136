import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import config from "../../config";
import Loading from "../ui/Loading";

const styles = () => ({

    iframeContainer:{
        width:'100%',
        height:'100%',
        position: 'relative'
    },

    hide:{
        display: 'none'
    }

});

class H5pIframe extends Component {

    constructor(props){
        super(props);
        this.myIframe = React.createRef();
    }

    setH5PEventListener = () =>  {
        if (window.addEventListener) {
            window.addEventListener('message', this.eventFromH5P, false);
        } else {
            window.attachEvent('onmessage', this.eventFromH5P);
        }
    };

    removeH5PEventListener = () => {
        if (window.removeEventListener) {
            window.removeEventListener('message', this.eventFromH5P, false);
        } else {
            window.detachEvent('onmessage', this.eventFromH5P);
        }
    };

    eventFromH5P = (evt) => {
        if (evt.data.type === 'gotH5PSize') {
            const contentId = evt.data.data.id;
            if(contentId === this.props.activity.external){
                let h5pHeight = evt.data.data.height;
                let h5pWidth = evt.data.data.width;
                h5pHeight = h5pHeight + 16;

                this.removeH5PEventListener();
                 this.updateIframeSize(
                     h5pHeight,
                     h5pWidth
                );
            }
        }
    };

    handleOnLoad = () =>{
        this.setState({loading:false});
        this.setH5PEventListener();

        const message = {
            type: 'getH5PSize'
        };

        this.myIframe.current.contentWindow.postMessage(message,config.API_ROOT.substring(0, config.API_ROOT.lastIndexOf('/')));

    };

    updateIframeSize = (ifH,ifW) => {
        if(this.myIframe.current) {
            this.myIframe.current.height = ifH;
            this.myIframe.current.width = ifW;
        }
    };

    state = {
        loading:true
    };

    render() {
        const { activity,classes} = this.props;
        return (<div className={classes.iframeContainer}>{this.state.loading && <Loading dark={true} fullsize={true} />} <iframe  allowFullScreen={"allowFullScreen"} className={this.state.loading ? classes.hide : ''} ref={this.myIframe} onLoad={this.handleOnLoad} title={'H5P'} frameBorder="0" src={`${config.WP_ROOT}/wp-admin/admin-ajax.php?action=h5p_embed&id=${activity.external}`} style={{display:'block',margin: 'auto'}} width={'100%'} height={0} /></div>);
    }
}

export default withStyles(styles)(H5pIframe);
