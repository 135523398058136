import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {idForwardingLogin, wordpressLogin} from '../../actions/user';
import {goHome} from '../../actions/navigator';
import Loading from '../../components/ui/Loading';
import {parseQuery} from '../../utils/UrlHelper';

class SetTokenContainer extends Component {
  componentDidMount() {
    const {search, idForwardingLogin, goHome, wordpressLogin} = this.props;
    const params = parseQuery(search);
    if (params['token']) {
      idForwardingLogin(params['token']).then(() => {
        wordpressLogin();
      });
    } else {
      goHome();
    }
  }

  render() {
    return (
      <div style={{minHeight: 300, minWidth: 500, backgroundColor: '#ffff00'}}>
        <Loading />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {idForwardingLogin, goHome, wordpressLogin},
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SetTokenContainer);
