import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { EditInputType } from '../../../../config/constants';
import styles from './ActivityModalStyles';
import AdminResourceForm from '../../AdminResourceForm';

const FORM_COLUMNS = [
  {
    title: 'Nombre',
    source: 'name',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'name' }
  },
  {
    title: 'Descripción',
    source: 'description',
    editable: true,
    editConfig: {
      type: EditInputType.Text,
      name: 'description',
      multiline: 4
    }
  },
  {
    title: 'Url',
    source: 'url',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'url' }
  },
  {
    title: 'Mostrar',
    source: 'target_type',
    editable: true,
    editConfig: {
      type: EditInputType.RadioGroup,
      name: 'target_type',
      row: true,
      options: [
        {
          id: 1,
          description: 'En la actividad',
          helperContent:
            'Por restricciones de navegación, es posible que en ocasiones esta opción no se aplique y sea necesario mostrar el contenido en una ventana nueva.'
        },
        {
          id: 2,
          description: 'A pantalla completa',
          helperContent:
            'Por restricciones de navegación, es posible que en ocasiones esta opción no se aplique y sea necesario mostrar el contenido en una ventana nueva.'
        },
        {
          id: 3,
          description: 'En ventana nueva'
        }
      ]
    }
  }
];

const ActivityModalExternContent = ({
  activity,
  results,
  onChange,
  onSubmit,
  classes,
  actionButtons,
  onCancel,
  title
}) => {
  const onAttributeChange = (name, value) => {
    onChange({ ...activity, [name]: value });
  };

  return (
    <AdminResourceForm
      title={title}
      keyName="admin-activity-modal-extern-form"
      form={activity}
      isValid={results && results.isValid}
      errors={results ? results.errors : []}
      loading={false}
      attributes={{ keys: FORM_COLUMNS }}
      onAttributeChange={onAttributeChange}
      customClasses={classes}
      onSubmit={onSubmit}
      onCancel={onCancel}
      actionButtons={actionButtons}
    />
  );
};

export default withStyles(styles)(ActivityModalExternContent);
