import {
  PAGINATOR_CHANGE_QUERY,
  PAGINATOR_INIT,
  PAGINATOR_PAGINATE_END,
  PAGINATOR_PAGINATE_FAILED,
  PAGINATOR_PAGINATE_NEXT,
  PAGINATOR_PAGINATE_PREV,
  PAGINATOR_PAGINATE_START,
  PAGINATOR_PAGINATE_TO,
  PAGINATOR_REFRESH,
  PAGINATOR_UPDATE_ITEM
} from '../actions';
import { Paginator } from '../config/constants';

let initialState = {
  key: 'default',
  error: null,
  loading: false,
  items: [],
  page: 1,
  total: 1,
  size: Paginator.PAGE_SIZE,
  meta: null,
  query: null,
  fetch: true,
  hasPrev: false,
  hasNext: false
};

let states = {};

function getStateFor(key) {
  if (states[key] !== undefined) {
    return states[key];
  } else {
    states[key] = { ...initialState, key: key };
    return states[key];
  }
}

export default (state = initialState, action) => {
  const key = action.key || state.key || 'default';
  const currentState = getStateFor(key);
  switch (action.type) {
    case PAGINATOR_INIT: {
      states[key] = { ...currentState };
      return states;
    }
    case PAGINATOR_UPDATE_ITEM:
      const { itemId, apply } = action.payload;
      const itemToUpdate = currentState.items.find(
        i => '' + i.id === '' + itemId
      );
      let items = currentState.items.filter(i => '' + i.id !== '' + itemId);
      const itemUpdated = apply(itemToUpdate);
      if (itemUpdated != null) {
        items = [...items, { ...itemUpdated }];
      }
      states[key] = {
        ...currentState,
        items: items
      };
      return states;
    case PAGINATOR_PAGINATE_FAILED:
      const { message } = action.payload;
      states[key] = {
        ...currentState,
        items: [],
        error: message,
        query: null,
        meta: null,
        loading: false,
        fetch: false
      };
      return states;
    case PAGINATOR_PAGINATE_TO: {
      const { page } = action.payload;
      let newPage =
        page < 1 ? 0 : page > currentState.total ? currentState.total : page;
      states[key] = {
        ...currentState,
        items: [],
        page: newPage,
        error: null,
        loading: false,
        fetch: true,
        hasPrev: false,
        hasNext: false
      };
      return states;
    }
    case PAGINATOR_PAGINATE_NEXT: {
      states[key] = {
        ...currentState,
        items: [],
        page: currentState.page + 1,
        error: null,
        loading: false,
        fetch: true,
        hasPrev: false,
        hasNext: false
      };
      return states;
    }
    case PAGINATOR_PAGINATE_PREV: {
      let prevPage = currentState.page - 1;
      states[key] = {
        ...currentState,
        items: [],
        page: prevPage < 1 ? 1 : prevPage,
        error: null,
        loading: false,
        fetch: true,
        hasPrev: false,
        hasNext: false
      };
      return states;
    }
    case PAGINATOR_PAGINATE_START: {
      const { query } = action.payload;
      states[key] = {
        ...currentState,
        items: [],
        query: query,
        error: null,
        loading: true,
        fetch: false
      };
      return states;
    }
    case PAGINATOR_CHANGE_QUERY: {
      const { query } = action.payload;
      states[key] = {
        ...currentState,
        page: 1,
        size: Paginator.PAGE_SIZE,
        items: [],
        query: query,
        error: null,
        loading: false,
        fetch: true,
        hasPrev: false,
        hasNext: false
      };
      return states;
    }
    case PAGINATOR_REFRESH: {
      states[key] = {
        ...currentState,
        fetch: true
      };
      return states;
    }
    case PAGINATOR_PAGINATE_END: {
      const {
        items,
        query,
        meta: {
          pagination: { size, total }
        }
      } = action.payload;
      if (query === currentState.query) {
        const lastPage = Math.ceil(total / size);
        states[key] = {
          ...currentState,
          items: items,
          error: null,
          loading: false,
          fetch: false,
          hasPrev: currentState.page > 1,
          hasNext: currentState.page < lastPage,
          total: lastPage
        };
      }
      return states;
    }
    default:
      states[key] = { ...currentState };
      return states;
  }
};
