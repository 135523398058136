import {
  ADMIN_RESOURCE_READ_START,
  ADMIN_RESOURCE_READ_END,
  ADMIN_RESOURCE_READ_FAILED
} from './index';
import api from '../api/api';
import { paginate, refresh, silentPaginate } from './paginator';
import {
  FiltersFormat,
  EditInputType,
  ActivityTypeToH5pLibrary, ActivityType
} from '../config/constants';
import FileSaver from 'file-saver';

export function getResources(
  resourceType,
  paginatorKey,
  query,
  page,
  size,
  apply = a => a
) {
  return paginate(
    paginatorKey,
    query,
    dispatch => {
      return api
        .withDispatch(dispatch)
        .Admin.getResources(resourceType, page, size, query);
    },
    apply
  );
}

export function silentGetResources(
  resourceType,
  paginatorKey,
  query,
  page,
  size,
  apply = a => a
) {
  return silentPaginate(
    paginatorKey,
    query,
    dispatch => {
      return api
        .withDispatch(dispatch)
        .Admin.getResources(resourceType, page, size, query);
    },
    apply
  );
}

export function refreshResources(paginatorKey) {
  return refresh(paginatorKey);
}

export function getResource(
  keyName,
  resourceType,
  id,
  type = null,
  apply = a => a
) {
  return async dispatch => {
    try {
      dispatch({ type: ADMIN_RESOURCE_READ_START, key: keyName });
      const response = await api
        .withDispatch(dispatch)
        .Admin.getResource(resourceType, id);
      const data = response.data;
      dispatch({
        type: ADMIN_RESOURCE_READ_END,
        payload: { item: apply(data) },
        key: keyName
      });
    } catch (e) {
      dispatch({ type: ADMIN_RESOURCE_READ_FAILED, payload: e, key: keyName });
    }
  };
}

export function createResource(resourceType, data) {
  return async dispatch => {
    const response = await api
      .withDispatch(dispatch)
      .Admin.createResource(resourceType, data);
    return response.data;
  };
}

export function updateResource(resourceType, id, data) {
  return async dispatch => {
    const response = await api
      .withDispatch(dispatch)
      .Admin.updateResource(resourceType, id, data);
    return response.data;
  };
}

export function createActivity(resourceType, data) {
  return async dispatch => {
    const response = await ((data.type === ActivityType.File)?api.Admin.createFileActivity(resourceType,data):api
        .withDispatch(dispatch)
        .Admin.createResource(resourceType, data));
    return response.data;
  };
}

export function updateActivity(resourceType, id, data) {
  return async dispatch => {
    const response = await ((data.type === ActivityType.File)?api.Admin.updateResource(resourceType,id,data):api
        .withDispatch(dispatch)
        .Admin.createResource(resourceType+'/'+id, data));
    return response.data;
  };
}



export function deleteResource(resourceType, id) {
  return async dispatch => {
    const response = await api
      .withDispatch(dispatch)
      .Admin.deleteResource(resourceType, id);
    return response.data;
  };
}

function getValueFromFilter(filter, config) {
  switch (config.type) {
    case EditInputType.Select:
    case EditInputType.Text:
    case EditInputType.Number:
      return filter[config.name];
    case EditInputType.Object:
      if (config.multiple) {
        if (Array.isArray(filter[config.name]) && filter[config.name].length) {
          return typeof filter[config.name][0] === 'object'
            ? filter[config.name].map(value => value.id)
            : filter[config.name];
        } else {
          return filter[config.name];
        }
      }
      return filter[config.name].id;
    case EditInputType.Fixed:
      return config.fixedValue;
    case EditInputType.Boolean:
      return filter[config.name];
    default:
      return null;
  }
}

export function makeQuery(filter, filtersConfig, orderingConfig) {
  let query = '';
  let conditions = [];
  if (filter) {
    filtersConfig.forEach(fc => {
      if (filter[fc.name] && filter[fc.name] !== '') {
        switch (fc.format) {
          case FiltersFormat.Plain:
            conditions.push(
              'filters[' + fc.name + ']=' + getValueFromFilter(filter, fc)
            );
            break;
          case FiltersFormat.In:
            conditions.push(
              getValueFromFilter(filter, fc)
                .map(
                  (value, index) => `filters[${fc.name}][IN][${index}]=${value}`
                )
                .join('&')
            );
            break;
          case FiltersFormat.Like:
            conditions.push(
              'filters[' +
                fc.name +
                '][LIKE]=%' +
                getValueFromFilter(filter, fc) +
                '%'
            );
            break;
          case FiltersFormat.FullSearch:
            fc.fields.forEach(field => {
              conditions.push(
                'filters[OR][' +
                  fc.fsModel +
                  '.' +
                  field +
                  '][LIKE]=%' +
                  getValueFromFilter(filter, fc) +
                  '%'
              );
            });
            break;
          case FiltersFormat.Null:
            const val = getValueFromFilter(filter, fc);
            conditions.push(
              'filters[' +
                fc.name +
                ']' +
                (val === null || val === 'NULL' ? '[IS]=NULL' : '=' + val)
            );
            break;
          default:
            break;
        }
      } else if (fc.type === EditInputType.Fixed) {
        conditions.push(
          'filters[' + fc.name + ']=' + getValueFromFilter(filter, fc)
        );
      }
    });
    if (orderingConfig.length > 0) {
      orderingConfig.forEach(oc => {
        conditions.push('ordering[' + oc.name + ']=' + oc.order);
      });
    }
    query = conditions.join('&');
  }
  return query;
}

export function createContentH5p(type) {
  let library = ActivityTypeToH5pLibrary[type];
  if (library) {
    return api.Wordpress.createContent({ library: library }).then(response => {
      if (response.success) {
        return response.data.id;
      } else {
        return false;
      }
    });
  }
  return Promise.resolve(false);
}

export function createContentScorm() {
    return api.Wordpress.createScormContent().then(response => {
      if (response.success) {
        return response.data.scorm_id;
      } else {
        return false;
      }
    });
}

export function downloadReport(filters) {
  return async dispatch => {
    const file = await api.withDispatch(dispatch).Admin.downloadReport(filters);
    FileSaver.saveAs(file, 'report_activity');
  };
}

export function downloadReportUsers(filters) {
  return async dispatch => {
    const file = await api.withDispatch(dispatch).Admin.downloadReportUsers(filters);
    FileSaver.saveAs(file, 'report_users');
  };
}


export function downloadReportSurveys(filters) {
  return async dispatch => {
    const file = await api.withDispatch(dispatch).Admin.downloadReportSurveys(filters);
    FileSaver.saveAs(file, 'report_surveys');
  };
}
