import api from '../api/api';

export function setActivityDone(courseId, id) {
  return async dispatch => {
    try {
      const response = await api.withDispatch(dispatch).My.Activities.setDone(courseId, id);
      return response.data;
    } catch (e) {}
    return false;
  };
}
