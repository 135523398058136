import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import CourseBlock from '../../components/courses/CourseBlock';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {init,updateItems} from "../../actions/infinite_paginator";
import {paginateCourseBlocks,silentUpdateCourseById} from "../../actions/courses";
import {bindActionCreators} from "redux";
import InfiniteScroll from 'react-infinite-scroller';
import ParallaxBackground from "../../components/ui/ParallaxBackground";

const styles = theme => ({
  root: {
    minHeight: 600,
    display: 'flex',
    flexFlow: 'column'
  }
});

class ContentCourseContainer extends Component {

  constructor(props){
    super(props);
    props.init(props.keyName);
  }

  onFetchMore = () => {
    const {keyName,course,paginateCourseBlocks,page,size,preview} = this.props;
    paginateCourseBlocks(course.id,keyName,page,size,preview);
  };

  onActivityDone = (activityId,updatedBlocks) => {
    const {keyName,updateItems,silentUpdateCourseById,course} = this.props;
    updateItems(keyName,updatedBlocks);
    silentUpdateCourseById(course.id);
  };

  render() {
    const { classes, course,hasMore,items=[], loading,courseColor,preview  } = this.props;
    return (
      course && (
        <div className={classes.root}>
          <ParallaxBackground/>
          <InfiniteScroll
              pageStart={0}
              loadMore={this.onFetchMore}
              hasMore={hasMore && !loading}
              loader={<div className="loader" key={0}>Loading ...</div>}
          >
            {items.map((block,i) => <CourseBlock
                key={'block-'+i}
                courseId={course.id}
                block={block}
                onActivityDone={this.onActivityDone}
                courseColor={courseColor}
                preview={preview}
            />)}
          </InfiniteScroll>
        </div>
      )
    );
  }

}



const mapStateToProps = (state,props) => {
  const infiniteState = state.infiniteScrollPaginatorReducer;
  return {
    ...infiniteState[props.keyName]
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ init,paginateCourseBlocks,updateItems, silentUpdateCourseById }, dispatch);
}

export default withStyles(styles)(withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ContentCourseContainer))
);