import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CourseItem from '../ui/CourseItem';
import ZoomGrid from '../ui/ZoomGrid';
import ParallaxBackground from "../ui/ParallaxBackground";
const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    paddingTop: 32,
    paddingBottom: 50
  }
});

class CoursesExploreContent extends Component {
  onCreateItemCourse = item => {
    return <CourseItem item={item} />;
  };
  onItemClick = item => {
    const { onShowCourse } = this.props;
    onShowCourse(item);
  };
  render() {
    const { classes, courses } = this.props;

    return (
      <div className={classes.root}>
        <ParallaxBackground/>
        <ZoomGrid
          items={courses}
          itemCreator={this.onCreateItemCourse}
          itemClick={this.onItemClick}
          itemHeight={165}
          itemWidth={290}
        />
      </div>
    );
  }
}
export default withStyles(styles)(CoursesExploreContent);
