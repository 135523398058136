import React, { Component } from "react";
import {withStyles} from "@material-ui/core/styles";
import Activity from "../../containers/courses/Activity";

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    root: {
      height: "100%",
      width: "100%"
    },
    activities: {
      width: "60%",
      minWidth: 500,
      margin: "auto",
      backgroundColor: "rgba(190,190,190,0.4)",
      paddingTop: 32,
      paddingBottom: 32,
      marginBottom: 64,
      [mediaLg]: {
        paddingTop: 1,
        width: "100%",
        minWidth: "unset"
      }
    }
  };
};

class CourseBlockContent extends Component {
  render() {
    const { classes, block, onActivityDone, courseId, preview } = this.props;
    return (
      <div className={classes.root}>
        {block && (
          <div className={classes.activities}>
            {block.block_activities.map((ba, i) => {
              ba.activity.color = block.color;
              return (
                <Activity
                  preview={preview}
                  onActivityDone={onActivityDone}
                  courseId={courseId}
                  activity={ba.activity}
                  key={"block-" + ba.id + "-activity-" + i}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(CourseBlockContent);
