export const USER_LOGIN_END = 'USER_LOGIN_END';
export const USER_LOGIN_START = 'USER_LOGIN_START';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const USER_LOGOUT_END = 'USER_LOGOUT_END';
export const USER_LOGOUT_START = 'USER_LOGOUT_START';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export const USER_READ_END = 'USER_READ_END';
export const USER_READ_START = 'USER_READ_START';
export const USER_READ_FAILED = 'USER_READ_FAILED';

export const APP_LOAD_TOKEN = 'APP_LOAD_TOKEN';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GENERIC_ERROR = 'GENERIC_ERROR';

export const MENU_DRAWER_TOGGLE = 'MENU_DRAWER_TOGGLE';

export const EDIT_ROW_INIT = 'EDIT_ROW_INIT';
export const EDIT_ROW_TOGGLE_EDITING = 'EDIT_ROW_TOGGLE_EDITING';
export const EDIT_ROW_CHANGE_VALUE = 'EDIT_ROW_CHANGE_VALUE';
export const EDIT_ROW_START_SAVE = 'EDIT_ROW_START_SAVE';
export const EDIT_ROW_END_SAVE = 'EDIT_ROW_END_SAVE';
export const EDIT_ROW_FAIL_SAVE = 'EDIT_ROW_FAIL_SAVE';

export const LAZY_SELECT_INPUT_INIT = 'LAZY_SELECT_INPUT_INIT';
export const LAZY_SELECT_INPUT_FETCH_START = 'LAZY_SELECT_INPUT_FETCH_START';
export const LAZY_SELECT_INPUT_FETCH_END = 'LAZY_SELECT_INPUT_FETCH_END';
export const LAZY_SELECT_INPUT_FETCH_FAIL = 'LAZY_SELECT_INPUT_FETCH_FAIL';

export const PAGINATOR_PAGINATE_NEXT = 'PAGINATOR_PAGINATE_NEXT';
export const PAGINATOR_PAGINATE_TO = 'PAGINATOR_PAGINATE_TO';
export const PAGINATOR_PAGINATE_PREV = 'PAGINATOR_PAGINATE_PREV';
export const PAGINATOR_PAGINATE_START = 'PAGINATOR_PAGINATE_START';
export const PAGINATOR_INIT = 'PAGINATOR_INIT';
export const PAGINATOR_CHANGE_QUERY = 'PAGINATOR_CHANGE_QUERY';
export const PAGINATOR_PAGINATE_FAILED = 'PAGINATOR_FAILED';
export const PAGINATOR_PAGINATE_END = 'PAGINATOR_PAGINATE_END';
export const PAGINATOR_UPDATE_ITEM = 'PAGINATOR_UPDATE_ITEM';
export const PAGINATOR_REFRESH = 'PAGINATOR_REFRESH';

export const INFINITE_PAGINATE_LOAD_NEXT = 'INFINITE_PAGINATE_LOAD_NEXT';
export const INFINITE_PAGINATE_START = 'INFINITE_PAGINATE_START';
export const INFINITE_INIT = 'INFINITE_INIT';
export const INFINITE_RESET = 'INFINITE_RESET';
export const INFINITE_PAGINATE_FAILED = 'INFINITE_PAGINATE_FAILED';
export const INFINITE_PAGINATE_END = 'INFINITE_PAGINATE_END';
export const INFINITE_PAGINATE_UPDATE_ITEM = 'INFINITE_PAGINATE_UPDATE_ITEM';
export const INFINITE_PAGINATE_UPDATE_ITEMS = 'INFINITE_PAGINATE_UPDATE_ITEMS';

export const SEARCH_REQUEST_END = 'SEARCH_REQUEST_END';
export const SEARCH_REQUEST_START = 'SEARCH_REQUEST_START';
export const SEARCH_REQUEST_CHANGE_QUERY = 'SEARCH_REQUEST_CHANGE_QUERY';
export const SEARCH_REQUEST_FAILED = 'SEARCH_REQUEST_FAILED';
export const SEARCH_REQUEST_CLEAR = 'SEARCH_REQUEST_CLEAR';
export const SEARCH_REQUEST_INIT = 'SEARCH_REQUEST_INIT';

export const CREATE_REQUEST_START = 'CREATE_REQUEST_START';
export const CREATE_REQUEST_END = 'CREATE_REQUEST_END';
export const CREATE_REQUEST_FAILED = 'CREATE_REQUEST_FAILED';

export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';

export const ADMIN_RESOURCE_READ_END = 'ADMIN_RESOURCE_READ_END';
export const ADMIN_RESOURCE_READ_START = 'ADMIN_RESOURCE_READ_START';
export const ADMIN_RESOURCE_READ_FAILED = 'ADMIN_RESOURCE_READ_FAILED';
export const ADMIN_RESOURCE_UPDATED = 'ADMIN_RESOURCE_UPDATED';

export const RECOMMENDED_COURSES_READ_START = 'RECOMMENDED_COURSES_READ_START';
export const RECOMMENDED_COURSES_READ_END = 'RECOMMENDED_COURSES_READ_END';
export const RECOMMENDED_COURSES_READ_FAILED = 'RECOMMENDED_COURSES_READ_FAILED';

export const REQUIRED_COURSES_READ_START = 'REQUIRED_COURSES_READ_START';
export const REQUIRED_COURSES_READ_END = 'REQUIRED_COURSES_READ_END';
export const REQUIRED_COURSES_READ_FAILED = 'REQUIRED_COURSES_READ_FAILED';

export const FEATURED_CATEGORIES_READ_START = 'FEATURED_CATEGORIES_READ_START';
export const FEATURED_CATEGORIES_READ_END = 'FEATURED_CATEGORIES_READ_END';
export const FEATURED_CATEGORIES_READ_FAILED = 'FEATURED_CATEGORIES_READ_FAILED';

export const SEARCH_COURSES_CLEAR = 'SEARCH_COURSES_CLEAR';
export const SEARCH_COURSES_READ_START = 'SEARCH_COURSES_READ_START';
export const SEARCH_COURSES_READ_END = 'SEARCH_COURSES_READ_END';
export const SEARCH_COURSES_READ_FAILED = 'SEARCH_COURSES_READ_FAILED';

export const MY_COURSES_IN_PROGRESS_READ_START =
  'MY_COURSES_IN_PROGRESS_READ_START';
export const MY_COURSES_IN_PROGRESS_READ_END =
  'MY_COURSES_IN_PROGRESS_READ_END';
export const MY_COURSES_IN_PROGRESS_READ_FAILED =
  'MY_COURSES_IN_PROGRESS_READ_FAILED';

export const MY_COURSES_FINISHED_READ_START = 'MY_COURSES_FINISHED_READ_START';
export const MY_COURSES_FINISHED_READ_END = 'MY_COURSES_FINISHED_READ_END';
export const MY_COURSES_FINISHED_READ_FAILED =
  'MY_COURSES_FINISHED_READ_FAILED';

export const CATEGORY_READ_START = 'CATEGORY_READ_START';
export const CATEGORY_READ_END = 'CATEGORY_READ_END';
export const CATEGORY_READ_FAILED = 'CATEGORY_READ_FAILED';

export const CATEGORIES_READ_START = 'CATEGORIES_READ_START';
export const CATEGORIES_READ_END = 'CATEGORIES_READ_END';
export const CATEGORIES_READ_FAILED = 'CATEGORIES_READ_FAILED';

export const COURSE_READ_END = 'COURSE_READ_END';
export const COURSE_READ_FAILED = 'COURSE_READ_FAILED';
export const COURSE_READ_START = 'COURSE_READ_START';


export const RESET_PASSWORD_GET_TOKEN_START = "RESET_PASSWORD_GET_TOKEN_START";
export const RESET_PASSWORD_GET_TOKEN_END = "RESET_PASSWORD_GET_TOKEN_END";
export const RESET_PASSWORD_GET_TOKEN_FAILED = "RESET_PASSWORD_GET_TOKEN_FAILED";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_END = "RESET_PASSWORD_END";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_BACK = "RESET_PASSWORD_BACK";
export const RESET_PASSWORD_NEXT = "RESET_PASSWORD_NEXT";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_END = "REGISTER_END";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const OCCUPATIONS_READ_START = 'OCCUPATIONS_READ_START';
export const OCCUPATIONS_READ_END = 'OCCUPATIONS_READ_END';
export const OCCUPATIONS_READ_FAILED = 'OCCUPATIONS_READ_FAILED';


export const SURVEY_START = "SURVEY_START";
export const SURVEY_END = "SURVEY_END";
export const SURVEY_FAILED = "SURVEY_FAILED";
export const SURVEY_BACK = "SURVEY_BACK";
export const SURVEY_NEXT = "SURVEY_NEXT";
export const SURVEY_RESET = "SURVEY_RESET";
