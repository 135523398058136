import { ActivitiesTypesList } from "../config/constants";
import React from "react";

export const activityTypeText = type => {
  const result = ActivitiesTypesList.find(at => at.type === type);
  if (result) {
    return result.title;
  }
  return null;
};

export const activityTypeIcon = (type, className = null, ...extraProps) => {
  const result = ActivitiesTypesList.find(at => at.type === type);
  if (result) {
    const Icon = result.icon;
    return <Icon className={className} {...extraProps} />;
  }
  return null;
};
