const styles = {
  container: {
    height: '100%',
    minHeight: '22em'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  description: {
    color: '#777',
    fontSize: 16,
    lineHeight: 1.5,
    marginBottom: '1em'
  },
  icon:{ color: '#fff', height: '4em', width: '4em' },
  // Extern Activity classes:
  activityContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.16)',
    boxSizing: 'border-box',
    background: '#fff',
    padding: '2em 2em 4em 0',
    marginTop: '1em'
  },
  activityContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  activityData: {
    width: '72%',
    marginLeft: 'auto'
  },
  activityIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#1fd1a8',
    height: '10em',
    width: '10em'
  }
};

export default styles;
