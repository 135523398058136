import React, { Component } from 'react';
import KeyValueTable from '../../components/ui/KeyValueTable';

class AdminResourceKeyValue extends Component {
  render() {
    const { item, attributes, onAttributeChange } = this.props;
    return (
      <KeyValueTable
        item={item}
        attributes={attributes}
        onAttributeChange={onAttributeChange}
      />
    );
  }
}

export default AdminResourceKeyValue;
