import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  input: {
    color: '#7f7f7f',
    float: 'left',
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    fontWeight: 400,
    fontFamily: 'Open Sans',
    fontSize: 14
  }
});

class FileInput extends Component {
  handleOnChange = event => {
    const { name, onChange } = this.props;
    const value = event.target.files;
    onChange(name, value);
  };

  render() {
    const { name, accept, value, placeholder, classes, ...others } = this.props;
    delete others['onChange'];
    return (
      <input
        accept={accept}
        className={classes.input}
        type="file"
        name={name}
        style={{ minWidth: '100%' }}
        onChange={this.handleOnChange}
      />
    );
  }
}

export default withStyles(styles)(FileInput);
