import React, { Component } from 'react';
import {Chip} from '@material-ui/core';
import { withStyles,MuiThemeProvider } from '@material-ui/core/styles';
import { text_chip_theme } from '../../themes/text_chip_theme';

const styles = theme => ({
  root: {
    borderRadius: 18
  }
});
class TextChip extends Component {
  render() {
    const { text, className, onDeleteChip, classes } = this.props;
    return (
      <MuiThemeProvider theme={text_chip_theme}>
        <Chip
          variant="outlined"
          classes={{ root: classes.root }}
          label={text}
          onDelete={() => onDeleteChip()}
          className={className}
        />
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(TextChip);
