import React, { Component } from "react";
import config from "../../config";

export default class Loading extends Component {
  render() {
    const {
      height = 80,
      align = "center",
      dark = false,
      fullSize = false
    } = this.props;
    const customStyle = fullSize
      ? {
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: align,
          paddingTop: 0
        }
      : {};
    return (
      <div style={{ textAlign: align, paddingTop: 32, ...customStyle }}>
        <img
          src={
            config.BASE_URL + "img/ac_loading" + (dark ? "_dark" : "") + ".gif"
          }
          height={height}
          alt={"Loading"}
        />
      </div>
    );
  }
}
