import React, { Component } from 'react';
import Header from './SearchHeader';
import Content from './SearchContent';
import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
  root: {
    backgroundColor: '#101010',
    height: '100%',
    width: '100%',
    maxWidth: 'calc(100vw - 0%)'
  }
});

class Search extends Component {
  render() {
    const { classes,items, loading, onShowCourse,onQueryChange,query} = this.props;

    return (
      <div className={classes.root}>
        <Header onQueryChange={onQueryChange} query={query} />
        <Content items={items} loading={loading} onShowCourse={onShowCourse} />
      </div>
    );
  }
}

export default withStyles(styles)(Search);
