import axios from 'axios';
import { AUTH_ERROR, GENERIC_ERROR, USER_LOGIN_FAILED } from '../actions';
import { LOG_NETWORK } from '../config/constants';
import axiosCookieJarSupport from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';

const client = axios.create();
axiosCookieJarSupport(client);
client.defaults.jar = new CookieJar();

client.interceptors.request.use(config => {
  if (config.token) {
    config.headers['Authorization'] = `Bearer ${config.token}`;
  }
  config.headers['Access-Control-Allow-Origin'] = '*';
  if (config.download) {
    config.responseType = 'blob';
  }
  return config;
});

const request = function(options, dispatch) {
  const onSuccess = function(response) {
    if (LOG_NETWORK) {
      console.log(
        '<-- ',
        response.request._method,
        response.request.responseURL
      );
      console.log('<-- Status ', response.status);
      console.log('<-- ', response.data);
    }
    return response.data;
  };

  const onError = function(error) {
    if (dispatch) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: USER_LOGIN_FAILED,
          payload: { message: 'Unauthorized' }
        });
        dispatch({ type: AUTH_ERROR, payload: error.response.data });
      } else {
        dispatch({ type: GENERIC_ERROR, payload: error.response ? error.response.data : error.message });
      }
    }
    return Promise.reject(error.response && error.response.data ? error.response.data : error.message);
  };
  if (LOG_NETWORK) {
    const { method, url, ...others } = options;
    console.log('--> ', method, url);
    console.log('-->', others);
  }
  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export default request;
