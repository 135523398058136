import React from 'react';
import {Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@material-ui/core';

class ConfirmDialog extends React.Component {
  render() {
    const { title, description, onContinue, onCancel, open } = this.props;
    const buttonStyle = {
      color: '#000'
    };
    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={buttonStyle}
            onClick={() => {
              onCancel();
            }}
            color="primary"
          >
            CANCELAR
          </Button>
          <Button
            style={buttonStyle}
            onClick={() => {
              onContinue();
            }}
            color="primary"
            autoFocus
          >
            CONTINUAR
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDialog;
