import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { searchCourses,clearSearchCourses } from '../../actions/courses';
import {appLoadToken} from "../../actions/app";
import {goTo} from "../../actions/navigator";
import Search from '../../components/search/Search';
import {COURSES_VIEW, router} from "../../config/routes";

class SearchContainer extends Component {

  componentDidMount() {
    this.props.appLoadToken().then(()=>{
      this.onQueryChange('');
    });
  }

  showCourse = course => {
    const { goTo } = this.props;
    goTo(router.getRoute(COURSES_VIEW, { id: course.id }));
    this.props.clearSearchCourses();
  };


  onQueryChange = (query) =>{
    this.props.searchCourses(query);
  };

  render() {
    const {items, loading, query} = this.props;
    return <Search items={items} loading={loading} onShowCourse={this.showCourse} onQueryChange={this.onQueryChange} query={query} />;
  }
}

const mapStateToProps = (state) => {
  return {
      ...state.courses.search
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({searchCourses,appLoadToken,goTo,clearSearchCourses}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchContainer);
