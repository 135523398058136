import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CourseFormBlockContent } from '../../../components/admin/course';
import { addSnackbar } from '../../../actions/snackbar';
import {
  createActivity,
  createResource,
  updateResource,
  getResource,
  getResources,
  silentGetResources,
  deleteResource
} from '../../../actions/admin';

import {
  SnackbarType,
  CourseStatusValue,
  ActivityType
} from '../../../config/constants';
import { goTo } from '../../../actions/navigator';
import { ADMIN_COURSES, ADMIN_VIEW, router } from '../../../config/routes';

class AdminCoursesBlockFormContainer extends Component {
  state = {
    newId: -1,
    basicData: {}
  };

  componentDidMount() {
    this.fetchCourse();
    this.fetchBlocksList();
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    if (!this.props.item && nextProps.item) {
      newState.basicData = nextProps.item;
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  }

  fetchCourse = async () => {
    const { type, getResource, getResources, keyName, params } = this.props;
    getResource(keyName, type, params.id);
    getResources(`${type}/${params.id}/blocks`, keyName);
  };

  fetchBlocksList = async () => {
    const { type, silentGetResources, keyName, params } = this.props;
    return silentGetResources(`${type}/${params.id}/blocks`, keyName);
  };

  fetchActivitiesList = async id => {
    const { getResource, keyName, params } = this.props;
    getResource(
      `${keyName}-milestone-activities`,
      'course',
      `${params.id}/activities/${id}/required_activities`
    );
  };

  handleSubmitBlock = async data => {
    const { addSnackbar, createResource, updateResource, type } = this.props;
    const { basicData } = this.state;

    try {
      if (data.id) {
        await updateResource(`${type}/${basicData.id}/blocks`, data.id, data);
        await this.fetchBlocksList();
        addSnackbar('Bloque actualizado exitosamente.', SnackbarType.Success);
      } else {
        await createResource(`${type}/${basicData.id}/blocks`, data);
        await this.fetchBlocksList();
        addSnackbar('Bloque creado exitosamente.', SnackbarType.Success);
      }
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error);
    }
  };

  handleRemoveBlock = async data => {
    const { addSnackbar, deleteResource, type } = this.props;
    const { basicData } = this.state;

    try {
      await deleteResource(`${type}/${basicData.id}/blocks`, data.id);
      await this.fetchBlocksList();
      addSnackbar('Bloque eliminado exitosamente.', SnackbarType.Success);
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error);
    }
  };

  handleSubmitActivity = async data => {
    const {
      addSnackbar,
      createActivity,
      updateResource,
      type: pageType
    } = this.props;

    const { basicData } = this.state;
    try {
      if (data.content) {
        if (data.content.url) {
          if (typeof data.content.url === 'object' ) {
            data.content.file_extension = data.content.url.file_extension ? data.content.url.file_extension : data.content.url.name.split('.').pop();
            if (data.content.type === ActivityType.Image) {
              data.content.url = data.content.url.file;
            }
          }
        }
        data.content.type = data.type;
      }

      if (data.id) {
        await updateResource(
          `${pageType}/${basicData.id}/blocks/${data.blockId}/activities`,
          data.id,
          {
            id: data.id,
            type: data.type,
            ...data.content
          }
        );
        await this.fetchBlocksList();
        addSnackbar(
          'Actividad actualizada exitosamente.',
          SnackbarType.Success
        );
      } else {
        await createActivity(
          `${pageType}/${basicData.id}/blocks/${data.blockId}/activities`,
          {
            type: data.type,
            ...data.content
          }
        );
        await this.fetchBlocksList();
        addSnackbar('Actividad creada exitosamente.', SnackbarType.Success);
      }
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error);
    }
  };

  handleSubmitMilestone = async data => {
    const { addSnackbar, updateResource } = this.props;

    await updateResource(`activities/${data.id}/required_activities`, null, {
      required_activities: data.requiredActivities
    });
    addSnackbar('Hito guardado exitosamente.', SnackbarType.Success);
  };

  handleRemoveActivity = async data => {
    const { addSnackbar, deleteResource, type } = this.props;
    const { basicData } = this.state;

    try {
      await deleteResource(
        `${type}/${basicData.id}/blocks/${data.blockId}/activities`,
        data.id
      );
      await this.fetchBlocksList();
      addSnackbar('Actividad eliminada exitosamente.', SnackbarType.Success);
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error);
    }
  };

  handleSubmitBlockOrder = async blocks => {
    const { addSnackbar, updateResource, type } = this.props;
    const { basicData } = this.state;

    try {
      await updateResource(`${type}/${basicData.id}/blocks`, null, { blocks });
      await this.fetchBlocksList();
      addSnackbar(
        'Ordenamiento de bloques actualizado exitosamente.',
        SnackbarType.Success
      );
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error);
    }
  };

  handlePublishCourse = async data => {
    const { type, updateResource, addSnackbar } = this.props;
    try {
      await updateResource(type, data.id, {
        status: CourseStatusValue.Published
      });
      addSnackbar(`Curso publicado exitosamente.`, SnackbarType.Success);
    } catch (err) {
      addSnackbar('Error: ' + err.message, SnackbarType.Error);
    }
  };

  handlePreview = () => {
    this.props.goTo(
      router.getRoute(ADMIN_COURSES + ADMIN_VIEW, { id: this.props.params.id })
    );
  };

  render() {
    return (
      <CourseFormBlockContent
        {...this.props}
        {...this.state}
        blocks={this.props.paginator.items}
        milestone={this.props.milestone}
        handleRemoveBlock={this.handleRemoveBlock}
        handleSubmitBlock={this.handleSubmitBlock}
        handleRemoveActivity={this.handleRemoveActivity}
        handleSubmitActivity={this.handleSubmitActivity}
        handleSubmitMilestone={this.handleSubmitMilestone}
        handlePublishCourse={this.handlePublishCourse}
        handleSubmitBlockOrder={this.handleSubmitBlockOrder}
        handlePreview={this.handlePreview}
        fetchActivitiesList={this.fetchActivitiesList}
      />
    );
  }
}

AdminCoursesBlockFormContainer.defaultProps = {
  type: 'courses'
};

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer;
  const resourceStates = state.admin.resource;
  return {
    ...resourceStates[ownProps.keyName],
    paginator: { ...paginatorState[ownProps.keyName] },
    milestone: {
      ...resourceStates[`${ownProps.keyName}-milestone-activities`]
    }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResource,
      getResources,
      silentGetResources,
      createActivity,
      createResource,
      updateResource,
      deleteResource,
      addSnackbar,
      goTo
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCoursesBlockFormContainer);
