import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ZoomGrid from '../ui/ZoomGrid';
import CategoryItem from '../ui/CategoryItem';
import ParallaxBackground from '../ui/ParallaxBackground';

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column'
  },
  categories: {
    marginTop: 32,
    marginBottom: 50
  }
});

class CategoriesContent extends Component {
  onCreateCategoryItem = item => {
    return <CategoryItem item={item} />;
  };
  onItemClick = item => {
    const { onCategoryClick } = this.props;
    onCategoryClick(item);
  };
  render() {
    const { classes, categories } = this.props;

    return (
      <div className={classes.root}>
        <ParallaxBackground />
        <div className={classes.categories}>
          <ZoomGrid
            items={categories}
            itemCreator={this.onCreateCategoryItem}
            itemClick={this.onItemClick}
            itemHeight={110}
            itemWidth={290}
          />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CategoriesContent);
