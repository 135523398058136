import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goToExploreCoursesByCategory } from '../../actions/navigator';
import CategoryChip from '../../components/ui/CategoryChip';

class CategoryChipContainer extends Component {

    handleClick = () => {
        this.props.goToExploreCoursesByCategory(this.props.category.id);
    };

    render() {
        const {category,className='',link=true} = this.props;
        return (
            <CategoryChip
                className={className}
                label={category.name.toUpperCase()}
                color={category.color}
                onClick={link ? this.handleClick: null}
            />
        );
    }
}

const mapStateToProps = state => {
    return {};
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ goToExploreCoursesByCategory }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoryChipContainer);
