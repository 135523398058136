import React, { Component } from 'react';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import config from '../../config';
import classnames from 'classnames';

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {

    header: {
      position: 'relative',
      height: '20%',
      backgroundSize: 'cover',
      backgroundImage: `url("${config.BASE_URL}img/ac_bg_home.png")`,
      minHeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
      [mediaLg]: {
        padding: 24
      }
    },
    headerContent: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      zIndex: 100
    },
    logoBanner: {
      maxWidth: 445,
      width: '80%',
      [mediaLg]: {
        width: '100%'
      }
    },
    title: {
      fontSize: 27,
      fontWeight: 700,
      color: '#fff'
    },
    subtitle: {
      padding: '0 8px',
      width: '100%',
      boxSizing: 'border-box',
      marginTop: 16,
      marginBottom: 8,
      fontSize: 22,
      color: '#fff',
      textAlign: 'center',
      opacity: 0,
      transition: 'all 0.9s ease-in',
      '&_show': {
        opacity: 1
      },
      [mediaLg]: {
        fontSize: 16
      }
    },
    button: {
      margin: '0 24px',
      color: '#fff',
      borderColor: '#fff',
      padding: '10px 16px',
      [mediaLg]: {
        width: 120,
        margin: 'auto',
        padding: 'unset'
      }
    },
    separator: {
      backgroundColor: '#fff',
      width: 1,
      height: 16,
      margin: 10
    },
    image: {
      zIndex: 2
    },
    buttonsContainer: {
      marginTop: 24,
      marginBottom: 16,
      minHeight: 40,
      display: 'flex',
      opacity: 0,
      transition: 'all 1s ease-in',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      '&_show': {
        opacity: 1
      }
    },
    lineGlow: {
      content: `''`,
      display: 'block',
      width: '104%',
      border: '1px solid #b7366c',
      height: 0,
      [mediaLg]: {
        maxWidth: '90%',
        display: 'none'
      },

    }
  };
};

class HeaderHome extends Component {
  state = {
    showSubtitle: false,
    showButtons: false
  };

  constructor(props) {
    super(props);
    setTimeout(() => {
      this.setState({ showSubtitle: true });
      setTimeout(() => {
        this.setState({ showButtons: true });
      }, 1000);
    }, 100);
  }

  render() {
    const { classes, onGoToExplore, onGoToSearch } = this.props;
    return (
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <img
            src={`${config.BASE_URL}img/ic_banner_academia.png`}
            alt="logo banner"
            className={classes.logoBanner}
          />
          <Typography
            className={classnames(classes.subtitle, {
              [classes.subtitle + '_show']: this.state.showSubtitle
            })}
          >
            CAPACITANDO PROFESIONALES PARA AFRONTAR LA EMERGENCIA SANITARIA
          </Typography>
          <div className={classes.lineGlow} />
          <div
            className={classnames(classes.buttonsContainer, {
              [classes.buttonsContainer + '_show']: this.state.showButtons
            })}
          >
            {this.state.showButtons && (
              <React.Fragment>
                <Button
                  className={classes.button}
                  variant="text"
                  onClick={onGoToExplore}
                >
                  POR CATEGORÍA
                </Button>
                <div className={classes.separator} />
                <Button
                  className={classes.button}
                  variant="text"
                  onClick={onGoToSearch}
                >
                  POR PALABRA CLAVE
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(HeaderHome);
