import React, { Component } from "react";
import {Typography,LinearProgress} from '@material-ui/core';
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Rating from "react-rating";
import { inverted_progressbar_theme } from "../../themes/inverted_progressbar_theme";
import Loading from "../ui/Loading";
import SvgIcon from "../ui/SvgIcon";
import CategoryChip from "../../containers/ui/CategoryChip";
import TagChip from "../ui/TagChip";
import CreateReviewDialog from "../../containers/courses/CreateReviewDialog";
import CourseReviewsDialogContainer from "../../containers/courses/CourseReviewsDialog";
import api from "../../api/api";

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    header: {
      position: "relative",
      backgroundColor: "#434343",
      height: 500,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1,
      maxWidth: 'calc(100vw - 0%)',
      [mediaLg]: {
        height: "fit-content"
      }
    },
    headerContent: {
      paddingTop: 50,
      textAlign: "center",
      display: "block",
      [mediaLg]: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: 100
      }
    },
    title: {
      fontSize: 40,
      fontWeight: 700,
      color: "#ffffff",
      [mediaLg]: {
        padding: "0 24px",
        fontSize: 24
      }
    },
    description: {
      margin: "auto",
      width: 600,
      fontSize: 16,
      color: "#ffffff",
      paddingBottom: 12,
      boxSizing: "border-box",
      [mediaLg]: {
        width: "100%",
        padding: "0 24px",
        margin: "0 auto 24px"
      }
    },
    button: {
      color: "#434343",
      marginTop: 16
    },
    rating: {
      display: "flex",
      width: "100%",
      justifyContent: "center"
    },
    ratingsCountLink: {
      float: "left",
      color: "#ffffff",
      textDecoration: "underline",
      marginLeft: 16,
      marginTop: 4,
      cursor: "pointer",
      fontSize: 12
    },
    stars: {
      float: "left"
    },
    star: {
      fontSize: 20,
      color: "white"
    },
    mandatory: {
      margin: "auto",
      marginBottom: 16,
      backgroundColor: "rgba(0,0,0,0,0)",
      borderColor: "white",
      border: "solid 1px",
      borderRadius: 6,
      padding: 6,
      maxWidth: 100,
      "&_label": {
        color: "white",
        fontSize: 10
      },
      [mediaLg]: {
        margin: "0 auto 16px"
      }
    },
    progress: {
      position: "relative",
      width: "100%",
      maxWidth: 450,
      margin: "auto",
      paddingTop: 32,
      display: "block",
      [mediaLg]: {
        margin: "unset"
      }
    },
    indicators: {
      position: "absolute",
      display: "flex",
      width: "100%",
      bottom: 16,
      justifyContent: "space-between",
      [mediaLg]: {
        justifyContent: "center",
        bottom: 24
      }
    },
    activityProgress: {
      fontSize: 14,
      position: "relative",
      top: 8,
      color: "white",
      [mediaLg]: {
        right: "80px"
      }
    },
    textProgress: {
      fontSize: 14,
      position: "relative",
      top: 8,
      color: "white"
    },
    categoriesAndTagsContainer: {
      width: "100%",
      float: "left"
    },
    categoriesAndTagsWrapper: {
      maxWidth: 600,
      margin: "auto",
      padding: 16,
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap"
    },
    category: {
      fontSize: 12,
      height: "1.5em"
    },
    tag: {
      fontSize: 12,
      marginTop: 2
    }
  };
};

class HeaderCourse extends Component {
  state = {
    openCreateReview: false,
    openCourseReview: false,
    reviewStars: 5
  };

  render() {
    const { classes, course, courseColor, preview } = this.props;
    let userInfo = { total_activities_done: 0, progress: 0 };
    if (course && course.user_courses && course.user_courses.length === 1) {
      userInfo = course.user_courses[0];
      userInfo.progress =
        (userInfo.total_activities_done / (course.total_activities || 1)) * 100;
    }
    return (
      course != null && (
        <div
          className={classes.header}
          style={{
            backgroundImage: `url(${api.signUrl(course.image)})`,
            backgroundSize: "cover",
            backgroundBlendMode: "multiply"
          }}
        >
          {course != null ? (
            <div className={classes.headerContent}>
              {course.required && (
                <div className={classes.mandatory}>
                  <Typography className={classes.mandatory + "_label"}>
                    OBLIGATORIO
                  </Typography>
                </div>
              )}
              {course.comments_enabled && (
                <div className={classes.rating}>
                  <Rating
                    className={classes.stars}
                    emptySymbol={
                      <SvgIcon
                        iconName={"ic_star_outline"}
                        className={classes.star}
                      />
                    }
                    fullSymbol={
                      <SvgIcon
                        iconName={"ic_star_filled"}
                        className={classes.star}
                      />
                    }
                    stop={5}
                    onClick={s => {
                      this.setState({ openCreateReview: true, reviewStars: s });
                    }}
                    readonly={
                      preview ||
                      !(
                        course.user_course_ratings &&
                        course.user_course_ratings.length === 0
                      )
                    }
                    initialRating={parseFloat(course.rating_average)}
                  />
                  <Typography
                    className={classes.ratingsCountLink}
                    onClick={() => {
                      if (course.rates_count !== 0) {
                        this.setState({ openCourseReview: true });
                      }
                    }}
                  >
                    {course.rates_count === 0
                      ? "Sin valoraciones"
                      : course.rates_count === 1
                      ? "1 valoración"
                      : `${course.rates_count} valoraciones`}
                  </Typography>
                </div>
              )}
              <Typography className={classes.title}>{course.title}</Typography>
              {course.categories && (
                <div className={classes.categoriesAndTagsContainer}>
                  <div className={classes.categoriesAndTagsWrapper}>
                    {course.categories.map(c => (
                      <CategoryChip
                        className={classes.category}
                        key={"c" + c.id}
                        category={c}
                      />
                    ))}
                    {course.tags.map(tag => (
                      <TagChip
                        key={tag.id}
                        label={tag.name}
                        className={classes.tag}
                      />
                    ))}
                  </div>
                </div>
              )}
              <Typography className={classes.description}>
                {course.description}
              </Typography>
              <div className={classes.progress}>
                <div className={classes.indicators}>
                  <Typography className={classes.activityProgress}>
                    {`${userInfo.total_activities_done}/${course.total_activities}`}
                  </Typography>
                  <Typography className={classes.textProgress}>
                    {"Progreso " + userInfo.progress.toFixed(0) + "%"}
                  </Typography>
                </div>
                {userInfo && (
                  <MuiThemeProvider theme={inverted_progressbar_theme}>
                    <LinearProgress
                      variant="determinate"
                      value={userInfo.progress}
                    />
                  </MuiThemeProvider>
                )}
              </div>
            </div>
          ) : (
            <Loading />
          )}
          <CreateReviewDialog
            course={course}
            headerColor={courseColor}
            open={this.state.openCreateReview}
            onDismiss={() => {
              this.setState({ openCreateReview: false });
            }}
            initialRating={this.state.reviewStars}
          />
          {course && (
            <CourseReviewsDialogContainer
              course={course}
              keyName={"course-reviews-dialog-" + course.id}
              headerColor={courseColor}
              open={this.state.openCourseReview}
              onDismiss={() => {
                this.setState({ openCourseReview: false });
              }}
            />
          )}
        </div>
      )
    );
  }
}
export default withStyles(styles)(HeaderCourse);
