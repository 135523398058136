// Put environment configuration here!

function getBaseUrl() {
  let baseUrl = process.env.REACT_APP_BASE_URL || '/';
  if (baseUrl.charAt(baseUrl.length - 1) !== '/') {
    baseUrl = baseUrl + '/';
  }
  return baseUrl;
}

export default {
  API_ROOT: process.env.REACT_APP_SERVER_URL || 'http://localhost:8000/api',
  WP_ROOT: process.env.REACT_APP_SERVER_WP_URL || 'http://localhost:8000/wp',
  RECAPTCHA_SECRET_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
  BASE_URL: getBaseUrl(),
};
