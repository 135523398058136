import React, { Component } from 'react';
import Home from '../../components/home/Home';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { appLoadToken } from '../../actions/app';
import { addSnackbar } from '../../actions/snackbar';
import { loadRequired, loadRecommended,loadFeaturedCategories } from '../../actions/courses';
import { loadMyFinished, loadMyInProgress } from '../../actions/user';
import { withRouter } from 'react-router-dom';
import { goTo, goToExploreCategories, goToSearch, showResetPassword } from '../../actions/navigator';
import {COURSES_VIEW, REGISTER, SURVEY} from '../../config/routes';
import { router } from '../../config/routes';
import Loading from '../../components/ui/Loading';

class HomeContainer extends Component {
  componentDidMount() {
    this.props.appLoadToken().then(res => {
      if (res) {
        this.props.loadRecommended();
        this.props.loadRequired();
        this.props.loadMyFinished();
        this.props.loadMyInProgress();
        this.props.loadFeaturedCategories();
      }
    });
  }

  showCourse = course => {
    const { goTo } = this.props;
    goTo(router.getRoute(COURSES_VIEW, { id: course.id }));
  };


  render() {
    const { me, courses } = this.props;
    return me.loading ? (
      <Loading  fullSize={true} />
    ) : (
      <Home
        onShowCourse={this.showCourse}
        onGoToExplore={() => this.props.goToExploreCategories()}
        onGoToSearch={()=> this.props.goToSearch()}
        me={me.user}
        courses={courses}
        onGoToResetPassword={()=> this.props.showResetPassword()}
        onGoToRegister={()=> this.props.goTo(REGISTER)}
        onGoToSurvey={()=> this.props.goTo(SURVEY)}
        addSnackbar={this.props.addSnackbar}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    me: { ...state.userReducer },
    courses: {
      required: { ...state.courses.required },
      recommended: { ...state.courses.recommended },
      finished: { ...state.me.finished },
      inProgress: { ...state.me.inProgress },
      featuredCategories: {...state.featuredCategoriesReducer}
    }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showResetPassword,
      appLoadToken,
      goTo,
      goToExploreCategories,
      goToSearch,
      loadRequired,
      loadRecommended,
      loadMyFinished,
      loadMyInProgress,
      loadFeaturedCategories,
      addSnackbar
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomeContainer)
);
