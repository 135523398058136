import {
  ADMIN_CATEGORIES,
  ADMIN_USERS,
  ADMIN_TAGS,
  ADMIN_COURSES,
  ADMIN_REPORTS, ADMIN_REPORTS_USERS, ADMIN_REPORTS_SURVEYS
} from '../../../config/routes';

export const ITEM_USERS = 'ITEM_USERS';
export const ITEM_CATEGORIES = 'ITEM_CATEGORIES';
export const ITEM_TAGS = 'ITEM_TAGS';
export const ITEM_COURSES = 'ITEM_COURSES';
export const ITEM_REPORTS = 'ITEM_REPORTS';
export const ITEM_REPORTS_COURSES = 'ITEM_REPORTS_COURSES';
export const ITEM_REPORTS_SURVEYS = 'ITEM_REPORTS_SURVEYS';
//export const ITEM_ACTIVITIES = 'ITEM_ACTIVITIES';

export const menuItems = {
  items: [
    {
      title: 'Cursos',
      icon: 'school',
      action: ITEM_COURSES,
      route: ADMIN_COURSES
    },
    {
      title: 'Categorías',
      icon: 'category',
      action: ITEM_CATEGORIES,
      submenu: false,
      route: ADMIN_CATEGORIES
    },
    {
      title: 'Usuarios',
      icon: 'people',
      action: ITEM_USERS,
      submenu: false,
      route: ADMIN_USERS
    },
    {
      title: 'Etiquetas',
      icon: 'style',
      action: ITEM_TAGS,
      submenu: false,
      route: ADMIN_TAGS
    },
    {
      title: 'Reportes',
      icon: 'format_list_bulleted',
      action: ITEM_REPORTS_COURSES,
      submenu: true,
      items:[
        {
          title: 'Usuarios',
          icon: 'format_list_bulleted',
          action: ITEM_REPORTS_COURSES,
          submenu: false,
          route: ADMIN_REPORTS_USERS
        },
        {
          title: 'Actividad',
          icon: 'format_list_bulleted',
          action: ITEM_REPORTS_COURSES,
          submenu: false,
          route: ADMIN_REPORTS
        },
        {
          title: 'Encuestas',
          icon: 'format_list_bulleted',
          action: ITEM_REPORTS_SURVEYS,
          submenu: false,
          route: ADMIN_REPORTS_SURVEYS
        },
      ],
      route: ITEM_REPORTS_COURSES
    }

    /* {
      title: 'Actividades',
      icon: 'format_list_numbered',
      action: ITEM_ACTIVITIES,
      route: ADMIN_ACTIVITIES,
      submenu: false
    }*/
  ]
};
