import React, { Component } from 'react';
import {Button} from '@material-ui/core';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = {
  colorButton: {
    minWidth: 120,
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '14px',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    color: '#fff'
  },
  extraViewContainer: {
    width: 16,
    marginTop: 4,
    marginRight: 4
  },
  disabled: {
    backgroundColor: 'rgba(177,177,177,1) !important'
  }
};

class ColorButton extends Component {
  render() {
    const {
      onClick,
      label,
      classes,
      className,
      color,
      disabled,
      extraView,
      disableRipple
    } = this.props;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: color || '#000000'
        },
        secondary: {
          main: '#000000'
        }
      },
      typography: {
        useNextVariants: true,
        fontFamily: [
          'Open Sans',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"'
        ].join(',')
      }
    });

    return (
      <MuiThemeProvider theme={theme}>
        <Button
          className={classNames(classes.colorButton, className)}
          classes={{ disabled: classes.disabled }}
          disableRipple={disableRipple || false}
          style={{}}
          onClick={() => {
            onClick();
          }}
          disabled={disabled || false}
          variant="contained"
          color="primary"
        >
          {extraView ? (
            <div className={classes.extraViewContainer}> {extraView}</div>
          ) : null}
          {label}
        </Button>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(ColorButton);
