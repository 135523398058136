import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goTo } from '../../../actions/navigator';
import {
  getResources,
  deleteResource,
  refreshResources,
  updateResource
} from '../../../actions/admin';
import {
  ADMIN_EDIT,
  ADMIN_NEW,
  router,
  ADMIN_CATEGORIES
} from '../../../config/routes';
import AdminResourceIndex from '../AdminResourceIndex';
import {
  FiltersFormat,
  EditInputType,
  SnackbarType,
  AdminItemViewType
} from '../../../config/constants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';
import { addSnackbar } from '../../../actions/snackbar';
import CategoriesDrawer from '../../../components/admin/categories/CategoriesDrawer';
import api from "../../../api/api";

const KEY_NAME = 'admin-categories';

const DEFAULT_CATEGORIES_ORDER = [
  {
    name: 'category.sort',
    order: 'ASC'
  }
];

class AdminCategoriesContainer extends Component {
  state = {
    confirmDeleteOpen: false,
    deleteItem: null,
    itemToDelete: '',
    drawer: false
  };

  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size);
  };

  onRenderCustomItemIcon = item => {
    return (
      <div
        style={{
          borderStyle: 'solid',
          borderColor: '#554d5633',
          borderRadius: 2,
          borderWidth: 1,
          width: 36,
          height: 36,
          maxWidth: 36,
          maxHeight: 36,
          padding: 0,
          background: item.color
        }}
      >
        <img
          style={{
            width: 34,
            height: 34,
            padding: 1
          }}
          src={api.signUrl(item.icon)}
          alt={''}
        />
      </div>
    );
  };

  getColumns = () => {
    return [
      { title: 'ID', source: 'id' },
      { title: 'Nombre', source: 'name' },
      { title: 'Descripción', source: 'description' },
      {
        title: 'Diseño',
        source: 'icon',
        type: AdminItemViewType.Custom,
        renderItem: this.onRenderCustomItemIcon
      },
      { title: 'Visible', source: 'visible', type: AdminItemViewType.Boolean },
      {
        title: 'Destacada',
        source: 'featured',
        type: AdminItemViewType.Boolean
      }
    ];
  };

  onCreate = () => {
    this.props.goTo(ADMIN_CATEGORIES + ADMIN_NEW);
  };

  onEditItem = item => {
    this.props.goTo(
      router.getRoute(ADMIN_CATEGORIES + ADMIN_EDIT, { id: item.id })
    );
  };

  onDeleteItem = item => {
    this.setState({
      confirmDeleteOpen: true,
      deleteItem: item,
      itemToDelete: item.name
    });
  };

  getActions = item => {
    return [
      {
        title: 'EDITAR',
        action: this.onEditItem,
        icon: <EditIcon />
      },
      {
        title: 'ELIMINAR',
        action: this.onDeleteItem,
        icon: <DeleteIcon />
      }
    ];
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Text,
        name: 'category.name',
        placeholder: 'Nombre',
        format: FiltersFormat.Like
      },
      {
        type: EditInputType.Text,
        name: 'category.description',
        placeholder: 'Descripción',
        format: FiltersFormat.Like
      },
      {
        type: EditInputType.Boolean,
        name: 'category.visible',
        placeholder: 'Visible',
        format: FiltersFormat.Plain
      }
    ];
  };

  deleteItem = () => {
    const { deleteResource, refreshResources, addSnackbar, type } = this.props;
    const item = this.state.deleteItem;
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null
    });
    deleteResource(type, item.id)
      .then(() => {
        addSnackbar('Categoría eliminada.', SnackbarType.Success);
        refreshResources(KEY_NAME);
      })
      .catch(err => {
        addSnackbar('Error: ' + err.message, SnackbarType.Error);
      });
  };

  cancelDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      itemToDelete: '',
      deleteItem: null
    });
  };

  showDrawer = () => {
    this.setState(prevState => ({ drawer: !prevState.drawer }));
  };

  submitCategoriesOrder = async categories => {
    const { updateResource, addSnackbar, type, paginator } = this.props;
    await updateResource(`${type}`, null, { categories });
    addSnackbar(
      'Ordenamiento de categorías actualizado exitosamente.',
      SnackbarType.Success
    );
    this.showDrawer();
    this.onFetch(KEY_NAME, paginator.query, paginator.page, paginator.size);
  };

  render() {
    const { confirmDeleteOpen, itemToDelete, drawer } = this.state;

    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'CATEGORÍAS'}
        getActions={this.getActions}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        onCreate={this.onCreate}
        sort={this.showDrawer}
        getOrderingConfig={() => DEFAULT_CATEGORIES_ORDER}
      >
        <CategoriesDrawer
          drawer={drawer}
          categories={this.props.paginator.items}
          onSubmit={this.submitCategoriesOrder}
          showDrawer={this.showDrawer}
        />
        <ConfirmDialog
          title={'Eliminar Categoría'}
          open={confirmDeleteOpen}
          description={`¿Estás seguro de eliminar la categoría ${itemToDelete}?`}
          onContinue={() => this.deleteItem()}
          onCancel={() => this.cancelDelete()}
        />
      </AdminResourceIndex>
    );
  }
}

AdminCategoriesContainer.defaultProps = {
  type: 'categories'
};

const mapStateToProps = (state, ownProps) => {
  const states = state.paginatorReducer;
  return {
    paginator: { ...states[KEY_NAME] }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResources,
      refreshResources,
      deleteResource,
      goTo,
      addSnackbar,
      updateResource
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCategoriesContainer);
