import React from 'react';
import { FormControlLabel, Switch} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    // color: '#ff0000'
    width: '100em',
    height: '100em'
  }
};

const SwitchInput = ({ value, checked, onChange, classes, label }) => {
  return (
    <FormControlLabel
      control={<Switch value={value} checked={checked} onChange={onChange} />}
      label={label}
    />
  );
};

export default withStyles(styles)(SwitchInput);
