import {
  SURVEY_NEXT,
  SURVEY_BACK,
  SURVEY_START,
  SURVEY_END,
  SURVEY_FAILED,
  SURVEY_RESET,
} from "./index";
import api from '../api/api';

export function resetSteps() {
  return async (dispatch) => {
    dispatch({ type: SURVEY_RESET });
  };
}

export function nextStep() {
  return async (dispatch) => {
    dispatch({ type: SURVEY_NEXT });
  };
}

export function prevStep() {
  return async (dispatch) => {
    dispatch({ type: SURVEY_BACK });
  };
}


export function saveSurvey(data) {
  return async (dispatch) => {
    try {
      dispatch({type: SURVEY_START});
      await api.Survey.save(data);
      dispatch({type: SURVEY_END});
    }catch (e) {
      dispatch({type:   SURVEY_FAILED, payload: e});
    }
  };
}
