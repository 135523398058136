import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ColorButton from '../../ui/ColorButton';
import { EditInputType } from '../../../config/constants';
import OpenButton from '@material-ui/icons/ArrowDropUp';
import CloseButton from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import { itemEdit } from '../../../utils/AdminHelper';
import classnames from 'classnames';
import { Table, Grid,TableBody,TableCell,TableRow,Typography,Paper,IconButton } from '@material-ui/core';

const styles = theme => ({
  content: {
    flexGrow: 1,
    flexDirection: 'column'
  },
  grid: {
    flexGrow: 1,
    flexDirection: 'row'
  },

  filterChip: {
    marginTop: 8
  },
  filterButton: {
    marginRight: 8,
    boxShadow: 'none'
  },
  header: {
    position: 'relative',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    height: 60,
    backgroundColor: '#868686',
    color: '#fff'
  },
  headerClosed: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4
  },
  headerIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#fff'
  },
  title: {
    color: '#fff',
    fontSize: 20,
    paddingTop: 16,
    paddingLeft: 16
  },
  filterContent: {
    width: '100%',
    minHeight: 60,
    backgroundColor: '#fff'
  }
});

class AdminFilters extends Component {
  state = {
    open: false,
    filters: {}
  };

  getFilterByConfig = f => {
    return itemEdit(f, this.state.filters[f.name], this.handleOnChange);
  };

  componentDidMount = () => {
    this.loadFilters(this.props);
  };

  componentWillReceiveProps = nextProps => {
    const { filters = {} } = this.props;
    if (!_.isEqual(filters, this.state.filters)) {
      this.loadFilters(nextProps);
    }
  };

  loadFilters = props => {
    const { filters = false, getFilterConfig } = props;
    if (filters !== false) {
      const fixedFilters = getFilterConfig().filter(
        f => f.type === EditInputType.Fixed
      );
      fixedFilters.forEach(f => (filters[f.name] = f.fixedValue));
      this.setState({ filters: filters }, () => {
        this.applyFilters();
      });
    }
  };

  handleOnChange = (name, value) => {
    const { filters } = { ...this.state };
    const currentState = filters;
    if (value == null || value === '') {
      delete currentState[name];
    } else if (Array.isArray(value)) {
      currentState[name] = value.length ? [...value] : undefined;
    } else if (typeof value === 'object') {
      currentState[name] = { ...value };
    } else {
      currentState[name] = value;
    }
    this.setState({ filters: currentState });
  };

  applyFilters = () => {
    const { onFiltersChange } = this.props;
    onFiltersChange(this.state.filters);
  };

  clearFilters = () => {
    this.setState({ filters: {} }, () => this.applyFilters());
  };

  render() {
    const { getFilterConfig, classes } = this.props;
    const { open } = this.state;
    const filtersConfig = getFilterConfig && getFilterConfig();

    const filtersComponent = filtersConfig && (
      <Table>
        <TableBody
          style={{ paddingTop: 16, paddingBottom: 16, display: 'block' }}
        >
          <TableRow>
            <TableCell style={{ borderBottom: 'none' }}>
              <Grid container spacing={16}>
                {filtersConfig
                  .filter(f => f.type !== EditInputType.Fixed)
                  .map(f => {
                    return (
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={6}
                        key={'key-filter-' + f.name}
                        className={classes.filter}
                      >
                        {this.getFilterByConfig(f)}
                      </Grid>
                    );
                  })}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ColorButton
                    className={classes.filterButton}
                    color={'#3abf91'}
                    onClick={this.applyFilters}
                    label={'Aplicar'}
                  />
                  <ColorButton
                    className={classes.filterButton}
                    onClick={this.clearFilters}
                    color={'#4b4b4b'}
                    label={'Limpiar'}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );

    const table = (
      <Paper>
        <div
          className={classnames(classes.header, {
            [classes.headerClosed]: !open
          })}
          onClick={() => this.setState({ open: !open })}
        >
          <Typography className={classes.title}>FILTROS</Typography>
          <IconButton
            className={classes.headerIcon}
            onClick={() => this.setState({ open: !open })}
          >
            {open ? <OpenButton /> : <CloseButton />}
          </IconButton>
        </div>
        {open && (
          <div className={classes.filterContent}>{filtersComponent}</div>
        )}
      </Paper>
    );

    return filtersConfig && table;
  }
}

export default withStyles(styles)(AdminFilters);
