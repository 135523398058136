import {
  RESET_PASSWORD_END,
  RESET_PASSWORD_START,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_GET_TOKEN_END,
  RESET_PASSWORD_GET_TOKEN_FAILED,
  RESET_PASSWORD_GET_TOKEN_START,
  RESET_PASSWORD_NEXT,
  RESET_PASSWORD_BACK,
  RESET_PASSWORD_RESET
} from "../actions";

let initialState = {
  step: 0,
  loading: false,
  error: false
};

const MAX_STEPS = 2;

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_RESET:
      return {
        ...state,
        step: 0,
        loading: false,
        error: false
      };
    case RESET_PASSWORD_BACK:
      return {
        ...state,
        step: (state.step>0)?state.step-1:0,
        loading: false,
        error: false
      };
    case RESET_PASSWORD_NEXT:
      return {
        ...state,
        step: (state.step<MAX_STEPS)?state.step+1:MAX_STEPS,
        loading: false,
        error: false
      };
    case RESET_PASSWORD_GET_TOKEN_START:
    case RESET_PASSWORD_START:
      return {
          ...state,
          loading: true,
          error: false
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        step: 2,
        error: 'Hubo un problema y no hemos podido generar tu clave'
      };
    case RESET_PASSWORD_GET_TOKEN_FAILED:
    case RESET_PASSWORD_GET_TOKEN_END:
      return {
        ...state,
        loading: false,
        error: false,
        step: 1
      };
    case RESET_PASSWORD_END:
      return {
        ...state,
        loading: false,
        error: false,
        step: 2
      };
    default:
      return {...state};
  }
};
