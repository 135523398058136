import request from './request';
import config from '../config';
import {encode} from 'querystring';

let token = null;
let dispatch = null;

const requests = {
  del: (url) =>
    request(
      {
        method: 'DELETE',
        url: `${config.API_ROOT}${url}`,
        token: token,
      },
      dispatch,
    ),
  get: (url) =>
    request(
      {
        method: 'GET',
        url: `${config.API_ROOT}${url}`,
        token: token,
      },
      dispatch,
    ),
  put: (url, body) =>
    request(
      {
        method: 'PUT',
        url: `${config.API_ROOT}${url}`,
        data: body,
        token: token,
      },
      dispatch,
    ),
  post: (url, body, withCredentials = false) =>
    request(
      {
        method: 'POST',
        url: `${config.API_ROOT}${url}`,
        data: body,
        token: token,
        withCredentials: withCredentials,
      },
      dispatch,
    ),
  file: (url) =>
    request(
      {
        method: 'GET',
        url: `${config.API_ROOT}${url}`,
        token: token,
        download: true,
        data: {download: true},
      },
      dispatch,
    ),
  multipart: (url, data, withCredentials = false) => {
    const form = new FormData();
    Object.keys(data).forEach((key) => form.append(key, data[key]));
    return request(
      {
        method: 'POST',
        url: `${config.API_ROOT}${url}`,
        headers: {
          'content-type': 'multipart/form-data',
        },
        token: token,
        withCredentials: withCredentials,
        data: form,
      },
      dispatch,
    );
  },
};

const Wordpress = {
  login: () => requests.post('/users/authWP', {}, true),
  createContent: (data) =>
    request(
      {
        method: 'POST',
        url: `${config.WP_ROOT}/wp-admin/admin-ajax.php?action=create_new_h5p`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: encode(data),
        token: token,
        withCredentials: true,
      },
      dispatch,
    ),
  createScormContent: () =>
    request(
      {
        method: 'POST',
        url: `${config.WP_ROOT}/wp-admin/admin-ajax.php?action=create_new_scorm`,
        withCredentials: true,
      },
      dispatch,
    ),
};
const Auth = {
  session: (data) => requests.multipart('/users/session', data),
  getLoggedUser: () => requests.get('/users/me'),
  logout: () => (token = null),
  resetPassword: (email) =>
    requests.post('/users/reset_password', {email: email}),
  registerUser: (data) => requests.post('/users/register', data),
  changePasswordToken: (email, code, password) =>
    requests.post('/users/change_password_token', {
      email: email,
      token: code,
      new_password: password,
    }),
  sendSms: (email, phone) =>
    requests.post('/users/send_sms', {email: email, phone: phone}),
};

const Admin = {
  //TODO: agregar /admin a todos los paths
  getResources: (resourceType, page = 1, size = 20, query = '') => {
    const pagination = 'pagination[page]=' + page + '&pagination[size]=' + size;
    return requests.get(
      '/admin/' +
        resourceType +
        '?' +
        encodeURI(pagination + (query ? '&' + query : '')),
    );
  },
  getResource: (resourceType, id) =>
    requests.get('/admin/' + resourceType + '/' + id),
  createResource: (resourceType, data) =>
    requests.post('/admin/' + resourceType, data),
  updateResource: (resourceType, id, data) =>
    requests.put(
      '/admin/' + resourceType + (id !== null ? '/' + id : ''),
      data,
    ),
  deleteResource: (resourceType, id) =>
    requests.del('/admin/' + resourceType + '/' + id),
  downloadReport: (filters) =>
    requests.file(`/admin/user_courses/xls${filters ? `?${filters}` : ''}`),
  downloadReportUsers: (filters) =>
    requests.file(`/admin/reports/users/xls${filters ? `?${filters}` : ''}`),
  downloadReportSurveys: (filters) =>
    requests.file(`/admin/reports/surveys/xls${filters ? `?${filters}` : ''}`),

  getCoursePreview: (id) => requests.get('/admin/courses/' + id + '/preview'),
  getCoursePreviewBlocks: (id, page = 1, size = 1) =>
    requests.get(
      '/admin/courses/' +
        id +
        '/blocks/preview?pagination[size]=' +
        size +
        '&pagination[page]=' +
        page,
    ),
  createFileActivity: (resourceId, data) =>
    requests.multipart('/admin/' + resourceId, data),
};

const Filter = {
  tags: (query) =>
    requests.get(
      '/admin/tags?' +
        encodeURI(
          'filters[tag.name][LIKE]=%' +
            query +
            '%&pagination[size]=50&pagination[page]=1',
        ),
    ),
  categories: (query) =>
    requests.get(
      '/admin/categories?' +
        encodeURI(
          'filters[category.name][LIKE]=%' +
            query +
            '%&pagination[size]=50&pagination[page]=1',
        ),
    ),
  users: (query) => {
    const filters = ['username', 'firstName', 'lastName', 'email']
      .map((field) => `filters[OR][user.${field}][LIKE]=%${query}%`)
      .join('&');
    const uri = encodeURI(`${filters}&pagination[size]=50&pagination[page]=1`);
    return requests.get(`/admin/users?${uri}`);
  },
  courses: (query) =>
    requests.get(
      '/admin/courses?' +
        encodeURI(
          'filters[course.title][LIKE]=%' +
            query +
            '%&pagination[size]=50&pagination[page]=1',
        ),
    ),
};

const Create = {
  tags: (name) => requests.post('/admin/tags', {name}),
  categories: (name) => requests.post('/admin/categories', {name}),
};

const Categories = {
  all: () => requests.get('/categories'),
  get: (id) => requests.get('/categories/' + id),
};

const Courses = {
  get: (id) => requests.get('/courses/' + id),
  allPublished: () => requests.get('/published_courses'),
  all: () => requests.get('/courses'),
  getAllRecommended: () =>
    requests.get('/courses?filters[course.recommended]=1'),
  getAllRequired: () => requests.get('/courses?filters[course.required]=1'),
  getBlocks: (id, page = 1, size = 1) =>
    requests.get(
      '/courses/' +
        id +
        '/blocks?pagination[size]=' +
        size +
        '&pagination[page]=' +
        page,
    ),
  getBlock: (courseId, blockId) =>
    requests.get('/courses/' + courseId + '/blocks/' + blockId),
  getReviews: (id, page = 1, size = 1) =>
    requests.get(
      '/courses/' +
        id +
        '/user_ratings?pagination[size]=' +
        size +
        '&pagination[page]=' +
        page,
    ),
  getAllForFeaturedCategories: () =>
    requests.get(
      '/courses?filters[category.featured]=1&ordering[category.name]=asc',
    ),
  searchByText: (query) =>
    requests.get('/courses/search?q=' + encodeURI(query || '')),
};

const My = {
  Courses: {
    inProgress: () =>
      requests.get('/users/me/courses?filters[userCourse.completed]=0'),
    completed: () =>
      requests.get('/users/me/courses?filters[userCourse.completed]=1'),
    rate: (id, data) => requests.post('/users/me/course_ratings/' + id, data),
  },
  Activities: {
    setDone: (courseId, id) =>
      requests.post('/users/me/courses/' + courseId + '/activities/' + id),
  },
};

const signUrl = (url) => {
  if (url && url.startsWith(config.API_ROOT)) {
    const parsedUrl = new URL(url);
    parsedUrl.searchParams.set('bearer', token);
    return parsedUrl.toString();
  } else {
    return url;
  }
};

const Occupations = {
  all: () => requests.get('/occupations'),
};

const Survey = {
  save: (data) => requests.post('/surveys', data),
};

const api = {
  Survey,
  Wordpress,
  My,
  Courses,
  Categories,
  Create,
  Filter,
  Admin,
  Auth,
  Occupations,
  setToken: (_token) => {
    token = _token;
  },
  hasToken: () => {
    return Boolean(token);
  },
  withDispatch: (d) => {
    dispatch = d;
    return api;
  },
  signUrl: (u) => signUrl(u),
};

export default api;
