import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import HorizontalSliderArrow from "./HorizontalSliderArrow";
import { HorizontalSlideArrowType } from "../../config/constants";
import classNames from "classnames";

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    title: {
      fontSize: 24,
      color: "#fff",
      paddingTop: 16,
      [mediaLg]: {
        marginLeft: 16,
        fontSize: 16
      }
    },
    zoomSlider: {
      gridColumn: "2 / 13",
      color: "#fff",
      "&__container::-webkit-scrollbar": {
        display: "none"
      },
      "&_arrow": {},
      "&__container": {
        position: "relative",
        scrollbarWidth: "none",
        transition: "transform 450ms",
        overflowY: "hidden",
        overflowX: "scroll",
        display: "flex",
        width: "100%",
        margin: "auto",
        // gridTemplateColumns: 'repeat(20, 1fr)',
        columnGap: 0,
        "&:hover &--item": {
          opacity: 0.3,
          "&:hover": {
            transform: "scale(1.25)",
            opacity: 1
          }
        },
        "&:hover &--item:first-child": {
          paddingLeft: 45
        },
        "&:hover &--item:last-child": {
          paddingRight: 45
        },
        "&:hover > *": {
          transform: "translate3d(-45px, 0, 0)"
        },
        "&--item:hover ~ &--item": {
          transform: "translate3d(45px, 0, 0)"
        },
        "&--item": {
          position: "relative",
          cursor: "pointer",
          transition: "all 450ms",
          transform: "center left",
          paddingTop: 40,
          paddingBottom: 40,
          height: 180,
          minHeight: 0,
          [mediaLg]: {
            paddingTop: 16
          },
          "&:not(:last-child)": {
            paddingRight: 4
          },
          "&-image": {
            height: "100%"
          }
        }
      },
      [mediaLg]: {
        marginTop: 40
      }
    },
    wrapper: {
      position: "relative",
      '-ms-overflow-style':'none'
    },
    arrow: {
      position: "absolute"
    },
    arrowRight: {
      position: "absolute",
      right: 0
    }
  };
};

class HorizontalZoomSlider extends Component {
  constructor(props) {
    super(props);
    this.myScrollContainerRef = React.createRef();
    this.state = { rightVisible: false, leftVisible: false };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateArrows);
    this.myScrollContainerRef.current.addEventListener(
      "scroll",
      this.updateArrows
    );
    this.updateArrows();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateArrows);
    this.myScrollContainerRef.current.removeEventListener(
      "scroll",
      this.updateArrows
    );
  }

  getItems = items => {
    const {
      classes,
      itemCreator = i => null,
      itemClick = i => console.log("click on", i),
      itemWidth = 320,
      itemHeight = 180
    } = this.props;

    return items.map((i, index) => {
      return (
        <div
          key={index}
          onClick={() => itemClick(i)}
          className={classes.zoomSlider + "__container--item"}
          style={{ width: itemWidth, minWidth: itemWidth, height: itemHeight }}
        >
          {itemCreator(i)}
        </div>
      );
    });
  };

  scrollLeft = (element, change, duration) => {
    var start = element.scrollLeft,
      currentTime = 0,
      increment = 20;
    const delta = this.calcMaxDelta(change);
    var animateScroll = () => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, delta, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  handleScrollRight = () => {
    const view = this.myScrollContainerRef.current;
    this.scrollLeft(view, 500, 400);
  };

  handleScrollLeft = () => {
    const view = this.myScrollContainerRef.current;
    this.scrollLeft(view, -500, 400);
  };

  updateArrows = () => {
    const view = this.myScrollContainerRef.current;
    const visibleWidth = view.clientWidth;
    const totalWidth = view.scrollWidth;
    const totalScrolled = view.scrollLeft;
    const leftVisible = totalScrolled !== 0;
    const rightVisible = visibleWidth + totalScrolled < totalWidth;
    this.setState({ rightVisible, leftVisible });
  };

  calcMaxDelta = delta => {
    const view = this.myScrollContainerRef.current;
    const totalScrolled = view.scrollLeft;
    const variation = totalScrolled + delta;
    const totalWidth = view.scrollWidth - view.clientWidth;
    if (variation < 0) {
      return -totalScrolled;
    } else if (variation > totalWidth) {
      return totalWidth - totalScrolled;
    }
    return delta;
  };

  render() {
    const { classes, items, title, customStyleTitle, icon = null } = this.props;

    return (
      <div className={classes.zoomSlider}>
        {(title || icon) && (
          <div>
            <Typography className={`${classes.title} ${customStyleTitle}`}>
              {icon}
              {title}
            </Typography>
          </div>
        )}
        <div className={classes.wrapper}>
          {this.state.leftVisible && (
            <HorizontalSliderArrow
              className={classes.arrow}
              type={HorizontalSlideArrowType.left}
              onClick={this.handleScrollLeft}
            />
          )}
          {this.state.rightVisible && (
            <HorizontalSliderArrow
              className={classNames(classes.arrow, classes.arrowRight)}
              type={HorizontalSlideArrowType.right}
              onClick={this.handleScrollRight}
            />
          )}
          <div
            className={classes.zoomSlider + "__container"}
            ref={this.myScrollContainerRef}
          >
            {this.getItems(items)}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HorizontalZoomSlider);
