import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  goToSearch,
  goToUserProfile,
  goHome,
  goAdmin,
  goToExploreCategories
} from "../../actions/navigator";
import FrontNavBar from "../../components/ui/FrontNavBar";
import { FrontNavBarActionTypes } from "../../config/constants";
import { toggleMenu } from "../../actions/menuDrawer";

class FrontNavBarContainer extends Component {
  onToggleMenu = () => {
    this.props.toggleMenu();
  };

  handleActionClick = actionType => {
    switch (actionType) {
      case FrontNavBarActionTypes.Home:
        this.props.goHome();
        break;
      case FrontNavBarActionTypes.Search:
        this.props.goToSearch();
        break;
      case FrontNavBarActionTypes.Profile:
        this.props.goToUserProfile();
        break;
      case FrontNavBarActionTypes.AdminHome:
        this.props.goAdmin();
        break;
      case FrontNavBarActionTypes.Explore:
        this.props.goToExploreCategories();
        break;
      default:
        return false;
    }
  };

  render() {
    const { me, location } = this.props;
    return (
      <FrontNavBar
        onToggleMenu={this.onToggleMenu}
        onActionClick={this.handleActionClick}
        me={me.user}
        location={location}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    me: { ...state.userReducer }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { goToSearch, goToUserProfile, goHome, goAdmin, toggleMenu, goToExploreCategories },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrontNavBarContainer);
