import React, { Component } from 'react';
import config from '../../config/index';

export default class SvgIcon extends Component {
  render() {
    const { iconName = '', className = null, style = {} } = this.props;
    return (
      <img
        alt={iconName}
        className={className}
        src={config.BASE_URL + 'img/icons/' + iconName + '.svg'}
        style={{ width: '1em', height: '1em', ...style }}
      />
    );
  }
}
