import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  createResource,
  updateResource,
  getResource
} from '../../../actions/admin';
import {
  ActivitiesTypesList,
  ActivityType,
  EditInputType,
  SnackbarType
} from '../../../config/constants';
import AdminResourceForm from '../../../components/admin/AdminResourceForm';
import { addSnackbar } from '../../../actions/snackbar';
import { ADMIN_ACTIVITIES } from '../../../config/routes';
import { goTo } from '../../../actions/navigator';
import validator from 'validator';
import { getBase64 } from '../../../utils/FileHelper';

const FORM_COLUMNS = [
  {
    title: 'Nombre',
    source: 'name',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'name' }
  },
  {
    title: 'Descripción',
    source: 'description',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'description' }
  },
  {
    title: 'Tipo',
    source: 'type',
    editable: true,
    editConfig: {
      type: EditInputType.Select,
      name: 'type',
      options: ActivitiesTypesList.map(t => {
        return { id: t.type, description: t.description };
      })
    }
  }
];

const FORM_TYPES = {
  [ActivityType.Text]: {
    title: 'Texto',
    source: 'text',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'text' }
  },
  [ActivityType.External]: {},
  [ActivityType.Scorm]: {},
  [ActivityType.Trivia]: {},
  [ActivityType.Video]: {},
  [ActivityType.Audio]: {
    title: 'Link',
    source: 'link',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'link' }
  },
  [ActivityType.Image]: {
    title: 'Imagen',
    source: 'image',
    editable: true,
    editConfig: {
      type: EditInputType.File,
      accept: '.jpg,.jpeg',
      name: 'image'
    }
  }
};

class AdminActivitiesFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      name: '',
      description: '',
      type: '',
      text: '',
      image: null,
      link: ''
    },
    errors: {
      name: '',
      description: '',
      type: '',
      text: '',
      image: '',
      link: ''
    }
  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData();
    }
  };

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.item && nextProps.item) {
      const { name, description = '' } = nextProps.item;
      this.setState({ form: { name, description } }, () => this.validateForm());
    }
  };

  onAttributeChange = (name, value) => {
    let form = this.state.form;
    if (name === 'type') {
      form.image = null;
      form.link = '';
      form.text = '';
    }
    this.setState({ form: { ...form, [name]: value } }, () =>
      this.validateForm()
    );
  };

  getColumns = () => {
    const { type } = this.state.form;
    return {
      keys: FORM_TYPES[type]
        ? [...FORM_COLUMNS, FORM_TYPES[type]]
        : FORM_COLUMNS
    };
  };

  getRequestDataFromForm = async () => {
    const { form } = this.state;
    let data = {
      name: form.name,
      description: form.description,
      type: form.type
    };

    switch (form.type) {
      case ActivityType.Text:
        data.text = form.text;
        break;
      case ActivityType.External:
      case ActivityType.Scorm:
      case ActivityType.Trivia:
      case ActivityType.Video:
      case ActivityType.Audio:
        data.url = form.link;
        break;
      case ActivityType.Image:
        data.image = await getBase64(form.image[0]);
        break;
      default:
        break;
    }
    return data;
  };

  onSubmit = async () => {
    const {
      addSnackbar,
      goTo,
      createResource,
      updateResource,
      isEdit,
      item,
      type
    } = this.props;
    const data = await this.getRequestDataFromForm();
    if (isEdit) {
      updateResource(type, item.id, data)
        .then(() => {
          addSnackbar('Actividad editada exitosamente.', SnackbarType.Success);
          goTo(ADMIN_ACTIVITIES);
        })
        .catch(err => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    } else {
      createResource(type, data)
        .then(() => {
          addSnackbar('Actividad creada exitosamente.', SnackbarType.Success);
          goTo(ADMIN_ACTIVITIES);
        })
        .catch(err => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    }
  };

  validateForm = () => {
    const form = this.state.form;
    let error = false;
    let errors = {
      name: '',
      description: '',
      type: '',
      text: '',
      image: '',
      link: ''
    };

    if (validator.isEmpty(form.type)) {
      errors.type = 'Debes seleccionar un tipo de actividad.';
      error = true;
    }

    if (validator.isEmpty(form.name.trim())) {
      errors.name = 'Debes completar este campo';
      error = true;
    } else if (!validator.isLength(form.name.trim(), { min: 2, max: 140 })) {
      errors.name = 'Debes completar entre 2 y 140 caracteres.';
      error = true;
    }

    if (validator.isEmpty(form.description.trim())) {
      errors.description = 'Debes completar este campo';
      error = true;
    } else if (
      !validator.isLength(form.description.trim(), { min: 2, max: 140 })
    ) {
      errors.description = 'Debes completar hasta 140 caracteres.';
      error = true;
    }

    switch (form.type) {
      case ActivityType.Text:
        if (validator.isEmpty(form.text.trim())) {
          errors.text = 'Debes completar este campo';
          error = true;
        } else if (
          !validator.isLength(form.text.trim(), { min: 2, max: 140 })
        ) {
          errors.text = 'Debes completar entre 1 y 500 caracteres.';
          error = true;
        }
        break;
      case ActivityType.External:
      case ActivityType.Scorm:
      case ActivityType.Trivia:
      case ActivityType.Video:
      case ActivityType.Audio:
        if (!validator.isURL(form.link)) {
          errors.link = 'Debes completar con una URL válida';
          error = true;
        }
        break;
      case ActivityType.Image:
        if (!form.image) {
          errors.image = 'Debes seleccionar una imagen';
          error = true;
        }
        break;
      default:
        break;
    }

    this.setState({ errors: errors, isValid: !error });
    return !error;
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_ACTIVITIES);
  };

  render() {
    const { loading, title } = this.props;
    return (
      <AdminResourceForm
        keyName={'admin-activities-form'}
        title={title}
        form={this.state.form}
        isValid={this.state.isValid}
        errors={this.state.errors}
        loading={loading}
        attributes={this.getColumns()}
        onSubmit={this.onSubmit}
        onAttributeChange={this.onAttributeChange}
        onGoBack={this.onGoBack}
      />
    );
  }
}

AdminActivitiesFormContainer.defaultProps = {
  type: 'activities'
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    ...states[ownProps.keyName]
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResource, createResource, updateResource, addSnackbar, goTo },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminActivitiesFormContainer);
