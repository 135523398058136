import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResources, downloadReportUsers } from '../../../actions/admin';
import AdminResourceIndex from '../AdminResourceIndex';

import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel, AdminItemViewType,
} from '../../../config/constants';

const KEY_NAME = 'users';

class AdminUsersReportsContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size);
  };

  getColumns = () => {
    return [
      { title: 'Nombre', source: 'first_name' },
      { title: 'Apellido', source: 'last_name' },
      { title: 'Documento', source: 'document' },
      { title: 'Email', source: 'email' },
      { title: 'Teléfono', source: 'phone' },
      { title: 'Empresa', source: 'company' },
      { title: 'Bloqueado?', source: 'blocked', type: AdminItemViewType.Custom, renderItem: (item) => (item.blocked) ? 'Si' : 'No' },
      { title: 'Actividad en cursos', source: 'courses_count', type: AdminItemViewType.Custom, renderItem: (item) => (item.courses_count > 0) ? 'Si' : 'No'},
    ];
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'user.id',
        placeholder: 'Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: (a) => `${a.last_name}, ${a.first_name}`
      },
      {
        type: EditInputType.Boolean,
        placeholder: 'Bloqueado?',
        format: FiltersFormat.Plain,
        name: 'user.blocked'
      },
    ];
  };

  onDownload = () => {
    const {
      downloadReportUsers,
      paginator: { query }
    } = this.props;
    downloadReportUsers(query);
  };

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTES'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onDownload={this.onDownload}
      />
    );
  }
}

AdminUsersReportsContainer.defaultProps = {
  type: 'reports/users'
};

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer;
  return {
    paginator: { ...paginatorState[KEY_NAME] }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResources, downloadReportUsers }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersReportsContainer);
