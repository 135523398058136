import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResource } from '../../../actions/admin';
import AdminResourceView from '../../../components/admin/AdminResourceView';
import { goTo } from '../../../actions/navigator';
import { ADMIN_USERS } from '../../../config/routes';
import { AdminItemViewType } from '../../../config/constants';

class AdminUsersViewContainer extends Component {
  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  getTemplate = () => {
    return {
      keys: [
        { title: 'Nombre', source: 'first_name' },
        { title: 'Apellido', source: 'last_name' },
        { title: 'Email', source: 'email' },
        {
          title: 'Teléfono',
          source: 'phone'
        },
        { title: 'Dni', source: 'document' },
        {
          title: 'Administrador',
          source: 'is_admin',
          type: AdminItemViewType.Boolean
        },
      ]
    };
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_USERS);
  };

  componentDidMount = () => {
    this.onLoadData();
  };

  render() {
    const { item, loading } = this.props;
    return (
      <AdminResourceView
        title={'USUARIO'}
        item={item}
        loading={loading}
        attributes={this.getTemplate()}
        onGoBack={() => this.onGoBack()}
        onEdit={null}
      />
    );
  }
}

AdminUsersViewContainer.defaultProps = {
  type: 'users'
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    ...states[ownProps.keyName]
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResource, goTo }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersViewContainer);
