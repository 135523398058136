import config from './index';
export const HOME = config.BASE_URL;
export const TOKEN = config.BASE_URL + 'setToken';
export const VIEW = 'view/:id';
export const COURSES = config.BASE_URL + 'courses/';

export const RESET_PASSWORD = config.BASE_URL + 'resetPassword';
export const REGISTER = config.BASE_URL + 'register';
export const SURVEY = config.BASE_URL + 'survey';
export const COURSES_VIEW = COURSES + VIEW;
export const COURSES_EXPLORE = COURSES + 'explore';
export const COURSES_EXPLORE_CATEGORY = COURSES_EXPLORE + '/:categoryId';
export const BASE_ADMIN = '/admin';
export const PROFILE_ME = config.BASE_URL + 'profile';

export const IDFW = config.BASE_URL + 'idfw';

export const ADMIN_VIEW = '/view/:id';
export const ADMIN_EDIT = '/edit/:id';
export const ADMIN_NEW = '/new';
export const ADMIN_HOME = config.BASE_URL + 'admin';
export const ADMIN_CATEGORIES = config.BASE_URL + 'admin/categories';
export const ADMIN_TAGS = config.BASE_URL + 'admin/tags';
export const ADMIN_COURSES = config.BASE_URL + 'admin/courses';
export const ADMIN_COURSES_BASIC = config.BASE_URL + 'admin/courses/basic';
export const ADMIN_COURSES_BLOCK = config.BASE_URL + 'admin/courses/block';
export const ADMIN_ACTIVITIES = config.BASE_URL + 'admin/activities';
export const ADMIN_USERS = config.BASE_URL + 'admin/users';
export const ADMIN_REPORTS = config.BASE_URL + 'admin/reports';
export const ADMIN_REPORTS_USERS = config.BASE_URL + 'admin/reports/users';
export const ADMIN_REPORTS_SURVEYS = config.BASE_URL + 'admin/reports/surveys';

export const SEARCH = config.BASE_URL + 'search';

export const router = {
  getRoute: (route, params) => {
    let keys = Object.keys(params);
    var url = route;
    let query = [];
    keys.forEach(k => {
      let newUrl = url.replace(':' + k, params[k]);
      if (url === newUrl) {
        query.push(k + '=' + params[k]);
      } else {
        url = newUrl;
      }
    });
    if (query.length > 0) {
      url += '?' + query.join('&');
    }
    return url;
  }
};
