import React, {Component} from 'react';
import Main from './Main';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import {app_theme} from '../themes/app_theme';
import FrontNavBarContainer from '../containers/ui/FrontNavBarContainer';
import FooterContainer from '../containers/ui/FooterContainer';
import SnackbarMessageContainer from '../containers/ui/SnackbarMessage';
import MenuDrawerContainer from '../containers/menu/MenuDrawer';
import CookieConsent from 'react-cookie-consent';

const theme = createMuiTheme(app_theme());

const FOOTER_HEIGHT = '80px';

const styles = theme => ({

  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    minHeight: `calc(100vh - ${FOOTER_HEIGHT})`,
    maxWidth: '100vw',
  },
});

class Layout extends Component {
  render() {
    const {classes, me, location} = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        {me && <FrontNavBarContainer location={location} />}
        <div className={classes.root}>
          <MenuDrawerContainer me={me} />
          <Main />
        </div>
        {me && <FooterContainer />}
        <SnackbarMessageContainer />
        <CookieConsent
          location="bottom"
          buttonText="Entendido"
          cookieName="academia-salud-cookie"
          sameSite={'strict'}
          cookieSecurity={true}
          expires={15}
          style={{background: '#ffffff', color: '#000000'}}
          contentStyle={{padding: 16}}
          buttonStyle={{
            color: '#ffffff',
            fontSize: '16px',
            background: '#b7366c',
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 8,
            paddingBottom: 8,
          }}
          >
          Para Banco Santander Río S.A. es importante adaptarse a tus gustos y
          preferencias, para ello usamos cookies propias y de terceros que nos
          permiten medir el volumen y la interacción de los usuarios en la web y
          nos ayudan a mejorarla, permitiéndonos analizar sus hábitos y elaborar
          perfiles de navegación, respetando en todo caso su privacidad. Podes
          aceptar nuestras cookies o no. Este banner se mantendrá activo hasta
          que ejecutes la aceptación. Para más información consulta nuestra{' '}
          <a
            target="_blank"
            href={
              process.env.REACT_APP_BASE_URL +
              'pdf/AcademiaSalud-PoliticaCookies.pdf'
            }>
            Política de Cookies
          </a>
          .
        </CookieConsent>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Layout);
