import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import config from '../../../config/index';
import { AppBar,Toolbar,IconButton } from '@material-ui/core';
import { FrontNavBarActionTypes } from '../../../config/constants';
import MenuIcon from '@material-ui/icons/Menu';
import LogoutContainer from '../../../containers/session/Logout';

const styles = theme => ({
  appBar: {
    backgroundColor: '#000000',
    zIndex: theme.zIndex.drawer + 1,
    transitionTimingFunction: 'ease-in',
    transition: 'all 1s'
  },
  toolbar: {
    position: 'relative'
  },
  actions: {
    minHeight: 50,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  home: {
    position: 'relative',
    boxSizing: 'border-box',
    minWidth: 'unset'
  },
  flex: {
    position: 'relative',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  action: {
    color: 'white'
  }
});

class AdminNavBar extends React.Component {
  handleDrawerToggle = () => {
    const { onToggleMenu } = this.props;
    onToggleMenu();
  };

  render() {
    const { classes, onActionClick } = this.props;

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            style={{ zIndex: 1001 }}
            color="inherit"
            aria-label="Open drawer"
            onClick={this.handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            className={classes.home}
            color="inherit"
            onClick={() => onActionClick(FrontNavBarActionTypes.Home)}
          >
            {' '}
            <img
              alt="Logo Academia"
              src={config.BASE_URL + 'img/ac_toolbar_icon.png'}
              height={'25px'}
              width={'25px'}
            />
          </IconButton>
          <div className={classes.flex}>
            <div className={classes.actions}>
              <LogoutContainer />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(AdminNavBar);
