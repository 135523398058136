import React, { Component } from 'react';
import {  withStyles } from '@material-ui/core/styles';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import classNames from 'classnames';
import {HorizontalSlideArrowType} from "../../config/constants";
const styles = () => ({
    arrow:{
        cursor:'pointer',
        position: 'relative',
        height: 'calc(100% - 80px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex:1000,
        width: 60,
        marginTop: 40,
        transition: 'none',
        '&:hover':{
            transition: 'all 450ms',
            backgroundColor: '#0000005e'
        }
    },
    arrowIcon:{
        textAlign: 'center',
        fontSize: 100,
    }
});

class HorizontalSliderArrow extends Component {

    render() {
        const { classes,className, onClick=()=>{}, type=HorizontalSlideArrowType.left } = this.props;
        return (<div className={classNames(classes.arrow,className)} onClick={onClick}>
                    {type === HorizontalSlideArrowType.left ?
                        <ArrowLeft className={classes.arrowIcon}/>
                        : <ArrowRight className={classes.arrowIcon}/> }
                </div>);
    }
}

export default withStyles(styles)(HorizontalSliderArrow);
