import React, { Component } from 'react';
import GenericDialog from '../../components/ui/GenericDialog';
import withStyles from '@material-ui/core/styles/withStyles';
import LockIcon from '@material-ui/icons/Lock';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import { Typography } from '@material-ui/core';
import {
  activityTypeIcon,
  activityTypeText
} from '../../utils/ActivitiesHelper';

const styles = () => ({
  content: {
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    margin: -24
  },
  activityType: {
    fontWeight: 700,
    fontSize: 12,
    color: '#1ed1a8',
    textTransform: 'uppercase'
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
    color: '#434343'
  },
  icon: {
    color: '#ffffff',
    margin: 4,
    fontWeight: 700,
    fontSize: 48
  },
  iconWrapper: {
    marginTop: 4,
    float: 'left',
  },
  iconBackground: {
    width: 56,
    height: 56,
    backgroundColor: '#bebebe',
    borderRadius: '50%',
    margin: 'auto',
  },
  row: {
    padding: 8,
    borderBottom: '1px solid #ececec',
    '&:last-child': {
      borderBottom: 'none'
    },
    position: 'relative'
  },
  description: {
    float: 'left',
    marginTop: 2,
    padding: 8,
    paddingLeft: 16
  },
  done: {
    position: 'absolute',
    top: 24,
    right: 16,
    color: '#34cb62',
    fontSize: 32,
    zIndex: 4
  }
});

class RequiredActivitiesDialog extends Component {
  renderActivityItem = (activity,key) => {
    const { classes, headerColor } = this.props;

    return (
      <div key={key} className={classes.row}>
        <div className={classes.iconWrapper}>
          <div className={classes.iconBackground}>
            {activityTypeIcon(activity.required.type, classes.icon)}
          </div>
        </div>
        <div className={classes.description}>
          <Typography
            className={classes.activityType}
            style={{ color: headerColor }}
          >
            {activityTypeText(activity.required.type)}
          </Typography>
          <Typography className={classes.title}>{activity.required.name}</Typography>
        </div>

          {activity.required.done !==0 && <DoneIcon className={classes.done} />}
      </div>
    );
  };

  renderContent = () => {
    const { classes, activity } = this.props;
    return (
      <div className={classes.content}>
        {activity.required_activities.map((a,i) => this.renderActivityItem(a,i))}
      </div>
    );
  };

  render() {
    const { open, onDismiss, headerColor,activity} = this.props;
    return (
      <GenericDialog
        open={open}
        headerColor={headerColor}
        onDismiss={onDismiss}
        name={'REQUISITOS DE LA ACTIVIDAD'}
        icon={<LockIcon style={{ fontSize: 100 }} />}
        title={activity.name}
        subtitle={
          'Para desbloquearla debes completar las siguientes actividades del sprint.'
        }
        onExit={this.resetDialog}
      >
        {this.renderContent()}
      </GenericDialog>
    );
  }
}

export default withStyles(styles)(RequiredActivitiesDialog);
