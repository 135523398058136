import {
  EDIT_ROW_INIT,
  EDIT_ROW_TOGGLE_EDITING,
  EDIT_ROW_CHANGE_VALUE,
  EDIT_ROW_START_SAVE,
  EDIT_ROW_END_SAVE,
  EDIT_ROW_FAIL_SAVE
} from '../actions';

let initialState = {
  key: 'default',
  error: null,
  loading: false,
  isEditing: false,
  value: '',
  edited: false
};

let states = {};

function getStateFor(key) {
  if (states[key] !== undefined) {
    return states[key];
  } else {
    states[key] = { ...initialState, key: key };
    return states[key];
  }
}

export default (state = initialState, action) => {
  const key = action.key || state.key || 'default';
  const currentState = getStateFor(key);
  switch (action.type) {
    case EDIT_ROW_INIT: {
      states[key] = { ...currentState };
      return states;
    }
    case EDIT_ROW_TOGGLE_EDITING:
      states[key] = {
        ...currentState,
        isEditing: !currentState.isEditing,
        value: '',
        edited: false
      };
      return states;
    case EDIT_ROW_CHANGE_VALUE:
      const { value } = action.payload;
      states[key] = {
        ...currentState,
        value: value,
        edited: true
      };
      return states;
    case EDIT_ROW_START_SAVE:
      states[key] = {
        ...currentState,
        loading: true,
        isEditing: false
      };
      return states;
    case EDIT_ROW_END_SAVE:
      states[key] = {
        ...currentState,
        loading: false,
        edited: false
      };
      return states;
    case EDIT_ROW_FAIL_SAVE:
      states[key] = {
        ...currentState,
        loading: false,
        isEditing: true
      };
      return states;
    default:
      states[key] = { ...currentState };
      return states;
  }
};
