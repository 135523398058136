import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {
    ADMIN_ACTIVITIES,
    ADMIN_CATEGORIES,
    ADMIN_COURSES,
    ADMIN_COURSES_BASIC,
    ADMIN_COURSES_BLOCK,
    ADMIN_EDIT,
    ADMIN_HOME,
    ADMIN_NEW,
    ADMIN_TAGS,
    ADMIN_USERS,
    ADMIN_VIEW,
    ADMIN_REPORTS, HOME, ADMIN_REPORTS_USERS, ADMIN_REPORTS_SURVEYS
} from '../../config/routes';
import AdminTagsContainer from '../../containers/admin/tags/AdminTags';
import AdminTagsViewContainer from '../../containers/admin/tags/AdminTagsView';
import AdminTagsFormContainer from '../../containers/admin/tags/AdminTagsForm';
import AdminCategoriesContainer from '../../containers/admin/categories/AdminCategories';
import AdminCategoriesFormContainer from '../../containers/admin/categories/AdminCategoriesForm';
import AdminUsersContainer from '../../containers/admin/users/AdminUsers';
import AdminUsersViewContainer from '../../containers/admin/users/AdminUsersView';
import AdminActivitiesContainer from '../../containers/admin/activities/AdminActivities';
import AdminCoursesContainer from '../../containers/admin/courses/AdminCourses';
import AdminCoursesBasicFormContainer from '../../containers/admin/courses/AdminCoursesBasicForm';
import AdminCoursesBlockFormContainer from '../../containers/admin/courses/AdminCoursesBlockForm';
import AdminActivitiesFormContainer from '../../containers/admin/activities/AdminActivitiesForm';
import AdminCoursesViewContainer from '../../containers/admin/courses/AdminCoursesViewContainer';
import AdminReportsContainer from '../../containers/admin/reports/AdminReports';
import {isAdmin} from "../../utils/AdminHelper";
import AdminUsersFormContainer from "../../containers/admin/users/AdminUsersForm";
import AdminUsersReportsContainer from "../../containers/admin/reports/AdminUsersReports";
import AdminSurveysReportsContainer from "../../containers/admin/reports/AdminSurveysReports";
import * as ReactGA from 'react-ga';

const styles = theme => ({
    content: {
        display: 'block',
        minWidth: 0, // So the Typography noWrap works,
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#f7f7f7',
        maxWidth: 'calc(100vw - 0%)'
    },
    toolbar: theme.mixins.toolbar
});

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
function AdminMain(props) {
    const {classes, me} = props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className={classes.content}>
            <div className={classes.toolbar}/>
            {(isAdmin(me)) ?
                <Switch>
                    <Route
                        exact
                        path={ADMIN_HOME}
                        component={() => <AdminCoursesContainer/>}
                    />
                    <Route
                        exact
                        path={ADMIN_CATEGORIES}
                        component={() => <AdminCategoriesContainer/>}
                    />
                    <Route
                        exact
                        path={ADMIN_CATEGORIES + ADMIN_EDIT}
                        component={({match}) => (
                            <AdminCategoriesFormContainer
                                keyName={'admin-categories-edit'}
                                params={match.params}
                                isEdit={true}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_CATEGORIES + ADMIN_NEW}
                        component={() => (
                            <AdminCategoriesFormContainer
                                keyName={'admin-categories-edit'}
                                isEdit={false}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_USERS}
                        component={() => <AdminUsersContainer/>}
                    />
                    <Route
                        exact
                        path={ADMIN_USERS + ADMIN_EDIT}
                        component={({match}) => <AdminUsersFormContainer keyName={'admin-users-edit'}
                                                                         params={match.params} isEdit={true}/>}
                    />
                    <Route
                        exact
                        path={ADMIN_USERS + ADMIN_VIEW}
                        component={({match}) => (
                            <AdminUsersViewContainer
                                keyName={'admin-users-view'}
                                params={match.params}
                            />
                        )}
                    />

                    <Route
                        exact
                        path={ADMIN_TAGS}
                        component={() => <AdminTagsContainer/>}
                    />
                    <Route
                        exact
                        path={ADMIN_TAGS + ADMIN_VIEW}
                        component={({match}) => (
                            <AdminTagsViewContainer
                                keyName={'admin-tags-view'}
                                params={match.params}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_TAGS + ADMIN_EDIT}
                        component={({match}) => (
                            <AdminTagsFormContainer
                                keyName={'admin-tags-edit'}
                                params={match.params}
                                isEdit={true}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_TAGS + ADMIN_NEW}
                        component={() => (
                            <AdminTagsFormContainer
                                keyName={'admin-tags-edit'}
                                isEdit={false}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_ACTIVITIES}
                        component={() => <AdminActivitiesContainer/>}
                    />
                    <Route
                        exact
                        path={ADMIN_ACTIVITIES + ADMIN_NEW}
                        component={() => (
                            <AdminActivitiesFormContainer
                                keyName={'admin-activities-edit'}
                                isEdit={false}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_COURSES}
                        component={() => <AdminCoursesContainer/>}
                    />
                    <Route
                        exact
                        path={ADMIN_COURSES_BASIC + ADMIN_EDIT}
                        component={({match}) => (
                            <AdminCoursesBasicFormContainer
                                keyName={'admin-course-content-edit'}
                                params={match.params}
                                isEdit={true}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_COURSES_BASIC + ADMIN_NEW}
                        component={() => (
                            <AdminCoursesBasicFormContainer
                                keyName={'admin-course-content-edit'}
                                isEdit={false}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_COURSES_BLOCK + ADMIN_EDIT}
                        component={({match}) => (
                            <AdminCoursesBlockFormContainer
                                keyName={'admin-course-content-edit'}
                                params={match.params}
                                isEdit={true}
                            />
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_COURSES + ADMIN_VIEW}
                        component={({match}) => (
                            <AdminCoursesViewContainer courseId={match.params.id}/>
                        )}
                    />
                    <Route
                        exact
                        path={ADMIN_REPORTS}
                        component={() => <AdminReportsContainer/>}
                    />
                    <Route
                        exact
                        path={ADMIN_REPORTS_USERS}
                        component={() => <AdminUsersReportsContainer/>}
                    />
                    <Route
                        exact
                        path={ADMIN_REPORTS_SURVEYS}
                        component={() => <AdminSurveysReportsContainer/>}
                    />
                </Switch>
                :
                <Redirect to={HOME}/>
            }
        </div>
    );
}

export default withStyles(styles)(AdminMain);
