import React, { Component } from 'react';
import Content from './ContentHome';
import {withStyles} from '@material-ui/core/styles';
import Header from './HeaderHome';
import Login from "../session/Login";

const styles = theme => {
  return {
    root: {
      backgroundColor: '#101010',
      width: '100%',
      maxWidth: 'calc(100vw - 0%)'
    },
    loginRoot: {
      height: '100%',
      backgroundColor: theme.palette.primary.main
    }
  };
};

class Home extends Component {
  render() {
    const { classes, onShowCourse, me, onGoToExplore, courses, onGoToSearch,onGoToResetPassword, onGoToRegister, onGoToSurvey} = this.props;


    return me ? (
        <div className={classes.root}>
          <Header onGoToExplore={onGoToExplore} onGoToSearch={onGoToSearch}  />
          <Content onShowCourse={onShowCourse} onGoToExplore={onGoToExplore} courses={courses} />
        </div>
    ) : (
        <div className={classes.loginRoot}>
          <Login onGoToResetPassword={onGoToResetPassword} onGoToRegister={onGoToRegister} onGoToSurvey={onGoToSurvey} addSnackbar={this.props.addSnackbar}/>
        </div>
    );
  }
}
export default withStyles(styles)(Home);
