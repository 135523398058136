import React, { Component } from 'react';
import { ACTIVITY_TYPE_COMPONENTS } from './ActivityTypeModule';
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  withStyles
} from '@material-ui/core';
import ActivityModuleHeader from './ActivityModuleHeader';
import {
  activityTypeIcon,
  activityTypeText
} from '../../../../utils/ActivitiesHelper';
import styles from './ActivityModuleStyles';

class ActivityModule extends Component {
  state = {
    openAlert: false
  };

  handleOnRemove = () =>
    this.setState(prevState => ({ openAlert: !prevState.openAlert }));

  render() {
    const {
      activity,
      onEdit,
      onRemove,
      onMilestone,
      classes,
      unableActionsCreateDelete,
      availableMilestone
    } = this.props;
    const { openAlert } = this.state;

    let ActivityComponent = null;
    if (ACTIVITY_TYPE_COMPONENTS.hasOwnProperty(activity.type)) {
      ActivityComponent = ACTIVITY_TYPE_COMPONENTS[activity.type];
    } else {
      console.error(
        `Activity type ${activity.type} does not exist as a component on ActivityTypeModule`
      );
    }

    return (
      <React.Fragment>
        <div className={classes.activityContainer}>
          <div className={classes.activityIcon}>
            {activityTypeIcon(activity.type, classes.icon)}
          </div>
          <div className={classes.activityData}>
            <ActivityModuleHeader
              onMilestone={() => onMilestone(activity)}
              onEdit={() => onEdit(activity)}
              onRemove={this.handleOnRemove}
              activity={activity}
              unableActionsCreateDelete={unableActionsCreateDelete}
              availableMilestone={availableMilestone}
            />
            <Typography className={classes.title}>{activity.name}</Typography>
            <Typography className={classes.description}>
              {activity.description}
            </Typography>
            <div className={classes.activityContent}>
              <ActivityComponent content={activity} />
            </div>
          </div>
        </div>
        <Dialog open={openAlert}>
          <DialogContent>
            <Typography>
              ¿Está seguro que quiere eliminar esta actividad?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onRemove(activity)}>Aceptar</Button>
            <Button onClick={this.handleOnRemove}>Cancelar</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ActivityModule);
