import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCurrent } from "../../actions/user";
import { appLoadToken } from "../../actions/app";
import { goHome } from "../../actions/navigator";
import { generateToken, nextStep, prevStep, resetPassword, resetSteps } from "../../actions/resetPassword";
import ResetPasswordStepper from "../../components/session/ResetPasswordStepper";

class ResetPasswordContainer extends Component {

  sendCode = (email) => {
    this.props.generateToken(email);
  };

  changePassword = (email, code, password) => {
    this.props.resetPassword(email,code,password);
  };

  handleBackTohome = () => {
    this.props.goHome();
    this.props.resetSteps();
  };

  handleBack = () => {
    this.props.prevStep();
  };

  handleSkip = () => {
    this.props.nextStep();
  };

  handleReset = () => {
    this.props.resetSteps();
  };

  render() {
    const { step, error, loading } = this.props;
    return  <ResetPasswordStepper onBack={this.handleBack} onSkip={this.handleSkip} onReset={this.handleReset} onBackToHome={this.handleBackTohome} onSendCode={this.sendCode} onChangePassword={this.changePassword} activeStep={step} error={error} loading={loading}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.resetPasswordReducer
  }
};


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getCurrent, appLoadToken, goHome, generateToken, nextStep, prevStep, resetPassword, resetSteps  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
