import validateExternal from './ExternalValidation';
import validateText from './TextValidation';
import validateH5p from './H5pValidation';
import validateImage from './ImageValidation';
import validateScorm from './ScormValidation';
import validateFile from './FileValidation';
import { ActivityType } from '../../../../config/constants';

export default {
  [ActivityType.Text]: validateText,
  [ActivityType.Image]: validateImage,
  [ActivityType.External]: validateExternal,
  [ActivityType.Scorm]: validateScorm,
  [ActivityType.Audio]: validateH5p,
  [ActivityType.Video]: validateH5p,
  [ActivityType.Trivia]: validateH5p,
  [ActivityType.Presentation]: validateH5p,
  [ActivityType.Timeline]: validateH5p,
  [ActivityType.File]: validateFile
};
