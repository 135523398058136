import React, { Component } from 'react';
import PaginatorComponent from '../../components/ui/Paginator';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  paginatePrev,
  paginateNext,
  init,
  paginateTo
} from '../../actions/paginator';

class PaginatorContainer extends Component {
  componentDidMount() {
    this.props
      .init(this.props.keyName)
      .then(() => this.handleFetch(this.props));
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = props => {
    const { fetch, keyName, onFetch, page, size, query } = props;
    if (fetch && keyName && keyName !== '') {
      onFetch(keyName, query, page, size);
    }
  };

  goToPage = page => {
    const { keyName } = this.props;
    this.props.paginateTo(keyName, page);
  };

  onNext = () => {
    const { keyName } = this.props;
    this.props.paginateNext(keyName);
  };

  onPrev = () => {
    const { keyName } = this.props;
    this.props.paginatePrev(keyName);
  };

  render() {
    const { page, keyName, hasNext, hasPrev, total } = this.props;
    return (
      <PaginatorComponent
        keyName={keyName}
        page={page}
        total={total}
        nextEnabled={hasNext}
        prevEnabled={hasPrev}
        onFirst={() => this.goToPage(1)}
        onLast={() => this.goToPage(total)}
        onNext={() => {
          this.onNext();
        }}
        onPrev={() => {
          this.onPrev();
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const states = state.paginatorReducer;
  return {
    ...states[ownProps.keyName]
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { paginatePrev, paginateNext, init, paginateTo },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginatorContainer);
