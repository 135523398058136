import React, { Component } from 'react';
import {
  withStyles,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip
} from '@material-ui/core';
import ActivityModule from './ActivityModule/ActivityModule';
import AddIcon from '@material-ui/icons/AddCircleRounded';
import RemoveIcon from '@material-ui/icons/RemoveCircleRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ActivitiesTypesList } from '../../../config/constants';

const styles = {
  actionsContainer: {
    margin: '2em 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addResource: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '4em',
    alignItems: 'center'
  },
  headerContainer: {
    width: '100%',
    marginTop: '1em',
    padding: '1em 2em 4em',
    boxSizing: 'border-box',
    background: '#1ed1a8',
    maxWidth: 'calc(100vw - 0%)',
  },
  blockDescription: {
    color: '#fff',
    marginTop: '.4em'
  },
  blockHandler: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    height: '3em'
  },
  blockLabel: {
    color: '#707070',
    marginTop: '1em'
  },
  blockName: {
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: 32,
    fontWeight: 'bold'
  },
  createActivityIcon: {
    height: '2em',
    width: '2em'
  },
  divider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2em 0',
    width: '100%'
  },
  iconsContainer: {
    display: 'flex',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2em'
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: '1em',

    '&:after': {
      content: `''`,
      width: '100%',
      borderBottom: '1px solid #707070'
    }
  }
};

const ActivityActionsColumns = ({ classes, onActivityAdd, blockId }) => {
  return (
    <div className={classes.actionsContainer}>
      <Typography>Selecciona un tipo de recurso:</Typography>
      <div className={classes.iconsContainer}>
        {ActivitiesTypesList.map(activityType => (
          <Tooltip
            key={activityType.type}
            title={activityType.description.toUpperCase()}
          >
            <IconButton
              className={classes.createActivityIcon}
              onClick={() => onActivityAdd(blockId, activityType.type)}
            >
              <activityType.icon />
            </IconButton>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

class BlockSection extends Component {
  state = {
    actionsVisible: false,
    openAlert: false
  };

  toggleActions = () => {
    this.setState(prevState => ({
      actionsVisible: !prevState.actionsVisible
    }));
  };

  toggleAlert = () => {
    this.setState(prevState => ({
      openAlert: !prevState.openAlert
    }));
  };

  handleOnBlockRemove = block => {
    const { onBlockRemove } = this.props;
    onBlockRemove(block);
    this.toggleAlert();
  };

  render() {
    const {
      classes,
      block,
      onBlockEdit,
      onActivityAdd,
      onActivityRemove,
      onActivityEdit,
      onMilestone,
      unableActionsCreateDelete,
      availableMilestone
    } = this.props;

    const { actionsVisible, openAlert } = this.state;

    return (
      <div>
        <div className={classes.headerContainer}>
          <div className={classes.blockHandler}>
            <Tooltip title="EDITAR">
              <IconButton onClick={() => onBlockEdit(block)}>
                <EditIcon style={{ color: '#fff' }} />
              </IconButton>
            </Tooltip>
            {unableActionsCreateDelete !== true && (
              <Tooltip title="ELIMINAR">
                <IconButton onClick={this.toggleAlert}>
                  <DeleteIcon style={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <Typography className={classes.blockLabel}>Nombre</Typography>
          <Typography className={classes.blockName}>{block.title}</Typography>
          <Typography className={classes.blockLabel}>Descripción</Typography>
          <Typography className={classes.blockDescription}>
            {block.description}
          </Typography>
        </div>
        {block.block_activities &&
          block.block_activities.length > 0 &&
          block.block_activities.map(activity => (
            <div key={activity.id}>
              <ActivityModule
                activity={{
                  ...activity.activity,
                  blockId: block.id
                }}
                key={activity.id}
                onRemove={onActivityRemove}
                onEdit={onActivityEdit}
                onMilestone={onMilestone}
                unableActionsCreateDelete={unableActionsCreateDelete}
                availableMilestone={availableMilestone}
              />
            </div>
          ))}
        {unableActionsCreateDelete !== true && (
          <div className={classes.addResource}>
            <div className={classes.divider}>
              <div className={classes.line} />
              <Tooltip title={actionsVisible ? 'OCULTAR' : 'AGREGAR ACTIVIDAD'}>
                <IconButton onClick={this.toggleActions}>
                  {actionsVisible ? (
                    <RemoveIcon style={{ height: '1.4em', width: '1.4em' }} />
                  ) : (
                    <AddIcon style={{ height: '1.4em', width: '1.4em' }} />
                  )}
                </IconButton>
              </Tooltip>
              <div className={classes.line} />
            </div>
            {actionsVisible && (
              <ActivityActionsColumns
                classes={classes}
                onActivityAdd={onActivityAdd}
                blockId={block.id}
              />
            )}
          </div>
        )}
        <Dialog
          open={openAlert}
          aria-labelledby="alert-title"
          aria-describedby="alert-description"
        >
          <DialogTitle id="alert-title">Atención</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-description">
              {block && block.block_activities && block.block_activities.length
                ? 'Para poder borrar este bloque, primero debe eliminar todas las actividades asociadas...'
                : '¿Está seguro que quiere borrar este bloque?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {block &&
            block.block_activities &&
            block.block_activities.length ? (
              <Button onClick={this.toggleAlert} color="primary" autoFocus>
                Aceptar
              </Button>
            ) : (
              <React.Fragment>
                <Button
                  onClick={() => this.handleOnBlockRemove(block)}
                  color="primary"
                  autoFocus
                >
                  Aceptar
                </Button>
                <Button onClick={this.toggleAlert} color="primary" autoFocus>
                  Cancelar
                </Button>
              </React.Fragment>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(BlockSection);
