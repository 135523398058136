import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import {withStyles} from '@material-ui/core/styles';
import validator from 'validator';
import Typography from '@material-ui/core/Typography';
import {MuiThemeProvider} from '@material-ui/core/styles/index';
import {
  Button,
  FormGroup,
  FormHelperText,
  InputBase,
  MenuItem,
  Select,
} from '@material-ui/core';
import config from '../../config/index';
import {SnackbarType} from '../../config/constants';
import {login_theme} from '../../themes/login_theme';
import PreLoginLinks from './PreLoginLinks';

const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  const mediaMd = theme.breakpoints.down(theme.mediaQueries.md);
  const mediaSm = theme.breakpoints.down(theme.mediaQueries.sm);

  return {
    phoneLabel: {
      fontSize: 16,
      fontFamily: 'Open Sans',
      lineHeight: 1,
      fontWeight: 300,
      textAlign: 'left',
      color: '#ffffff',
    },
    phoneFormControl: {
      width: '100%',
      color: '#ffffff',
      margin: 0,
      border: 0,
      display: 'inline-flex',
      padding: 0,
      position: 'relative',
      minWidth: 0,
      marginBottom: 16,
      flexDirection: 'column',
      verticalAlign: 'top',
    },
    inputRow: {
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 0,
      marginTop: 24,
      minWidth: '100%',
      fontSize: 16,
      fontFamily: 'Open Sans',
    },

    inputRowItemShort: {
      float: 'left',
      marginRight: 4,
      maxWidth: 40,
      textAlign: 'center',
      flexShrink: 0,
    },
    inputRowPlaceHolder: {
      float: 'left',
      marginRight: 4,
      padding: 4,
      flexShrink: 0,
    },
    inputRowItem: {
      float: 'left',
      marginRight: 4,
      width: '100%',
      flexShrink: 1,
    },

    formContainer: {
      minWidth: 400,
      maxWidth: 600,
      margin: 'auto',
      [mediaMd]: {
        minWidth: 250,
      },
      [mediaSm]: {
        minWidth: 200,
      },
    },
    login: {
      position: 'relative',
      height: '100vh',
      backgroundSize: 'cover',
      background: `no-repeat center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'auto',
      flexDirection: 'column',
    },
    loginContent: {
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: 250,
      marginBottom: '4em',
    },
    logoAc: {
      margin: 'auto',
      width: '80%',
      maxWidth: 357,
    },
    titleContainer: {
      display: 'flex',
      marginBottom: 32,
      alignItems: 'center',
      [mediaLg]: {
        flexDirection: 'column',
      },
    },
    title: {
      color: '#fff',
      fontSize: 22,
      fontWeight: 300,
      lineHeight: 0.9,
      [mediaLg]: {
        marginTop: 24,
        fontSize: 24,
        padding: '0 8px',
        width: '100%',
        wordBreak: 'break-word',
        boxSizing: 'border-box',
      },
    },

    buttonsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 340,
      maxWidth: '90%',
      margin: 'auto',
      flexDirection: 'column',
      [mediaLg]: {
        flexDirection: 'column',
        minWidth: 300,
      },
      [mediaSm]: {
        flexDirection: 'column',
        minWidth: 200,
      },
      marginTop: 16,
    },
    button: {
      margin: '2px 16px',
      width: '110%',
      height: 56,
      borderRadius: 4,
      background: '#b7366c',
      boxShadow: '0 5px 8.2px 0 rgba(183, 54, 108, 0.25)',
      [mediaLg]: {
        marginTop: 16,
      },
      '&-disabled': {
        background: 'linear-gradient(150deg, #757070, #383232 100%)',
        boxShadow: 'none',
        color: '#444444 !important',
      },
    },
    buttonText: {
      color: '#fff',
      fontWeight: 700,
      fontSize: 20,
    },
    iconButton: {
      color: '#fff',
      marginRight: 16,
      fontSize: 40,
    },
    linkButton: {},
    linkButtonText: {
      color: '#fff',
      fontWeight: 500,
      fontSize: 16,
    },
    error: {
      color: '#ff0000',
    },
    smsMessage: {
      paddingTop: 16,
    },
    smsMessageLine: {
      wordBreak: 'break-word',
      width: '80%',
      textAlign: 'center',
      margin: 'auto',
      color: '#fff',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 400,
      whiteSpace: 'normal',
    },
    disabledInput: {
      color: 'rgba(255, 255, 255,0.7)',
    },
  };
};
const OTHER_OCCUPATION_ID = 0;

class Register extends React.Component {
  initialState = {
    step: 0,
    code: '',
    authy_id: '',
    email: '',
    password: '',
    password2: '',
    first_name: '',
    last_name: '',
    document: '',
    area: '',
    phoneNumber: '',
    phone: '',
    company: '',
    occupations: [],
    other_occupation: '',
    showPassword: false,
    showPassword2: false,
    validForm: false,
    clearErrors: false,
    selectIsOpen: false,
    errors: {
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      document: '',
      password2: '',
      phone: '',
      company: '',
      code: '',
      occupations: '',
      other_occupation: '',
    },
  };

  state = this.initialState;

  handleContinue = () => {
    if (!this.props.loading) {
      if (this.state.step === 0) {
        this.props
          .onSendSMS(this.state.email, this.state.phone)
          .then((data) => {
            if (data && data.authy_id) {
              this.setState({
                step: 1,
                authy_id: data.authy_id,
                clearErrors: true,
              });
            } else {
              this.setState({clearErrors: false});
            }
          });
      } else if (this.state.step === 1) {
        const {
          email,
          first_name,
          last_name,
          document,
          password,
          phone,
          company,
          code,
          authy_id,
          occupations,
          other_occupation,
        } = this.state;
        this.props
          .onRegister({
            email,
            first_name,
            last_name,
            document,
            password,
            phone,
            company,
            code,
            authy_id,
            occupations: occupations.filter(
              (oId) => oId !== OTHER_OCCUPATION_ID,
            ),
            other_occupation,
          })
          .then((res) => {
            if (res) {
              this.setState(this.initialState, () => {
                this.props.addSnackbar(
                  'Tu cuenta fue activada! Ya podes iniciar sesión',
                  SnackbarType.Success,
                );
                this.props.onBackToHome();
              });
            } else {
              this.setState({clearErrors: false});
            }
          });
      }
    }
  };

  handleBack = () => {
    if (!this.props.loading) {
      if (this.state.step === 0) {
        this.props.onBackToHome();
      }
      if (this.state.step === 1) {
        this.setState({step: 0, code: '', authy_id: ''}, () => {
          this.validateForm();
        });
      }
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleSelectIsOpen = (isOpen) => {
    this.setState({...this.state, selectIsOpen: isOpen});
  };

  handleChange = (event) => {
    const newState = {[event.target.name]: event.target.value};
    if (event.target.name === 'occupations') {
      if (!event.target.value.includes(OTHER_OCCUPATION_ID)) {
        newState['other_occupation'] = '';
      } else if (!this.state.occupations.includes(OTHER_OCCUPATION_ID)) {
        newState['selectIsOpen'] = false;
      }
    }
    this.setState(newState, () => {
      this.validateForm();
    });
  };

  handleChangePhone = (event) => {
    let valueArea = this.state.area;
    let valuePhoneNumber = this.state.phoneNumber;
    if (event.target.name === 'area') {
      let newArea = event.target.value;
      if (/^[1-9]?$/gm.test(newArea) || /^[1-9][0-9]{0,3}$/gm.test(newArea)) {
        valueArea = newArea;
        valuePhoneNumber = '';
      }
    }

    if (event.target.name === 'phoneNumber') {
      const area = this.state.area;
      const re = new RegExp('^[0-9]{0,' + (10 - area.length) + '}?$', 'gm');
      let newPhoneNumber = event.target.value;
      if (re.test(newPhoneNumber)) {
        valuePhoneNumber = newPhoneNumber;
      }
    }

    this.setState(
      {
        area: valueArea,
        phoneNumber: valuePhoneNumber,
        phone: valueArea + valuePhoneNumber,
      },
      () => {
        this.validateForm();
      },
    );
  };

  validateForm = () => {
    const {
      email,
      first_name,
      last_name,
      document,
      password,
      password2,
      area,
      phoneNumber,
      company,
      step,
      code,
      occupations,
      other_occupation,
    } = this.state;
    let isValid = true;
    const errors = {...this.initialState.errors};
    if (!validator.isEmail(email)) {
      isValid = false;
      errors.email = 'Email inválido';
    }
    if (validator.isEmpty(first_name.trim())) {
      isValid = false;
      errors.first_name = 'Debes completar este campo';
    }
    if (validator.isEmpty(last_name.trim())) {
      isValid = false;
      errors.last_name = 'Debes completar este campo';
    }
    if (validator.isEmpty(document.trim())) {
      isValid = false;
      errors.document = 'Debes completar este campo';
    }
    if (validator.isEmpty(password.trim())) {
      isValid = false;
      errors.password = 'Debes completar este campo';
    } else if (validator.isEmpty(password2.trim())) {
      isValid = false;
      errors.password2 = 'Debes completar este campo';
    } else if (password !== password2) {
      isValid = false;
      errors.password2 = 'Las contraseñas no coinciden';
    }

    const phone = area.trim() + phoneNumber.trim();
    if (validator.isEmpty(phone)) {
      isValid = false;
      errors.phone = 'Debes completar este campo';
    } else if (!validator.isLength(area.trim(), {min: 2, max: 4})) {
      isValid = false;
      errors.phone = 'El código de área debe tener entre 2 y 4 dígitos';
    } else if (!validator.isLength(phone, {min: 10})) {
      isValid = false;
      errors.phone = 'El número debe tener entre 6 y 8 dígitos';
    }

    if (validator.isEmpty(company.trim())) {
      isValid = false;
      errors.company = 'Debes completar este campo';
    } else if (!validator.isLength(company.trim(), {max: 300})) {
      isValid = false;
      errors.company =
        'Longitud máxima 300 caracteres. Completados ' + company.trim().length;
    }

    if (occupations.length === 0) {
      isValid = false;
      errors.occupations = 'Debes completar este campo';
    } else if (occupations.includes(OTHER_OCCUPATION_ID)) {
      if (validator.isEmpty(other_occupation)) {
        isValid = false;
        errors.other_occupation = 'Debes completar este campo';
      }
    }

    if (step === 1) {
      if (validator.isEmpty(code.trim())) {
        isValid = false;
        errors.code = 'Debes completar este campo';
      }
    }
    this.setState({validForm: isValid, errors: errors, clearErrors: true});
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({showPassword: !state.showPassword}));
  };

  handleClickShowPassword2 = () => {
    this.setState((state) => ({showPassword2: !state.showPassword2}));
  };

  getOccupations = () => {
    const {occupations} = this.props;
    console.log('getOccupations', occupations);
    return [
      ...occupations.items,
      {id: OTHER_OCCUPATION_ID, name: 'Otras tareas'},
    ];
  };

  render() {
    const {classes, loading, error} = this.props;
    const occupations = this.getOccupations();
    return (
      <div className={classes.login}>
        <div
          className={classes.loginContent}
          style={this.state.step === 1 ? {marginTop: 0} : {}}>
          <img
            src={config.BASE_URL + 'img/ic_banner_academia_2.png'}
            alt="logo banner"
            className={classes.logoAc}
          />
          <div className={classes.formContainer}>
            <MuiThemeProvider theme={login_theme}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}>
                {loading ? null : !this.state.clearErrors &&
                  error &&
                  typeof error === 'string' ? (
                  <Typography className={classes.error}>{error}</Typography>
                ) : null}
                {this.state.step === 1 ? (
                  <FormGroup>
                    <div className={classes.smsMessage}>
                      <Typography className={classes.smsMessageLine}>
                        {'Te enviamos un código SMS al teléfono:'}
                      </Typography>
                      <Typography
                        className={
                          classes.smsMessageLine
                        }>{`+54 ( 0${this.state.area} ) - 15 - ${this.state.phoneNumber}`}</Typography>
                      <Typography className={classes.smsMessageLine}>
                        {'Ingresalo a continuación para finalizar el registro'}
                      </Typography>
                    </div>
                    <FormControl className={classes.formItem}>
                      <InputLabel htmlFor="code">Código</InputLabel>
                      <Input
                        name="code"
                        disableUnderline={true}
                        value={this.state.code}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <FormHelperText error={true}>
                        {this.state.errors.code}
                      </FormHelperText>
                    </FormControl>
                  </FormGroup>
                ) : null}
                {this.state.step === 0 ? (
                  <FormGroup>
                    <FormControl className={classes.formItem}>
                      <InputLabel htmlFor="first_name">Nombre</InputLabel>
                      <Input
                        name="first_name"
                        disableUnderline={true}
                        value={this.state.first_name}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <FormHelperText error={true}>
                        {this.state.errors.first_name}
                      </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formItem}>
                      <InputLabel htmlFor="last_name">Apellido</InputLabel>
                      <Input
                        name="last_name"
                        disableUnderline={true}
                        value={this.state.last_name}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <FormHelperText error={true}>
                        {this.state.errors.last_name}
                      </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formItem}>
                      <InputLabel htmlFor="document">Documento</InputLabel>
                      <Input
                        name="document"
                        disableUnderline={true}
                        value={this.state.document}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <FormHelperText error={true}>
                        {this.state.errors.document}
                      </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formItem}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input
                        name="email"
                        disableUnderline={true}
                        value={this.state.email}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <FormHelperText error={true}>
                        {this.state.errors.email}
                      </FormHelperText>
                    </FormControl>
                    <div className={classes.phoneFormControl}>
                      <Typography className={classes.phoneLabel}>
                        {'Teléfono celular'}
                      </Typography>
                      <div className={classes.inputRow}>
                        <div className={classes.inputRowPlaceHolder}>
                          {'+54 ( 0'}
                        </div>
                        <InputBase
                          placeholder={'Área'}
                          className={classes.inputRowItemShort}
                          name="area"
                          value={this.state.area}
                          onChange={(e) => this.handleChangePhone(e)}
                        />
                        <div className={classes.inputRowPlaceHolder}>
                          {' ) - 15 -'}
                        </div>
                        <InputBase
                          placeholder={'Número'}
                          className={classes.inputRowItem}
                          name="phoneNumber"
                          value={this.state.phoneNumber}
                          onChange={(e) => this.handleChangePhone(e)}
                        />
                      </div>
                      <FormHelperText
                        style={{color: 'rgba(255, 255, 255,0.7)'}}
                        error={false}>
                        {
                          'Te enviaremos por SMS un código que deberás usar en el siguiente paso'
                        }
                      </FormHelperText>
                      <FormHelperText error={true}>
                        {this.state.errors.phone}
                      </FormHelperText>
                    </div>
                    <FormControl className={classes.formItem}>
                      <InputLabel htmlFor="company">
                        Empresa/Institución/Independiente
                      </InputLabel>
                      <Input
                        name="company"
                        disableUnderline={true}
                        value={this.state.company}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <FormHelperText error={true}>
                        {this.state.errors.company}
                      </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formItem}>
                      <InputLabel htmlFor="occupations">Ocupación</InputLabel>
                      <Select
                        style={{maxWidth: 400}}
                        name="occupations"
                        multiple={true}
                        value={this.state.occupations}
                        placeholder={'Seleccione una o mas ocupaciones'}
                        onChange={(e) => this.handleChange(e)}
                        options={occupations}
                        renderValue={(value) => {
                          return occupations
                            .filter((o) => value.includes(o.id))
                            .map((o) => o.name)
                            .join(', ');
                        }}
                        open={this.state.selectIsOpen}
                        onOpen={() => this.handleSelectIsOpen(true)}
                        onClose={() => this.handleSelectIsOpen(false)}>
                        {occupations.map((option) => (
                          <MenuItem
                            key={'menu-option-' + option.id}
                            value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {this.state.errors.occupations}
                      </FormHelperText>
                    </FormControl>
                    {this.state.occupations.includes(OTHER_OCCUPATION_ID) && (
                      <FormControl className={classes.formItem}>
                        <InputLabel htmlFor="other_occupation">
                          Contanos cuál/es son las otras tareas
                        </InputLabel>
                        <Input
                          name="other_occupation"
                          disableUnderline={true}
                          value={this.state.other_occupation}
                          onChange={(e) => this.handleChange(e)}
                        />
                        <FormHelperText error={true}>
                          {this.state.errors.other_occupation}
                        </FormHelperText>
                      </FormControl>
                    )}
                    <FormControl className={classes.formItem}>
                      <InputLabel htmlFor="password">Contraseña</InputLabel>
                      <Input
                        name="password"
                        disableUnderline={true}
                        value={this.state.password}
                        type={this.state.showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Cambiar visibilidad de la contraseña"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}>
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        autoComplete={'off'}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <FormHelperText error={true}>
                        {this.state.errors.password}
                      </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formItem}>
                      <InputLabel htmlFor="password2">
                        Confirmar contraseña
                      </InputLabel>
                      <Input
                        name="password2"
                        disableUnderline={true}
                        value={this.state.password2}
                        type={this.state.showPassword2 ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Cambiar visibilidad de la contraseña"
                              onClick={this.handleClickShowPassword2}
                              onMouseDown={this.handleMouseDownPassword}>
                              {this.state.showPassword2 ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        autoComplete={'off'}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <FormHelperText error={true}>
                        {this.state.errors.password2}
                      </FormHelperText>
                    </FormControl>
                  </FormGroup>
                ) : null}
              </form>
            </MuiThemeProvider>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              disabled={!this.state.validForm}
              onClick={() => this.handleContinue()}
              className={
                classes.button +
                (!this.state.validForm
                  ? ' ' + classes.button + '-disabled'
                  : '')
              }
              variant="outlined"
              form="login-form">
              <Typography className={classes.buttonText}>
                {this.state.step === 0 ? 'CONTINUAR' : 'REGISTRARME'}
              </Typography>
            </Button>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.linkButton}
              onClick={() => this.handleBack()}>
              <Typography className={classes.linkButtonText}>VOLVER</Typography>
            </Button>
          </div>
        </div>
        <PreLoginLinks />
      </div>
    );
  }
}

export default withStyles(styles)(Register);
