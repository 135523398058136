import React, { Component } from 'react';
import {TextField} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  input: {
    color: '#7f7f7f',
    float: 'left',
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    fontWeight: 400,
    fontSize: 14
  },
  container: {
    width: '100%'
  },
  root: {
    border: '1px solid black'
  }
});

class TextInput extends Component {
  handleOnChange = event => {
    const { name, onChange } = this.props;
    const value = event.target.value;
    onChange(name, value);
  };

  render() {
    const {
      name,
      value,
      placeholder,
      label,
      type = 'text',
      classes,
      containerClass,
      multiline,
      autoFocus=false,
      ...others
    } = this.props;

    delete others['onChange'];

    return (
      <div className={`${classes.container} ${containerClass}`}>
        <TextField
          inputProps={{
            className: classes.input,
            ...others
          }}
          variant={multiline ? 'outlined' : 'standard'}
          rows={multiline || '1'}
          name={name}
          type={type}
          fullWidth={true}
          onChange={this.handleOnChange}
          value={value}
          placeholder={placeholder}
          label={label}
          multiline={!!multiline}
          autoFocus={autoFocus}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TextInput);
