import {
  USER_LOGIN_END,
  USER_LOGIN_FAILED,
  USER_LOGIN_START,
  USER_LOGOUT_END,
  USER_LOGOUT_FAILED,
  USER_LOGOUT_START,
  USER_READ_START,
  USER_READ_END,
  USER_LOGIN_ERROR
} from '../actions';

let initialState = {
  error: null,
  loading: false,
  user: null,
  token: null
};

// user reducer: manage login & logout state changes
export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_START:
    case USER_LOGOUT_START:
      return {
        ...state,
        user: null,
        loading: false,
        error: null
      };
    case USER_LOGIN_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case USER_LOGIN_FAILED:
    case USER_LOGOUT_FAILED:
      return {
        ...state,
        loading: true,
        user: null,
        error: action.payload
      };
    case USER_LOGIN_END: {
      return {
        user: null,
        error: null,
        loading: false
      };
    }
    case USER_LOGOUT_END:
      return {
        ...state,
        user: null,
        error: null,
        loading: false
      };
    case USER_READ_START:
      return {
        ...state,
        user: null,
        error: null,
        loading: true
      };
    case USER_READ_END:
      const { user } = action.payload;
      return {
        ...state,
        user: user,
        error: null,
        loading: false
      };
    default:
      return { ...state };
  }
};
