import React, { Component } from 'react';
import AdminContentWrapper from './AdminContentWrapper';
import AdminResourceKeyValue from './AdminResourceKeyValue';
import Loading from '../ui/Loading';
import BackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import {IconButton} from '@material-ui/core';

class AdminResourceView extends Component {
  render() {
    const {
      item,
      loading,
      attributes,
      title,
      children,
      onGoBack = null,
      onEdit = null
    } = this.props;
    return (
      <AdminContentWrapper>
        <div style={{ minHeight: 45, display: 'flex' }}>
          {onGoBack != null ? (
            <IconButton
              style={{ float: 'left', height: 56, width: 56, marginTop: 12 }}
              onClick={onGoBack}
            >
              <BackIcon style={{ height: 32, width: 32 }} />
            </IconButton>
          ) : null}
          <h1
            style={{
              float: 'left',
              marginLeft: 16,
              display: 'flex',
              flexGrow: 1
            }}
          >
            {title}
          </h1>
          {onEdit != null ? (
            <IconButton
              style={{ float: 'right', height: 56, width: 56, marginTop: 12 }}
              onClick={onEdit}
            >
              <EditIcon style={{ height: 32, width: 32 }} />
            </IconButton>
          ) : null}
        </div>
        {loading ? (
          <Loading dark={true} />
        ) : (
          [
            <AdminResourceKeyValue
              key={'resource-table'}
              item={item}
              attributes={attributes}
            />,
            children
          ]
        )}
      </AdminContentWrapper>
    );
  }
}

export default AdminResourceView;
