export const app_theme = theme => ({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#ffffff'
    },
    terciary: {
      main: '#ffff00'
    }
  },
  mediaQueries: {
    xs: 320,
    sm: 375,
    md: 425,
    lg: 768
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});
