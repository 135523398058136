import { createMuiTheme } from '@material-ui/core';

export const login_theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#ffffff'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: 16,
        color: '#ffffff'
      }
    },
    MuiInputLabel:{
      color: '#ffffff',
      animated :{
        transition: 'none'
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 300,
        color: '#ffffff !important',
      },

      focused: {
        color: '#ffffff'
      }
    },
    MuiInputBase: {
      input: {
        color: '#fff'
      },
      root: {
        color: '#fff',
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 400,
        borderBottom: '1px solid rgba(255, 255, 255,0.7)'
      },
      disabled: {
        color: 'rgba(255, 255, 255,0.7) !important'
      },
      focused: {
        borderBottom: '1px solid rgba(255, 255, 255,0.7)'
      }
    },
    MuiIconButton: {
      colorPrimary:{
        color: '#fff'
      },
      root: {
        color: '#fff'
      }
    }
  }
});
