import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {FormControl,FormLabel,FormHelperText} from '@material-ui/core';
import { getBase64 } from '../../utils/FileHelper';
import { itemEdit, itemView } from '../../utils/AdminHelper';

const styles = theme => ({
  formControl: {
    margin: 8,
    width: '100%'
  },
  label: {
    color: '#3c3c3c',
    marginTop: 8,
    marginBottom: 8
  },
  error: {
    color: '#9e0000'
  }
});

class ResourceFormInput extends Component {
  generateFileObject = async file => {
    return ({
    file: await getBase64(file),
    file_extension: file.name.split('.').pop(),
    preview: URL.createObjectURL(file)
  });
  };

  handleOnChange = async (name, value) => {
    const { onAttributeChange } = this.props;

    if (
      (name === 'image' || name === 'url') &&
      value &&
      value.acceptedFiles &&
      value.acceptedFiles.length
    ) {
      const updatedValue = await Promise.all(
        value.acceptedFiles.map( f  =>this.generateFileObject(f))
      );
      onAttributeChange(name, {
        ...value,
        acceptedFiles: updatedValue
      });
    } else if (value == null || value === '') {
      onAttributeChange(name, '');
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      onAttributeChange(name, { ...value });
    } else {
      onAttributeChange(name, value);
    }
  };

  render() {
    const {
      template,
      classes,
      customClasses,
      editedValue,
      data,
      error
    } = this.props;
    return (
      <FormControl
        className={
          template && template.editConfig && template.editConfig.className
            ? template.editConfig.className
            : customClasses
            ? customClasses.resourceFormInputContainer
            : classes.formControl
        }
      >
        {template.title && (
          <FormLabel className={classes.label}>{template.title}</FormLabel>
        )}
        {template.editable
          ? itemEdit(template.editConfig, editedValue, this.handleOnChange)
          : itemView(template, data)}
        {error && (
          <FormHelperText className={classes.error} id="description-error-text">
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(styles)(ResourceFormInput);
