import { MENU_DRAWER_TOGGLE } from '../actions';

let initialState = {
  mobileOpen: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MENU_DRAWER_TOGGLE:
      return {
        mobileOpen: !state.mobileOpen
      };
    default:
      return { ...state };
  }
};
