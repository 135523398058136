import React, { Component } from 'react';
import {Avatar,Typography} from '@material-ui/core';
import {getHappyColor} from '../../utils/RandomMaterialColorGenerator';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

class UserAvatar extends Component {
  render() {
    const { user, clear, mini, className, classes, onClick } = this.props;
    const userKey = user ? user.first_name + user.last_name : 'default';

    const style = {
      backgroundColor: clear ? '#ffffff' : getHappyColor(userKey)
    };

    if (user != null) {
      return (
        <Avatar
          style={style}
          className={classNames(
            clear ? classes.clear : classes.dark,
            mini ? classes.mini : classes.big,
            className
          )}
          onClick={onClick}
        >
          <Typography variant={null} className={ (mini)?classes.charsMini : classes.chars}>
            {user.first_name.charAt(0).toUpperCase()}
            {user.last_name.charAt(0).toUpperCase()}
          </Typography>
        </Avatar>
      );
    } else {
      return null;
    }
  }
}

const styles = {
  clear: {
    color: '#ed1818'
  },
  mini: {
    width: 48,
    height: 48,
  },
  big: {
    width: 72,
    height: 72,
    fontSize: 30
  },

  chars: {
    color: '#fff',
    fontSize: 30
  },

  charsMini: {
    color: '#fff',
    fontSize: 24
  },
  dark: {
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    color: '#fff'
  }
};
export default withStyles(styles)(UserAvatar);
