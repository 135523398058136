import {
  SURVEY_END,
  SURVEY_START,
  SURVEY_FAILED,
  SURVEY_NEXT,
  SURVEY_BACK,
  SURVEY_RESET
} from "../actions";

let initialState = {
  step: 0,
  loading: false,
  error: false
};

const MAX_STEPS = 2;

export default (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_RESET:
      return {
        ...state,
        step: 0,
        loading: false,
        error: false
      };
    case SURVEY_BACK:
      return {
        ...state,
        step: (state.step>0)?state.step-1:0,
        loading: false,
        error: false
      };
    case SURVEY_NEXT:
      return {
        ...state,
        step: (state.step<MAX_STEPS)?state.step+1:MAX_STEPS,
        loading: false,
        error: false
      };
    case SURVEY_START:
      return {
          ...state,
          loading: true,
          error: false
      };
    case SURVEY_FAILED:
      return {
        ...state,
        loading: false,
        step: 2,
        error: 'Hubo un problema y no hemos podido enviar tu encuesta'
      };

    case SURVEY_END:
      return {
        ...state,
        loading: false,
        error: false,
        step: 2
      };
    default:
      return {...state};
  }
};
