import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {registerUser, sendSms, reset} from '../../actions/user';
import {loadOccupations} from '../../actions/occupations';
import {addSnackbar} from '../../actions/snackbar';
import {goHome} from '../../actions/navigator';
import Register from '../../components/session/Register';

class RegisterContainer extends Component {
  componentDidMount() {
    this.props.loadOccupations();
  }

  register = (data) => {
    return this.props.registerUser(data);
  };

  sendSms = (email, phone) => {
    return this.props.sendSms(email, phone);
  };

  handleBackTohome = () => {
    this.props.reset();
    this.props.goHome();
  };

  render() {
    const {error, loading, occupations} = this.props;
    return (
      <Register
        onBackToHome={this.handleBackTohome}
        addSnackbar={this.props.addSnackbar}
        onRegister={this.register}
        onSendSMS={this.sendSms}
        error={error}
        loading={loading}
        occupations={occupations}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.registerUserReducer,
    occupations: {...state.occupationsReducer},
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {goHome, registerUser, addSnackbar, sendSms, reset, loadOccupations},
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
