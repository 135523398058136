import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResources, downloadReport } from '../../../actions/admin';
import AdminResourceIndex from '../AdminResourceIndex';

import {
  FiltersFormat,
  EditInputType,
  EditInputObjectModel,
  CourseProgress, CourseStatus
} from '../../../config/constants';

const KEY_NAME = 'user_courses';

class AdminReportsContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size);
  };

  getColumns = () => {
    return [
      { title: 'Nombre', source: 'user.first_name' },
      { title: 'Apellido', source: 'user.last_name' },
      { title: 'Documento', source: 'user.document' },
      { title: 'Email', source: 'user.email' },
      { title: 'Teléfono', source: 'user.phone' },
      { title: 'Empresa', source: 'user.company' },
      { title: 'ID curso', source: 'course.id' },
      { title: 'Nombre de curso', source: 'course.title' },
      { title: 'Estado del curso', source: 'completed' },
      { title: 'Fecha de finalización', source: 'completed_at' }
    ];
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Users,
        name: 'user.id',
        placeholder: 'Usuario',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: (a) => `${a.last_name}, ${a.first_name}`
      },
      {
        type: EditInputType.Object,
        model: EditInputObjectModel.Courses,
        name: 'course.id',
        placeholder: 'Curso',
        multiple: true,
        format: FiltersFormat.In,
        displayAttribute: 'title'
      },

      {
        type: EditInputType.Select,
        name: 'userCourse.completed',
        placeholder: 'Estado',
        format: FiltersFormat.Plain,
        options: Object.keys(CourseProgress).map(k => ({
          id: k=='true'? '1':'0',
          description: CourseProgress[k]
        }))
      }
    ];
  };

  onDownload = () => {
    const {
      downloadReport,
      paginator: { query }
    } = this.props;
    downloadReport(query);
  };

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTES'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onDownload={this.onDownload}
      />
    );
  }
}

AdminReportsContainer.defaultProps = {
  type: KEY_NAME
};

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer;
  if (
    paginatorState &&
    paginatorState.user_courses &&
    paginatorState.user_courses.items
  ) {
    paginatorState.user_courses.items.map(course =>
      course.completed_at && course.completed_at.length
        ? (course.completed = CourseProgress.true)
        : (course.completed = CourseProgress.false)
    );
  }
  return {
    paginator: { ...paginatorState[KEY_NAME] }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResources, downloadReport }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportsContainer);
