import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextInput from '../../components/admin/inputs/TextInput';

const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);

  return ({
    header: {
      position: 'relative',
      backgroundSize: 'cover',
      height: '10%',
      minHeight: 350,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1
    },
    headerContent: {
      top: '4em',
      position: 'relative',
      textAlign: 'center',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontFamily: 'PlaylistScript',
      fontSize: 48,
      fontWeight: 100,
      color: '#ffffff',
      margin: 'auto -8px',
      [mediaLg]: {
        fontSize: 30,
      }
    },
    input: {
      backgroundColor: '#fff',
      borderRadius: '5px',
      padding: '1em 2em',
      margin: '2em 0',
      minWidth: 'unset'
    },
    inputContainerClass: {
      width: '60%',
      minWidth: 300
    }
  })
};

class SearchHeader extends Component {
  state = {
    filter: ''
  };

  onChange = (name, value) => {
    const { onQueryChange } = this.props;
    onQueryChange(value);
  };

  render() {
    const { classes, query } = this.props;

    return (
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>
              Qué vas a aprender hoy?
          </Typography>
          </div>
          <TextInput
            name="text"
            type="search"
            value={query}
            placeholder="Buscar"
            onChange={this.onChange}
            className={classes.input}
            containerClass={classes.inputContainerClass}
            autoFocus={true}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SearchHeader);
