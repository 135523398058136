import React, { Component } from 'react';
import GenericDialog from '../../components/ui/GenericDialog';
import SvgIcon from '../../components/ui/SvgIcon';
import { Button, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Rating from 'react-rating';
import TextField from '@material-ui/core/es/TextField/TextField';
import validator from "validator";

const styles = () => ({
  content: {
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    margin: 'auto',
    maxWidth: '80%'
  },
  stars: {
    marginTop: 8,
    textAlign: 'center'
  },
  text: {
    textAlign: 'center',
    color: '#575757'
  },
  actions: {
    margin: 'auto',
    marginTop:8
  },
  actionSave: {
    backgroundColor: '#575454',
    marginRight: 16,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgba(87,84,84,0.91)'
    }
  },
  actionCancel: {
    color: '#575454',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  buttonLabel: {
    textTransform: 'none'
  },
  inputRoot:{
    fontSize: 14,

  },
  textField:{
    marginBottom:0
  },
  error:{
    fontSize: 10,
    color: 'red'
  }
});

const initialState = {
  isValid:true,
  initialRating:0,
  stars: false,
  comment: '',
  errors:{comment: ''}
};

class CreateReviewDialog extends Component {
  state = { ...initialState };

  static getDerivedStateFromProps(nextProps, prevState){
    return {initialRating: nextProps.initialRating };
  }

  resetDialog = () => {
    this.setState({ ...initialState});
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => { this.validateForm()});
  };

  validateForm = () => {
    let error = false;
    let errors = {
      comment: ''
    };

    if (!validator.isLength(this.state.comment.trim(), { min: 0, max: 250 })) {
      errors.comment = 'Puedes completar hasta 250 caracteres. Completaste '+this.state.comment.trim().length;
      error = true;
    }

    this.setState({ errors: errors, isValid: !error });
    return !error;
  };

  renderContent = () => {
    const { classes, onDismiss, onSubmit } = this.props;
    return (
      <div className={classes.content}>
        <Typography className={classes.text}>
          {'Indica cuantas estrellas le pondrías a este curso.'}
        </Typography>
        <Rating
          className={classes.stars}
          emptySymbol={
            <SvgIcon
              iconName={'ic_star_outlined_gray'}
              style={{ height: 24, width: 24 }}
            />
          }
          fullSymbol={
            <SvgIcon
              iconName={'ic_star_filled_gray'}
              style={{ height: 24, width: 24 }}
            />
          }
          stop={5}
          readonly={false}
          initialRating={this.state.stars || this.state.initialRating}
          onChange={s => {
            this.setState({ stars: s });
          }}
        />
        <TextField
          id="comment"
          name="comment"
          placeholder={'Si querés podés dejar un comentario...'}
          value={this.state.comment}
          margin="normal"
          variant="outlined"
          type="text"
          multiline={true}
          rowsMax={6}
          onChange={this.handleChange}
          className={classes.textField}
          InputProps={{
            classes:{root:classes.inputRoot}
          }}
        />
        <Typography className={classes.error}>{this.state.errors.comment}</Typography>
        <div className={classes.actions}>
          <Button
            disabled={!this.state.isValid}
            variant={'outlined'}
            className={classes.actionSave}
            classes={{ label: classes.buttonLabel }}
            onClick={() =>
              onSubmit({
                rating: this.state.stars || this.state.initialRating,
                comment: this.state.comment.trim()
              })
            }
          >
            {'Guardar'}
          </Button>
          <Button
            variant={'outlined'}
            className={classes.actionCancel}
            classes={{ label: classes.buttonLabel }}
            onClick={onDismiss}
          >
            {'Cancelar'}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { open, onDismiss ,headerColor} = this.props;
    return (
      <GenericDialog
        headerColor={headerColor}
        open={open}
        onDismiss={onDismiss}
        name={'VALORAR LA ACTIVIDAD'}
        icon={
          <SvgIcon iconName={'ic_star_outlined'} style={{ fontSize: 100 }} />
        }
        title={'¿Cuántas estrellas le pones a este curso?'}
        onExit={this.resetDialog}
      >
        {this.renderContent()}
      </GenericDialog>
    );
  }
}

export default withStyles(styles)(CreateReviewDialog);
