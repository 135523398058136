import React  from "react";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import ViewSlider from 'react-view-slider'
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles/index";
import {Button} from "@material-ui/core";
import config from '../../config/index';
import Loading from "../ui/Loading";
import PreLoginLinks from "./PreLoginLinks";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#e2001a',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },

  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: 16,
        color: '#ffffff'
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 400,
        color: '#ffffff',
      },

      focused: {
        color: '#ffffff',
      }
    },
    MuiInputBase: {
      input: {
        color: '#fff',
      },
      root: {
        color: '#fff',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 400,
        borderBottom: '1px solid rgba(255, 255, 255,0.7)',
      },
      disabled: {
        color: '#fff',
      },
      focused: {
        borderBottom: '1px solid rgba(255, 255, 255,0.7)',
      }
    },
    MuiIconButton: {
      root: {
        color: '#fff'
      }
    },
    MuiMobileStepper: {
      root: {
        flexDirection: 'column',
        backgroundColor: '#00000000',
        paddingTop: 50
      },
      dotActive: {
        backgroundColor: '#fff',
        opacity: 1
      },
      dot:{
        backgroundColor: '#fff',
        opacity: 0.44
      }
    },
    MuiButton:{
      disabled:{
        color: '#ffffff55 !important'
      }
    }
  }
});

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);

  return ({

    root: {
      overflowY:'auto',
      position: 'relative',
      height: '100vh',
      backgroundSize: 'cover',
      background: `no-repeat center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    logoAc: {
        width: '70%',
        margin: 'auto',
        maxWidth:357
    },
    content: {
      width: '100%',
      height: '100%',
      margin: 'auto',
      maxWidth: 700,
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    lateralImage: {
      left: 0,
      position: 'absolute',
      minHeight: '100%',
    },

    title: {
      textAlign: 'center',
      paddingTop: 24,
      paddingBottom: 24,
      color: '#fff',
      fontFamily: 'Open Sans',
      fontSize: 34,
      fontWeight: 400
    },
    subtitle: {
      wordBreak: 'break-word',
      width: '80%',
      textAlign: 'center',
      margin: 'auto',
      paddingBottom: 24,
      color: '#fff',
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 400,
      whiteSpace: 'normal'
    },

    buttons: {
      margin: 'auto',
      width: 300
    },
    buttonRow: {
      height: 50,
      width: '100%',
      float: 'left',
      display: 'flex',
      justifyContent: 'center'
    },
    button: {
      color: '#ffffff',
      fontSize: 16,
      minWidth: 180,
      marginRight: theme.spacing.unit,
      marginTop: theme.spacing.unit,
    },
    backButton: {
      marginRight: theme.spacing.unit,
    },
    completed: {
      display: 'inline-block',
    },
    stepContent: {
      minHeight: '100%',
      margin: '32px 20%',
      textAlign: 'center'
    },
    instructions: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    formItem: {
      width: '100%',
    },
    center: {
      textAlign: 'center'
    },

    loader: {
      textAlign: 'center',
      minHeight: 250
    },

    errors: {
      marginTop: 16,
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: '400',
      color: '#f90000'
    },

    slide: {
      width: '100%',
      height: '100%',
      maxWidth: 700
    },
    exitButton: {
      position: 'absolute',
      right: 60,
      top: 30
    },
    stepperContent: {
      marginTop: -50
    },
  });
};


class ResetPasswordStepper extends React.Component {

  initialState =  {
    email: '',
    password: '',
    password2: '',
    code: '',
    showPassword: false,
    showPassword2: false,
    canContinue: false,
    validForm: false
  };

  state = this.initialState;


  getStepContent = (activeStep)  => {
    const {classes, loading, error} = this.props;
    if(loading) {
     return (<div className={classes.loader}>
        <Loading height={40}/>
      </div>)
    }
    switch (activeStep) {
      case 0:
        return (<div className={classes.slide}>
          <Typography className={classes.title}>Obtené tu clave</Typography>
          <Typography className={classes.subtitle}>{"Ingresá tu mail a continuación para comenzar, te enviaremos por mail un código que deberás usar en el siguiente paso."}</Typography>
          {
            (loading)? null : (error) ?  <Typography className={classes.errors}>{error}</Typography> : null
          }
          <FormControl className={classes.formItem}>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input name="email" disableUnderline={true} value={this.state.email} onChange={this.handleEmailChange}  />
          </FormControl>
        </div>);
      case 1:
        return (<div className={classes.slide} >
          <form>
          <Typography className={classes.title}>Creá tu clave</Typography>
          <Typography className={classes.subtitle}>{"Te enviamos un código por mail, ingresalo a continuación y podrás configurar tu nueva clave."}</Typography>
          {
            (loading)? null : (error && typeof error === "string") ?  <Typography className={classes.errors}>{error}</Typography> : null
          }

          <FormControl className={classes.formItem}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input name="email" disableUnderline={true} value={this.state.email} disabled={true} />
          </FormControl>
          <FormControl className={classes.formItem}>
            <InputLabel htmlFor="code">Código</InputLabel>
            <Input name="code" disableUnderline={true} value={this.state.code} onChange={this.handleCodeChange}  />
          </FormControl>
          <FormControl className={classes.formItem}>
            <InputLabel htmlFor="password">Contraseña</InputLabel>
            <Input name="password" disableUnderline={true} value={this.state.password} type={this.state.showPassword ? 'text' : 'password'} onChange={this.handlePasswordChange}
                   endAdornment={
                     <InputAdornment position="end">
                       <IconButton
                         aria-label="Cambiar visibilidad de la contraseña"
                         onClick={this.handleClickShowPassword}
                         onMouseDown={this.handleMouseDownPassword}
                       >
                         {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                       </IconButton>
                     </InputAdornment>
                   }
                   autoComplete={"off"}
            />
          </FormControl>
          <FormControl className={classes.formItem}>
            <InputLabel htmlFor="password2">Confirmar contraseña</InputLabel>
            <Input name="password2" disableUnderline={true} value={this.state.password2} type={this.state.showPassword2 ? 'text' : 'password'} onChange={this.handlePassword2Change}
                   endAdornment={
                     <InputAdornment position="end">
                       <IconButton
                         aria-label="Cambiar visibilidad de la contraseña"
                         onClick={this.handleClickShowPassword2}
                         onMouseDown={this.handleMouseDownPassword}
                       >
                         {this.state.showPassword2 ? <VisibilityOff /> : <Visibility />}
                       </IconButton>
                     </InputAdornment>
                   }
                   autoComplete={"off"}
            />
          </FormControl>
          </form>
        </div>);
      case 2:
        return (error)?(<div className={classes.slide}>
          <Typography className={classes.title}>No hemos podido generar tu clave</Typography>
          <Typography className={classes.subtitle}>{"Hubo un problema y no hemos podido generar tu clave, hacé clic en \"Reintentar\" para volver a comenzar."}</Typography>
          </div>):(<div className={classes.slide}>
          <Typography className={classes.title}>Tu clave ha sido generada con éxito!</Typography>
          <Typography className={classes.subtitle}>{"Hacé clic en \"Finalizar\" para iniciar sesión en Academia Salud."}</Typography>
        </div>);
      default:
        return 'Unknown step';
    }
  };

  hanldeSkip = () => {
    if(!this.props.loading) {
      this.props.onSkip();
    }
  };

  handleReset = () => {
    if(!this.props.loading) {
      this.setState(this.initialState,()=>{
        this.props.onReset();
      });
    }
  };

  handleSendCode = () => {
    if(!this.props.loading) {
      this.props.onSendCode(this.state.email);
    }
  };

  handleResetPassword = () => {
    if(!this.props.loading) {
      const {email, code, password} = this.state;
      this.props.onChangePassword(email, code, password);
    }
  };


  handleBack = () => {
    if(!this.props.loading) {
      if (this.props.activeStep > 0) {
        this.props.onBack();
      } else {
        this.props.onBackToHome();
      }
    }
  };

  handleExit = () => {
    if(!this.props.loading) {
      this.props.onBackToHome();
    }
  };


  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleCodeChange = (event) => {
    this.setState({code: event.target.value},()=>{this.validateForm();});
  };
  handleEmailChange = (event) => {
    const canContinue = validator.isEmail(event.target.value);
    this.setState({email: event.target.value, canContinue: canContinue});
  };

  handlePasswordChange = (event) => {
    this.setState({password: event.target.value},()=>{this.validateForm();});
  };

  handlePassword2Change = (event) => {
    this.setState({password2: event.target.value},()=>{this.validateForm();});

  };

  validateForm = () => {
    const {email, code, password, password2} = this.state;
    const isValid = (validator.isEmail(email) && !validator.isEmpty(code) && !validator.isEmpty(password) && password===password2);
    this.setState({validForm: isValid});
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleClickShowPassword2 = () => {
    this.setState(state => ({ showPassword2: !state.showPassword2 }));
  };


  renderView = ({index, key, ref, style, className, active, transitionState}) => {
    const {classes, error} = this.props;
    let activeStep = index;
    return (
      <div key={key} ref={ref} style={style} className={className}>
        <IconButton className={classes.exitButton }  color={'primary'}  onClick={() => this.handleExit() } label={'SALIR'}>
          <ClearIcon />
        </IconButton>
        <div>
          <div className={classes.stepContent}>
            {this.getStepContent(activeStep)}
          </div>
          { activeStep !== 2 &&
          (<div className={classes.buttons}>
            <div className={classes.buttonRow}>
              <Button className={classes.button }  disabled={activeStep === 0 ? !this.state.canContinue: !this.state.validForm} onClick={() =>  activeStep === 0 ? this.handleSendCode(): this.handleResetPassword() } >{'CONTINUAR'}</Button>
            </div>
            { false && activeStep === 0 && (
              <div className={classes.buttonRow}>
                <Button className={classes.button }  disabled={!this.state.canContinue} onClick={() => this.hanldeSkip() } >{'Ya tengo un código'}</Button>
              </div>
            )}
          </div>)}
          {activeStep === 2 && (<div className={classes.buttons}>
              <div className={classes.buttonRow}>
                {(error)?
                    <Button className={classes.button }  onClick={() => this.handleReset() } >{'REINTENTAR'}</Button>:
                    <Button className={classes.button }  onClick={() => this.handleExit() } >{'FINALIZAR'}</Button>
                }
              </div></div>)}
        </div>
      </div>)
  }

  render() {
    const { classes, activeStep } = this.props;
    return (

      <div className={classes.root}>

        <div className={classes.content}>
          <img
              src={config.BASE_URL + 'img/ic_banner_academia_2.png'}
              alt="logo banner"
              className={classes.logoAc}
          />
          <MuiThemeProvider theme={theme}>
          <MobileStepper activeStep={activeStep} steps={3} variant={'dots'} position={'static'} backButton={null} nextButton={null}/>
        <div style={{  height: '100%' }}>

          <ViewSlider renderView={this.renderView}
                    numViews={3}
                    activeView={activeStep}
                    animateHeight={false}
                      style={{position:'relative'}}
                      className={classes.stepperContent}
                    />

      </div>
          </MuiThemeProvider>
          <PreLoginLinks />

        </div>
      </div>

    );
  }
}


export default withStyles(styles)(ResetPasswordStepper);
