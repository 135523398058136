import { SNACKBAR_CLOSE, SNACKBAR_OPEN } from '../actions';
import { SnackbarType } from '../config/constants';

let initialState = {
  open: false,
  message: null,
  type: SnackbarType.Generic
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN: {
      const { type, message } = action.payload;
      return { ...state, open: true, message: message, type: type };
    }
    case SNACKBAR_CLOSE:
      return { ...state, open: false };
    default:
      return { ...state };
  }
};
