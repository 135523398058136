import React, { Component } from 'react';
import HorizontalZoomSlider from '../ui/HorizontalZoomSlider';
import CourseItem from '../ui/CourseItem';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import UserAvatar from '../ui/UserAvatar';
import ParallaxBackground from '../ui/ParallaxBackground';

const styles = theme => {
  const mediaLg = theme.breakpoints.up(theme.mediaQueries.lg);

  return {
    container: {
      flex: 1,
      marginTop: 64,
      minHeight: '100vh',
      height: '100%',
      backgroundColor: 'rgba(232,232,232,0.87)',
      maxWidth: 'unset',
      width: '100%',
      padding: 30,
      boxSizing: 'border-box',
      position: 'relative',
      zIndex: 1,
      margin: 'auto',
      [mediaLg]: {
        maxWidth: '72%',
        padding: 60,
        marginTop: 80
      }
    },
    customStyleTitle: {
      color: '#5c5c5c',
      fontSize: 16,
      fontWeight: '700'
    },
    topDataContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingBottom: 10,
      borderBottom: '1px solid #a3a3a3'
    },
    nameEmailContainer: {
      marginTop: 20
    },
    profileImageContainer: {
      height: 80,
      width: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },
    restProfileDataContainer: {
      marginTop: 20
    },
    root: {
      backgroundColor: '#000',
      display: 'block',
      position: 'relative',
      zIndex: 0,
      minHeight: '100%',
      width: '100%'
    },
    slidersContainer: {
      marginTop: '2em'
    },
    text: {
      color: '#0000008a',
      '&.bold': {
        fontWeight: 500
      }
    },
    title: {
      fontSize: 40,
      fontWeight: 100,
      color: '#5c5c5c',
      marginLeft: 16,
      fontFamily: 'PlaylistScript'
    }
  };
};

class ProfileContent extends Component {
  onCreateItemCourse = item => {
    return <CourseItem item={item} />;
  };

  onItemClick = item => {
    const { onShowCourse } = this.props;
    onShowCourse(item);
  };

  render() {
    const { classes, user, courses } = this.props;
    if (user && user.full_name && !user.last_name) {
      const names = user.full_name.split(',');
      if (names.length === 2) {
        user.first_name = names[1].trim();
        user.last_name = names[0].trim();
      }
    }

    return (
      <div className={classes.root}>
        <ParallaxBackground />
        <div className={classes.container}>
          {user && (
            <div>
              <div>
                <div className={classes.topDataContainer}>
                  <div className={classes.profileImageContainer}>
                    {user && (
                      <UserAvatar mini={false} clear={false} user={user} />
                    )}
                  </div>
                  <Typography className={classes.title}>Mi Perfil</Typography>
                </div>
                <div className={classes.nameEmailContainer}>
                  <Typography className={`${classes.text} bold`}>
                    {`${user.first_name} ${user.last_name}`}
                  </Typography>
                  <Typography className={`${classes.text} bold`}>
                    {user.email}
                  </Typography>
                  <Typography className={`${classes.text} bold`}>
                    {user.phone}
                  </Typography>
                </div>
              </div>
              <div className={classes.slidersContainer}>
                {courses.inProgress.items.length > 0 && (
                  <HorizontalZoomSlider
                    customStyleTitle={classes.customStyleTitle}
                    title="Continuar viendo"
                    itemCreator={item => this.onCreateItemCourse(item)}
                    itemClick={this.onItemClick}
                    items={courses.inProgress.items}
                  />
                )}
                {courses.finished.items.length > 0 && (
                  <HorizontalZoomSlider
                    customStyleTitle={classes.customStyleTitle}
                    title="Cursos Finalizados"
                    itemCreator={item => this.onCreateItemCourse(item)}
                    itemClick={this.onItemClick}
                    items={courses.finished.items}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(ProfileContent);
