import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import config from '../../config/index';

const styles = theme => {

  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  const mediaSm = theme.breakpoints.down(600);
  return {
    footer: {
      backgroundColor: '#000000',
      minHeight: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      width: '100%',
      padding: '0 90px',
      [mediaLg]: {
        padding: '0 16px',
      },
    },
    divLine: {
      marginTop: 16,
      marginBottom: 16,
      height: 1,
      backgroundColor: '#333333',
      width: '100%',
    },
    grid: {
      width: '100%',
    },
    image: {
      marginTop: 8,
    },
    clickableImage: {
      float:'left',
      minWidth: '100%',
      marginTop: 16,
      marginBottom: 16,
      cursor: 'pointer',
    },
    text: {
      fontSize: 12,
      color: 'white',
    },
    toRight:{
      textAlign:'right',
      [mediaSm]:{
        textAlign:'center',
      }
    },
    toLeft:{
      textAlign:'left',
      [mediaSm]:{
        textAlign:'center',
      }
    }

  };
};

class Footer extends Component {
  render() {
    const {classes } = this.props;
    return (
      <div className={classes.footer}>
        <div className={classes.content}>
          <Grid container className={classes.grid} spacing={8}>
            <Grid sm={4} xs={12} item>
              <a className={classes.clickableImage +' '+ classes.toLeft}  href={'https://www.santander.com.ar/banco/online/personas'} target={'_bank'}>
                <img
                    width={120}
                    alt={'Santander'}
                    src={config.BASE_URL + 'img/by_santander.png'}
                />
              </a>
            </Grid>
            <Grid sm={4} xs={12} item>
                <a className={classes.clickableImage} style={{textAlign:'center'}} href={'https://www.swissmedical.com.ar/prepagaclientes/'} target={'_bank'}>
                  <img
                      width={120}
                      alt={'SMG'}
                      src={config.BASE_URL + 'img/by_smg.png'}
                  />
                </a>
            </Grid>
            <Grid sm={4} xs={12} item>
              <a className={classes.clickableImage +' '+ classes.toRight}  href={'http://magnetico.com.ar/'} target={'_bank'}>
                <img
                    width={120}
                    alt={'Magnético'}
                    src={config.BASE_URL + 'img/by_mag.png'}
                />
              </a>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Footer);
