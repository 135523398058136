import React from 'react';
import {Link, Fab} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";

import DownloadIcon from '@material-ui/icons/CloudDownload';
import ColorButton from "../../../ui/ColorButton";
import api from "../../../../api/api";

const styles = {
    button: {
        fontWeight:700,
        padding:4
    },
    icon:{
        marginRight:4,
        fontSize: 16
    }
};

const ActivityModuleFileContent = ({ content,classes, color }) => (


        <Link  underline={'none'} href={api.signUrl(content.url)} target={'_blank'}  >
            <ColorButton label={'DESCARGAR'} color={color} onClick={()=>{}} extraView={<DownloadIcon className={classes.icon} />}/>
          </Link>

);

export default withStyles(styles)(ActivityModuleFileContent);
