import React, { Component } from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { inverted_progressbar_theme } from "../../themes/inverted_progressbar_theme";
import Rating from 'react-rating';
import SvgIcon from './SvgIcon';
import TagChip from './TagChip';
import api from '../../api/api';

const styles = theme => ({
  root: {
    height: '100%',
    backgroundColor: '#000000',
    display: 'block',
    position: 'relative',
    '&_content': {
      zIndex: 10,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      float: 'left',
      '&_bigContent': {
        opacity: 0
      },
      '&_miniContent': {
        opacity: 1,
        transition: 'all 0.45s ease-out',
        transitionDelay: '300ms',
        backgroundImage: 'linear-gradient(rgba(84, 84, 84, 0),#000000)'
      }
    },
    '&:hover > &_content_bigContent': {
      opacity: 1,
      transition: 'all 0.45s ease-out',
      transitionDelay: '300ms',
      backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0),#000000)'
    },
    '&:hover > &_content_miniContent': {
      opacity: 0
    },
    '&--overlay': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: '#000000',
      opacity: 0.25,
      zIndex: 5,
      transition: 'all 0.45s ease-out'
    },
    '&:hover &--overlay': {
      opacity: 0
    }
  },
  contentPadding: {
    padding: 8
  },
  miniTitle: {
    color: '#ffffff',
    fontSize: 16,
    paddingBottom: 4
  },
  bigTitle: {
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 4
  },
  bigSubtitle: {
    color: '#ffffff',
    fontSize: 12
  },
  image: {
    height: '100%'
  },
  progress: {
    margin: 'auto'
  },
  rating: {},
  star: {
    fontSize: 14
  },
  mandatory: {
    position: 'absolute',
    top: 8,
    right: 8,
    fontWeight: 700,
    color: '#fff',
    background: '#000000',
    fontSize: 8,
    borderRadius: 2,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 8,
    paddingLeft: 8,
    backgroundImage: 'none !important'
  },
  info: {
    width: '100%',
    float: 'left'
  },
  categories: {
    width: '100%',
    float: 'left'
  },
  category: {
    color: '#fff',
    fontSize: 10,
    float: 'left',
    marginRight: 4,
    marginBottom: 4
  },
  tags: {
    marginTop: 2
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  titleProgressContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  progressNumber: {
    display: 'block',
    color: '#fff'
  }
});

class CourseItem extends Component {
  trimEllip = function (text, length) {
    return text.length > length ? text.substring(0, length) + '...' : this;
  };

  render() {
    const { classes, item } = this.props;
    let userInfo = { total_activities_done: 0, progress: 0 };
    if (item && item.user_courses && item.user_courses.length === 1) {
      userInfo = item.user_courses[0];
      userInfo.progress =
        (userInfo.total_activities_done / (item.total_activities || 1)) * 100;
    }
    return (
      <div className={classes.root}>
        <div className={classes.root + '--overlay'} />
        <div
          className={classes.imageWrapper}
          style={{ backgroundImage: `url('${api.signUrl(item.image)}')` }}
        />
        <div
          className={
            classes.root + '_content ' + classes.root + '_content_bigContent'
          }
        >
          <div className={classes.contentPadding}>
            <div className={classes.info}>
              {item.tags &&
                item.tags
                  .slice(0, 3)
                  .map(tag => (
                    <TagChip
                      key={tag.id}
                      label={tag.name}
                      className={classes.tags}
                    />
                  ))}
            </div>
            {item.comments_enabled && (
              <Rating
                className={classes.rating}
                emptySymbol={
                  <SvgIcon
                    iconName={'ic_star_outline'}
                    className={classes.star}
                  />
                }
                fullSymbol={
                  <SvgIcon
                    iconName={'ic_star_filled'}
                    className={classes.star}
                  />
                }
                stop={5}
                readonly={true}
                initialRating={parseFloat(item.rating_average)}
              />
            )}
            <div className={classes.titleProgressContainer}>
              <Typography className={classes.bigTitle}>{item.title}</Typography>
              <Typography className={classes.progressNumber}>{`${userInfo.progress.toFixed(0)}%`}</Typography>
            </div>
            {
              <MuiThemeProvider theme={inverted_progressbar_theme}>
                <LinearProgress
                  className={classes.progress}
                  variant="determinate"
                  value={userInfo.progress}
                />
              </MuiThemeProvider>
            }
          </div>
        </div>
        {item.required && (
          <Typography
            className={
              classes.mandatory + ' ' + classes.root + '_content_bigContent'
            }
          >
            Obligatorio
          </Typography>
        )}
        <div
          className={
            classes.root + '_content ' + classes.root + '_content_miniContent'
          }
        >
          <div className={classes.contentPadding}>
            <Typography className={classes.miniTitle}>{item.title}</Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CourseItem);
