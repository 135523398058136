import React, { Component } from 'react';
import AdminMain from '../../components/admin/AdminMain';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import { admin_app_theme } from '../../themes/admin_app_theme';
import AdminNavBarContainer from './ui/AdminNavBarContainer';
import MenuDrawerContainer from './menu/MenuDrawer';
import SnackbarMessageContainer from "../ui/SnackbarMessage";

const theme = createMuiTheme(admin_app_theme());

const styles = theme => ({
  root: {
    backgroundColor: '#ffffff',
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    minHeight: '100%',
    maxWidth: 'calc(100vw - 0%)'
  }
});

class Layout extends Component {
  render() {
    const { classes, me } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <AdminNavBarContainer />
          <MenuDrawerContainer />
          <AdminMain me={me}/>
        </div>
        <SnackbarMessageContainer />
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Layout);
