import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { localStorageMiddleware } from '../middleware';
import rootReducer from '../reducers/index';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import reduxReset from 'redux-reset';

export const history = createBrowserHistory();

history.listen(_ => {
  window.scrollTo(0, 0);
});

const initialState = {};
const enhancers = [];
const middleware = [thunk, localStorageMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  reduxReset(),
  ...enhancers
);

export const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
);
