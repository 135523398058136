export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function() {
      const encoded = reader.result.replace(/^data:(.*,)?/, '');
      resolve(encoded);
    };
    reader.onerror = function(error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const getFileAsDataUrl = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function() {
      const encoded = reader.result;
      resolve(encoded);
    };
    reader.onerror = function(error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const getUrlAsDataUrl = url => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = function() {
      var reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = function(e) {
        resolve(e.target.result);
      };
      reader.onerror = function(error) {
        reject(error);
      };
    };
    request.send();
  });
};
