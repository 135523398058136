import React from 'react';
import SelectInput from './SelectInput';

export default class BooleanInput extends React.Component {
  render() {
    const { name, value, placeholder, onChange } = this.props;
    return (
      <SelectInput
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        options={[
          { id: '0', description: 'No' },
          { id: '1', description: 'Si' }
        ]}
      />
    );
  }
}
