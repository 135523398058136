import React, { Component } from 'react';
import AdminResourceIndex from '../../components/admin/AdminResourceIndex';
import { makeQuery } from '../../actions/admin';
import { changeQuery } from '../../actions/paginator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class AdminResourceIndexContainer extends Component {
  state = {
    filters: {}
  };

  componentDidMount() {
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = props => {
    const {
      fetch,
      filter,
      onFetch,
      keyName,
      page,
      size,
      getFilterConfig,
      getOrderingConfig = () => []
    } = props;
    if (fetch && filter) {
      onFetch(
        keyName,
        makeQuery(filter, getFilterConfig(), getOrderingConfig()),
        page,
        size
      );
    }
  };

  applyFilter = filters => {
    const {
      keyName,
      changeQuery,
      getFilterConfig,
      getOrderingConfig = () => []
    } = this.props;
    this.setState({ filters: filters }, () =>
      changeQuery(
        keyName,
        makeQuery(filters, getFilterConfig(), getOrderingConfig())
      )
    );
  };

  render() {
    const {
      children,
      keyName,
      getActions,
      columns,
      title,
      customTop,
      customBottom,
      onFetch,
      paginator,
      customItems,
      getFilterConfig = () => false,
      onCreate = null,
      sort,
      forceNoActions = false,
      onDownload
    } = this.props;
    return (
      <AdminResourceIndex
        keyName={keyName}
        onFilterChange={this.applyFilter}
        onFetch={onFetch}
        items={customItems || paginator.items}
        loading={paginator.loading}
        getActions={getActions}
        columns={columns}
        title={title}
        customTop={customTop}
        customBottom={customBottom}
        onFiltersChange={this.applyFilter}
        filters={this.state.filters}
        getFilterConfig={getFilterConfig}
        onCreate={onCreate}
        sort={sort}
        forceNoActions={forceNoActions}
        onDownload={onDownload}
      >
        {[children]}
      </AdminResourceIndex>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const states = state.paginatorReducer;
  return {
    paginator: { ...states[ownProps.keyName] }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeQuery }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminResourceIndexContainer);
