import { push } from 'connected-react-router';
import {
  HOME,
  RESET_PASSWORD,
  ADMIN_HOME,
  COURSES_EXPLORE,
  COURSES_EXPLORE_CATEGORY,
  SEARCH,
  PROFILE_ME
} from '../config/routes';
import { router } from '../config/routes';

export function showResetPassword() {
  return async dispatch => {
    try {
      dispatch(push(RESET_PASSWORD));
    } catch (e) {}
  };
}

export function goHome() {
  return async dispatch => {
    try {
      dispatch(push(HOME));
    } catch (e) {}
  };
}

export function goAdmin() {
  return async dispatch => {
    try {
      dispatch(push(ADMIN_HOME));
    } catch (e) {}
  };
}

export function goTo(route) {
  return async dispatch => {
    try {
      dispatch(push(route));
    } catch (e) {}
  };
}

export function goToExploreCategories() {
  return async dispatch => {
    try {
      dispatch(push(COURSES_EXPLORE));
    } catch (e) {}
  };
}

export function goToSearch() {
  return async dispatch => {
    try {
      dispatch(push(SEARCH));
    } catch (e) {
      console.log({ error: e });
    }
  };
}

export function goToUserProfile() {
  return async dispatch => {
    try {
      dispatch(push(PROFILE_ME));
    } catch (e) {
      console.log({ error: e });
    }
  };
}

export function goToExploreCoursesByCategory(categoryId) {
  return async dispatch => {
    try {
      dispatch(push(router.getRoute(COURSES_EXPLORE_CATEGORY, { categoryId })));
    } catch (e) {}
  };
}
