

export const parseQuery = (query) => {
    const params = {};
    if (query.startsWith('?')) {
        query = query.substring(1, query.length);
    }
    query = query.split('&');
    query.forEach(q => {
        const kv = q.split('=');
        params[kv[0]] = kv[1];
    });
    return params;
};
