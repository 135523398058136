import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from "../../api/api";

const styles = (theme, palette2) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  const mediaSm = theme.breakpoints.down(768);
  return {
    header: {
      backgroundColor: '#434343',
      minHeight: 320,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 1,
    },
    headerContent: {
      paddingTop: 80,
      position: 'relative',
      textAlign: 'center',
      margin: 'auto',
      maxWidth: '50%',
    },
    title: {
      fontSize: 80,
      fontWeight: 100,
      color: '#ffffff',
      textShadow: '0 1.5px 3px rgba(0, 0, 0, 0.16)',
      fontFamily: 'PlaylistScript',
      lineHeight: 1.1,
      marginBottom: 20,
      [mediaSm]:{
        fontSize: 40,
      }
    },
    subtitle: {
      fontSize: 18,
      color: '#ffffff',
      [mediaSm]:{
        fontSize: 15,
      }
    },
    icon: {
      marginTop: 40,
      position: 'absolute',
      right: 0,
      width: 180,
      transform: 'rotate(16deg)',
      [mediaLg]: {
        display: 'none'
      }
    }
  }
};

class CoursesExploreHeader extends Component {
  render() {
    const { classes, category } = this.props;
    return (
      <div
        className={classes.header}
        style={{ background: category.color }}
      >
        <div className={classes.headerContent}>
          <Typography className={classes.title}>{category.name}</Typography>
          <Typography className={classes.subtitle}>
            {category.description}
          </Typography>
        </div>
        <img src={api.signUrl(category.icon)} className={classes.icon} alt='' />
      </div>
    );
  }
}
export default withStyles(styles)(CoursesExploreHeader);
