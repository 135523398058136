import React, { Component } from 'react';
import Paginator from '../ui/Paginator';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class ResourcesPaginator extends Component {
  render() {
    const { keyName, onFetch } = this.props;
    return <Paginator keyName={keyName} onFetch={onFetch} />;
  }
}

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesPaginator);
