import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goTo } from '../../actions/navigator';
import Profile from '../../components/profile/Profile';
import {loadMyFinished, loadMyInProgress} from "../../actions/user";
import {appLoadToken} from "../../actions/app";
import {COURSES_VIEW, router} from "../../config/routes";

class ProfileContainer extends Component {
  componentDidMount() {
    this.props.appLoadToken().then(res => {
      if(res){
        this.props.loadMyFinished();
        this.props.loadMyInProgress();
      }
    });
  }
  showCourse = course => {
    const { goTo } = this.props;
    goTo(router.getRoute(COURSES_VIEW, { id: course.id }));
  };

  render() {
    const { user,courses } = this.props;
    return <Profile user={user} courses={courses} onShowCourse={this.showCourse} />;
  }
}

const mapStateToProps = state => {
  return {
    ...state.userReducer,
    courses:{
      inProgress: {...state.me.inProgress},
      finished:{...state.me.finished}
    }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ appLoadToken,loadMyFinished, loadMyInProgress, goTo }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainer);
