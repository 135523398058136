import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadCategoryById } from '../../actions/categories';
import CoursesExplore from '../../components/explore/CoursesExplore';
import { COURSES_VIEW, router } from '../../config/routes';
import { goTo } from '../../actions/navigator';
import { appLoadToken } from '../../actions/app';


class CoursesExploreContainer extends Component {
  componentDidMount() {
    const { categoryId,loadCategoryById,appLoadToken } = this.props;
    appLoadToken().then(() => {
      loadCategoryById(categoryId)
    });
  }

  showCourse = course => {
    const { goTo } = this.props;
    goTo(router.getRoute(COURSES_VIEW, { id: course.id }));
  };

  render() {
    const { category, loading } = this.props;
    return (
      <CoursesExplore
        category={category}
        loading={loading}
        onShowCourse={this.showCourse}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.categoryReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ goTo, appLoadToken, loadCategoryById}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoursesExploreContainer);
