import React, { Component } from 'react';
import AutocompleteSearch from '../../components/ui/AutocompleteSearch';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeQuery, clear, init } from '../../actions/autocomplete';

const NEW_ITEM_ID = 'newItem';
class AutocompleteSearchContainer extends Component {
  state = {
    currentQuery: ''
  };

  componentDidMount() {
    const { keyName } = this.props;
    this.props.init(keyName);
  }

  onChange = query => {
    const { keyName } = this.props;
    this.setState(
      {
        currentQuery: query
      },
      () => this.props.changeQuery(keyName, query)
    );
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const { keyName, onFetch } = this.props;
    onFetch(keyName, value);
  };

  handleSuggestionsClearRequested = () => {
    const { keyName } = this.props;
    this.props.clear(keyName);
  };

  handleSuggestionHighlighted = ({ suggestion, ...all }) => {};

  handleSuggestionSelected = (event, { suggestion }) => {
    const { keyName, onCreate, changeQuery } = this.props;
    if (onCreate && suggestion.id === NEW_ITEM_ID) {
      onCreate(keyName, suggestion.query);
      this.props.handleSuggestionSelected({
        id: `${suggestion.id}_${suggestion.query}`,
        name: suggestion.query
      });
    } else {
      this.props.handleSuggestionSelected(suggestion);
    }
    this.setState({ currentQuery: '' }, () => changeQuery(keyName, ''));
  };

  getSuggestedItems = () => {
    const {
      items,
      value,
      onCreate,
      query,
      placeholder,
      displayAttribute
    } = this.props;
    const { currentQuery } = this.state;

    let suggestedItems = items || [];
    if (value && Array.isArray(value) && value.length) {
      suggestedItems = items.filter(
        item =>
          !value.some(
            suggestedItem =>
              suggestedItem.id === item.id || (displayAttribute ? (  typeof displayAttribute === "function" ? displayAttribute(suggestedItem) : suggestedItem[displayAttribute] ): suggestedItem.name).toLowerCase() ===
                query.toLowerCase()
          )
      );
    }

    if (onCreate) {
      const suggestedNames = suggestedItems.map(
        suggestedItem => suggestedItem.name
      );
      const queryCondition = query && query.length > 2;
      const notInSuggestedCondition = !suggestedNames.some(
        name => name.toLowerCase() === query.toLowerCase()
      );
      const notInValueCondition =
        !value ||
        (value && value.length === 0) ||
        (value &&
          value.length &&
          !value.some(
            item =>
              item[displayAttribute || 'name'].toLowerCase() ===
              query.toLowerCase()
          ));

      if (queryCondition && notInSuggestedCondition && notInValueCondition) {
        suggestedItems.push({
          id: NEW_ITEM_ID,
          name: `Crear ${placeholder} "${currentQuery}"...`,
          query: currentQuery
        });
      }
    }
    return suggestedItems;
  };

  render() {
    const {
      keyName,
      getSuggestionValue,
      renderSuggestion,
      query,
      placeholder,
      autoFocus
    } = this.props;

    return (
      <AutocompleteSearch
        keyName={keyName}
        placeholder={placeholder}
        onSuggestionSelected={this.handleSuggestionSelected}
        onSuggestionHighlighted={this.handleSuggestionHighlighted}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onChange={this.onChange}
        suggestions={this.getSuggestedItems()}
        query={query || ''}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        autoFocus={autoFocus}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const states = state.searchRequestReducer;
  return {
    ...states[ownProps.keyName]
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeQuery, clear, init }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutocompleteSearchContainer);
