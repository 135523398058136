import {
  PAGINATOR_UPDATE_ITEM,
  PAGINATOR_PAGINATE_NEXT,
  PAGINATOR_PAGINATE_PREV,
  PAGINATOR_PAGINATE_START,
  PAGINATOR_PAGINATE_END,
  PAGINATOR_PAGINATE_FAILED,
  PAGINATOR_CHANGE_QUERY,
  PAGINATOR_INIT,
  PAGINATOR_PAGINATE_TO,
  PAGINATOR_REFRESH
} from './index';

export function init(key) {
  return async dispatch => {
    dispatch({ type: PAGINATOR_INIT, key: key });
  };
}
export function paginateNext(key) {
  return async dispatch => {
    dispatch({ type: PAGINATOR_PAGINATE_NEXT, key: key });
  };
}

export function paginatePrev(key) {
  return async dispatch => {
    dispatch({ type: PAGINATOR_PAGINATE_PREV, key: key });
  };
}
export function paginateTo(key, page) {
  return async dispatch => {
    dispatch({
      type: PAGINATOR_PAGINATE_TO,
      key: key,
      payload: { page: page }
    });
  };
}

export function changeQuery(key, query) {
  return async dispatch => {
    dispatch({
      type: PAGINATOR_CHANGE_QUERY,
      key: key,
      payload: { query: query }
    });
  };
}

export function paginate(key, query, request, apply = a => a) {
  return async dispatch => {
    dispatch({
      type: PAGINATOR_PAGINATE_START,
      key: key,
      payload: { query: query }
    });
    try {
      const response = await request(dispatch);
      dispatch({
        type: PAGINATOR_PAGINATE_END,
        key: key,
        payload: {
          items: apply(response.data),
          query: query,
          meta: response.metadata
        }
      });
    } catch (e) {
      dispatch({ type: PAGINATOR_PAGINATE_FAILED, key: key, payload: e });
    }
  };
}

export function silentPaginate(key, query, request, apply = a => a) {
  return async dispatch => {
    try {
      const response = await request(dispatch);
      dispatch({
        type: PAGINATOR_PAGINATE_END,
        key: key,
        payload: {
          items: apply(response.data),
          query: query,
          meta: response.metadata
        }
      });
    } catch (e) {
      dispatch({ type: PAGINATOR_PAGINATE_FAILED, key: key, payload: e });
    }
  };
}

export function updateItem(key, itemId, apply = i => i) {
  return async dispatch => {
    dispatch({
      type: PAGINATOR_UPDATE_ITEM,
      key: key,
      payload: { itemId: itemId, apply: apply }
    });
  };
}

export function refresh(key) {
  return async dispatch => {
    dispatch({ type: PAGINATOR_REFRESH, key: key });
  };
}
