import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goToExploreCoursesByCategory } from '../../actions/navigator';
import { loadAllCategories } from '../../actions/categories';
import { appLoadToken } from '../../actions/app';
import Categories from '../../components/categories/Categories';

class CategoriesContainer extends Component {


  componentDidMount() {
    const { loadAllCategories,appLoadToken } = this.props;
    appLoadToken().then(() => {
      loadAllCategories()
    });
  }

  handleCategoryClick = category => {
    this.props.goToExploreCoursesByCategory(category.id);
  };

  render() {
    const {items, loading} = this.props;
    return <Categories onCategoryClick={this.handleCategoryClick} categories={items} loading={loading}/>;
  }
}

const mapStateToProps = (state) => {
  return {
      ...state.categoriesReducer
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ goToExploreCoursesByCategory,loadAllCategories,appLoadToken }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesContainer);
