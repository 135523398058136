import {
  COURSE_READ_END,
  COURSE_READ_FAILED,
  COURSE_READ_START,
  SEARCH_COURSES_READ_END,
  SEARCH_COURSES_READ_FAILED,
  SEARCH_COURSES_READ_START,
  RECOMMENDED_COURSES_READ_END,
  RECOMMENDED_COURSES_READ_FAILED,
  RECOMMENDED_COURSES_READ_START,
  REQUIRED_COURSES_READ_END,
  REQUIRED_COURSES_READ_FAILED,
  REQUIRED_COURSES_READ_START,
  FEATURED_CATEGORIES_READ_START,
  FEATURED_CATEGORIES_READ_END,
  FEATURED_CATEGORIES_READ_FAILED, SEARCH_COURSES_CLEAR
} from './index';
import api from '../api/api';
import { paginateNext, updateItem } from './infinite_paginator';

export function loadCourseById(id,preview=false) {
  return async dispatch => {
    dispatch({ type: COURSE_READ_START });
    try {
      const response = await ((preview) ? api.withDispatch(dispatch).Admin.getCoursePreview(id) : api.withDispatch(dispatch).Courses.get(id));
      dispatch({ type: COURSE_READ_END, payload: { course: response.data } });
    } catch (e) {
      dispatch({
        type: COURSE_READ_FAILED,
        payload: { message: e.toString() }
      });
    }
  };
}

export function silentUpdateCourseById(id){
  return async dispatch => {
    try {
      const response = await api.withDispatch(dispatch).Courses.get(id);
      dispatch({ type: COURSE_READ_END, payload: { course: response.data } });
    } catch (e) {
    }
  };
}


export function searchCourses(query) {
  return async dispatch => {
    const timestamp = Date.now();
    dispatch({ type: SEARCH_COURSES_READ_START, payload:{ timestamp:timestamp,query:query} });
    try {
      const response = await api.withDispatch(dispatch).Courses.searchByText(query);
      dispatch({
        type: SEARCH_COURSES_READ_END,
        payload: { items: response.data, timestamp:timestamp, query:query }
      });
    } catch (e) {
      dispatch({
        type: SEARCH_COURSES_READ_FAILED,
        payload: { message: e.toString(),timestamp:timestamp, query:query }
      });
    }
  };
}

export function clearSearchCourses() {
  return async dispatch => {
    dispatch({ type: SEARCH_COURSES_CLEAR });
  };
}

export function loadRecommended() {
  return async dispatch => {
    dispatch({ type: RECOMMENDED_COURSES_READ_START });
    try {
      const response = await api
        .withDispatch(dispatch)
        .Courses.getAllRecommended();
      dispatch({
        type: RECOMMENDED_COURSES_READ_END,
        payload: { items: response.data }
      });
    } catch (e) {
      dispatch({
        type: RECOMMENDED_COURSES_READ_FAILED,
        payload: { message: e.toString() }
      });
    }
  };
}

export function loadRequired() {
  return async dispatch => {
    dispatch({ type: REQUIRED_COURSES_READ_START });
    try {
      const response = await api
        .withDispatch(dispatch)
        .Courses.getAllRequired();
      dispatch({
        type: REQUIRED_COURSES_READ_END,
        payload: { items: response.data }
      });
    } catch (e) {
      dispatch({
        type: REQUIRED_COURSES_READ_FAILED,
        payload: { message: e.toString() }
      });
    }
  };
}



export function loadFeaturedCategories() {
  return async dispatch => {
    dispatch({ type: FEATURED_CATEGORIES_READ_START });
    try {

      const response = await api
          .withDispatch(dispatch)
          .Courses.getAllForFeaturedCategories();

      dispatch({
        type: FEATURED_CATEGORIES_READ_END,
        payload: { items: response.data }
      });
    } catch (e) {
      dispatch({
        type: FEATURED_CATEGORIES_READ_FAILED,
        payload: { message: e.toString() }
      });
    }
  };
}



export function updateCourseBlock(courseId, blockId, paginatorKey) {
  return async dispatch => {
    try {
      const response = await api
        .withDispatch(dispatch)
        .Courses.getBlock(courseId, blockId);
      return updateItem(paginatorKey, response.data);
    } catch (e) {}
  };
}

export function paginateCourseBlocks(
  courseId,
  paginatorKey,
  page,
  size,
  preview=false,
  apply = a => a
) {
  return paginateNext(
    paginatorKey,
    dispatch => {
      return  ((preview) ? api.withDispatch(dispatch).Admin.getCoursePreviewBlocks(courseId, page, size) : api.withDispatch(dispatch).Courses.getBlocks(courseId, page, size));
    },
    apply
  );
}

export function paginateCourseReviews(
  courseId,
  paginatorKey,
  page,
  size,
  apply = a => a
) {
  return paginateNext(
    paginatorKey,
    dispatch => {
      return api
        .withDispatch(dispatch)
        .Courses.getReviews(courseId, page, size);
    },
    apply
  );
}

export function rateCourse(courseId, data) {
  return async dispatch => {
    try {
      await api.withDispatch(dispatch).My.Courses.rate(courseId, data);
      await silentUpdateCourseById(courseId)(dispatch);
      return true;
    } catch (e) {}
    return false;
  };
}
