import React, { Component } from 'react';
import {IconButton} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const styles = theme => ({
  root: {
    textAlign: 'center'
  },
  noButtons: {
    paddingTop: 16
  }
});

class Paginator extends Component {
  render() {
    const {
      classes,
      page,
      total,
      onFirst,
      onLast,
      nextEnabled,
      prevEnabled,
      onNext,
      onPrev
    } = this.props;
    return (
      <div
        className={classnames(classes.root, {
          [classes.noButtons]: !prevEnabled && !nextEnabled
        })}
      >
        <IconButton
          onClick={() => onFirst()}
          disabled={!prevEnabled}
          aria-label="Primer página"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={() => onPrev()}
          disabled={!prevEnabled}
          aria-label="Página anterior"
        >
          <KeyboardArrowLeft />
        </IconButton>
        Página {page} de {total}
        <IconButton
          onClick={() => onNext()}
          disabled={!nextEnabled}
          aria-label="Siguiente página"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={() => onLast()}
          disabled={!nextEnabled}
          aria-label="Última página"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(Paginator);
