import React, { Component } from 'react';
import Header from './CoursesExploreHeader';
import Content from './CoursesExploreContent';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    backgroundColor: '#101010',
    height: '100%',
    width: '100%',
    maxWidth: '100vw'
  }
});

class CoursesExplore extends Component {
  render() {
    const { classes, category, onShowCourse } = this.props;

    return (
      category != null && (
        <div className={classes.root}>
          <Header category={category} />
          <Content onShowCourse={onShowCourse} courses={category.courses} />
        </div>
      )
    );
  }
}
export default withStyles(styles)(CoursesExplore);
