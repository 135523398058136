import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {Tooltip,IconButton,Typography,DialogContent,Dialog} from "@material-ui/core";

const styles = theme => ({
  root: {
    width: '100%'
  },

  header: {
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerBox: {
    width: '90%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  headerIconRight: {
    height: 32,
    width: 32,
    color: '#ffffff',
    position: 'absolute',
    top: 8,
    right: 8,
    padding: 0
  },
  headerCloseIcon: {
    height: 16,
    width: 16
  },
  headerName: {
    position: 'absolute',
    top: 16,
    left: 16,
    padding: 0,
    color: '#ffffff',
    fontSize: 12
  },

  headerContent: {},
  headerText: {
    color: '#ffffff',
    width: '100%',
    fontSize: 20,
    textAlign: 'center'
  },

  headerSubtitle: {
    color: '#ffffff',
    width: '100%',
    fontSize: 14,
    textAlign: 'center'
  },

  headerIcon: {
    color: '#ffffff',
    width: '100%',
    textAlign: 'center'
  },

  paperDialog: {
    borderRadius: 0
  },
  paperWidthSm: {
    maxWidth: 700
  }
});

class GenericDialog extends Component {
  handleClose = () => {
    const { onDismiss } = this.props;
    onDismiss();
  };

  renderHeader = () => {
    const {
      classes,
      miniHeader= false,
      name = false,
      title = false,
      subtitle = false,
      icon,
      headerColor = '#1ed1a8'
    } = this.props;
    return (
      <div className={classes.header} style={{ background: headerColor, minHeight: miniHeader?50:200 }}>
        {name && (
          <Typography className={classes.headerName}> {name}</Typography>
        )}
        <div className={classes.headerBox}>
          {icon && <div className={classes.headerIcon}> {icon}</div>}
          {title && (
            <Typography className={classes.headerText}> {title}</Typography>
          )}
          {subtitle && (
            <Typography className={classes.headerSubtitle}>
              {' '}
              {subtitle}
            </Typography>
          )}
        </div>
        <Tooltip title={'CERRAR'}>
          <IconButton
          className={classes.headerIconRight}
          onClick={this.handleClose}>
            <CloseIcon className={classes.headerCloseIcon} />
          </IconButton>
          </Tooltip>
      </div>
    );
  };

  render() {
    const { classes, open, children, onExit = () => {} } = this.props;
    return (
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        className={classes.root}
        classes={{ paper: classes.paperDialog,paperWidthSm: classes.paperWidthSm }}
        aria-labelledby="form-dialog-title"
        open={open}
        onExit={onExit}
      >
        {this.renderHeader()}
        <div>
          <DialogContent>{children}</DialogContent>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(GenericDialog);
