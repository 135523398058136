import { PROFILE_ME, BASE_ADMIN } from "../../config/routes";

export const ITEM_PROFILE_ME = "ITEM_PROFILE_ME";
export const ITEM_ADMIN = "ITEM_ADMIN";

export const menuItems = {
  items: [
    {
      title: "Perfil",
      icon: "person",
      action: ITEM_PROFILE_ME,
      submenu: false,
      route: PROFILE_ME
    },
    {
      title: "Administrador",
      icon: "settings",
      action: ITEM_ADMIN,
      submenu: false,
      route: BASE_ADMIN
    }
  ]
};
