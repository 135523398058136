import React from 'react';
import { Typography, IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyIcon from '@material-ui/icons/VpnKey';
import { activityTypeIcon } from '../../../../utils/ActivitiesHelper';

const styles = {
  activityHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    height: '3em',
    width: '100%'
  },
};

const ActivityIcon = ({ activity }) => {
  return activityTypeIcon(activity, '');
};

const ActivityModuleHeader = ({
  onEdit,
  onRemove,
  onMilestone,
  classes,
  activity,
  unableActionsCreateDelete,
  availableMilestone
}) => (
    <div className={classes.activityHeader}>
      {availableMilestone && (
        <Tooltip title="HITOS">
          <IconButton onClick={onMilestone}>
            <KeyIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="EDITAR">
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      {unableActionsCreateDelete !== true && (
        <Tooltip title="ELIMINAR">
          <IconButton onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );

export default withStyles(styles)(ActivityModuleHeader);
