import { createMuiTheme } from '@material-ui/core';

export const inverted_progressbar_theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#ffffff'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        height: 5
      },
      colorPrimary: {
        backgroundColor: '#777777'
      },
      barColorPrimary: {
        backgroundColor: '#ffffff'
      }
    }
  }
});
