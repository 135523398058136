import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  PROFILE_ME,
  COURSES_EXPLORE,
  COURSES_EXPLORE_CATEGORY,
  COURSES_VIEW,
  HOME,
  SEARCH,
  TOKEN,
  RESET_PASSWORD, REGISTER, SURVEY
} from '../config/routes';
import HomeContainer from '../containers/home/Home';
import CourseContainer from '../containers/courses/Course';
import CoursesExploreContainer from '../containers/explore/CoursesExploreContainer';
import CategoriesContainer from '../containers/categories/CategoriesContainer';
import Profile from '../containers/profile/ProfileContainer';
import Search from '../containers/search/SearchContainer';
import SetTokenContainer from '../containers/session/SetTokenContainer';
import ResetPasswordContainer from "../containers/session/ResetPassword";
import RegisterContainer from "../containers/session/Register";
import SurveyContainer from "../containers/surveys/Survey";
import * as ReactGA from 'react-ga';

const styles = theme => ({
  content: {
    flexGrow: 1,
    minWidth: 0, // So the Typography noWrap works,
    maxWidth: 'calc(100vw - 0%)'
  },
  toolbar: theme.mixins.toolbar
});

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
function Main(props) {
  const { classes } = props;

  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className={classes.content}>
      <Switch>
        <Route
          exact
          path={TOKEN}
          component={({ location: { search } }) => (
            <SetTokenContainer search={search} />
          )}
        />
        <Route exact path={HOME} component={() => <HomeContainer />} />
        <Route exact path={RESET_PASSWORD} component={()=>{return (<ResetPasswordContainer />)}}/>
        <Route exact path={REGISTER} component={()=>{return (<RegisterContainer />)}}/>
        <Route exact path={SURVEY} component={()=>{return (<SurveyContainer />)}}/>
        <Route
          exact
          path={COURSES_VIEW}
          component={({ match }) => (
            <CourseContainer courseId={match.params.id} />
          )}
        />
        <Route
          exact
          path={COURSES_EXPLORE}
          component={() => <CategoriesContainer />}
        />
        <Route
          exact
          path={COURSES_EXPLORE_CATEGORY}
          component={({ match }) => (
            <CoursesExploreContainer categoryId={match.params.categoryId} />
          )}
        />
        <Route exact path={PROFILE_ME} component={() => <Profile />} />
        <Route exact path={SEARCH} component={() => <Search />} />

        <Route path="*" component={() =><Redirect to={HOME}/>}/>
      </Switch>
    </div>
  );
}

export default withStyles(styles)(Main);
