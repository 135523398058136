import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import config from '../../config/index';
import classNames from 'classnames';
import { AppBar, Toolbar, Button, IconButton } from '@material-ui/core';
import { FrontNavBarActionTypes } from '../../config/constants';
import LogoutContainer from '../../containers/session/Logout';
import { isAdmin } from '../../utils/AdminHelper';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    appBar: {
      backgroundColor: 'black',
      zIndex: theme.zIndex.drawer + 1,
      transitionTimingFunction: 'ease-in',
      transition: 'all 1s'
    },
    toolbar: {
      position: 'relative',
      padding: '0 90px',
      display: 'flex',
      justifyContent: 'space-between',
      [mediaLg]: {
        padding: '0'
      }
    },
    menuHandler: {
      display: 'none',
      [mediaLg]: {
        display: 'block'
      }
    },
    actions: {
      minHeight: 50,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    home: {
      position: 'relative',
      padding: '1em 0',
      boxSizing: 'border-box',
      minWidth: 'unset',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    logoAcademia: {
      height: 25,
      marginRight: 8,
      [mediaLg]: {
        height: 25
      }
    },
    exploreButton: {
      marginLeft: 18
    },
    flex: {
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center'
    },
    navIconHide: {
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    icon: {
      height: '2em',
      width: 0
    },
    withoutBackground: {
      backgroundColor: 'rgba(0,0,0,0)',
      boxShadow: 'none'
    },
    action: {
      color: '#fff'
    },
    hideAction: {
      [mediaLg]: {
        display: 'none'
      }
    }
  };
};

class FrontNavBar extends React.Component {
  state = {
    scrolling: false
  };

  handleDrawerToggle = () => {
    const { onToggleMenu } = this.props;
    onToggleMenu();
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  /** changes the scrolling state depending on the Y-position */
  handleScroll = event => {
    if (window.scrollY === 0) {
      this.setState({ scrolling: false });
    } else if (window.scrollY > 100 && !this.state.scrolling) {
      this.setState({ scrolling: true });
    }
  };

  render() {
    const { scrolling } = this.state;

    const { classes, onActionClick, me, location } = this.props;
    const inHome = location.pathname === '/';

    const admin = isAdmin(me);

    return (
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.withoutBackground]: !scrolling && inHome
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={`${classes.action} ${classes.menuHandler}`}
            aria-label="Open drawer"
            onClick={this.handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <div>
            <Button
              className={classes.home}
              color="inherit"
              onClick={() => onActionClick(FrontNavBarActionTypes.Home)}
            >
              <img
                alt="Logo Academia Salud"
                onClick={() => onActionClick(FrontNavBarActionTypes.Home)}
                src={config.BASE_URL + 'img/ac_toolbar_icon.png'}
                className={classes.logoAcademia}
              />
              HOME
            </Button>
            <Button className={`${classes.action} ${classes.exploreButton}`} onClick={() => onActionClick(FrontNavBarActionTypes.Explore)}>CATEGORÍAS</Button>
          </div>
          <div className={classes.flex}>
            <div className={classes.actions}>
              <IconButton
                className={classes.action}
                onClick={() => onActionClick(FrontNavBarActionTypes.Search)}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                className={`${classes.action} ${classes.hideAction}`}
                onClick={() => onActionClick(FrontNavBarActionTypes.Profile)}
              >
                <PersonIcon />
              </IconButton>
              {admin && (
                <IconButton
                  className={`${classes.action} ${classes.hideAction}`}
                  onClick={() =>
                    onActionClick(FrontNavBarActionTypes.AdminHome)
                  }
                >
                  <SettingsIcon />
                </IconButton>
              )}
              <div
                className={classes.hideAction}
                style={{ top: '1px', position: 'relative' }}
              >
                <LogoutContainer />
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(FrontNavBar);
