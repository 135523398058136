import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import HorizontalZoomSlider from "../ui/HorizontalZoomSlider";
import CourseItem from "../ui/CourseItem";
import PlayIcon from "@material-ui/icons/PlayCircleOutline";
import RecommendedIcon from "@material-ui/icons/Whatshot";
import RequiredIcon from "@material-ui/icons/ErrorOutline";
import FinishedIcon from "@material-ui/icons/CheckCircleOutline";
import ParallaxBackground from "../ui/ParallaxBackground";
import api from "../../api/api";

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    root: {
      backgroundColor: "#101010",
      display: "flex",
      flexFlow: "column",
      maxWidth: 'calc(100vw - 0%)',
      paddingTop: 24,
      paddingBottom: 50,
      paddingRight: 90,
      paddingLeft: 90,
      position: "relative",
      [mediaLg]: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0
      }
    },
    categoryIcon: {
      marginRight: 4,
      width: 20,
      height: 20,
      borderRadius: 14,
      padding: 4,
      marginBottom: -5
    },
    icon: {
      marginBottom: -2,
      fontSize: 20,
      color: "#fff",
      marginRight: 8
    },
    exploreButton: {
      margin: "auto",
      marginTop: 16,
      color: "white",
      width: 200,
      borderColor: "white"
    }
  };
};

class ContentHome extends Component {
  onCreateItemCourse = item => {
    return <CourseItem item={item} />;
  };
  onItemClick = item => {
    const { onShowCourse } = this.props;
    onShowCourse(item);
  };
  render() {
    const {
      classes,
      onGoToExplore,
      courses: {
        required,
        recommended,
        inProgress,
        finished,
        featuredCategories
      }
    } = this.props;

    return (
      <div className={classes.root}>
        <ParallaxBackground />
        {inProgress.items.length > 0 && (
          <HorizontalZoomSlider
            icon={<PlayIcon className={classes.icon} />}
            title={"Continuar viendo"}
            itemCreator={item => this.onCreateItemCourse(item)}
            items={inProgress.items}
            itemClick={item => this.onItemClick(item)}
          />
        )}
        {recommended.items.length > 0 && (
          <HorizontalZoomSlider
            icon={<RecommendedIcon className={classes.icon} />}
            title={"Recomendados"}
            itemCreator={item => this.onCreateItemCourse(item)}
            items={recommended.items}
            itemClick={item => this.onItemClick(item)}
          />
        )}
        {required.items.length > 0 && (
          <HorizontalZoomSlider
            icon={<RequiredIcon className={classes.icon} />}
            title={"Obligatorios"}
            itemCreator={item => this.onCreateItemCourse(item)}
            items={required.items}
            itemClick={item => this.onItemClick(item)}
          />
        )}
        {featuredCategories.items.length > 0 &&
          featuredCategories.items.map(fc => (
            <HorizontalZoomSlider
              icon={
                <img
                  src={api.signUrl(fc.icon)}
                  alt={fc.name}
                  className={classes.categoryIcon}
                  style={{ backgroundColor: fc.color }}
                />
              }
              title={fc.name}
              itemCreator={item => this.onCreateItemCourse(item)}
              items={fc.courses}
              itemClick={item => this.onItemClick(item)}
            />
          ))}

        {finished.items.length > 0 && (
          <HorizontalZoomSlider
            icon={<FinishedIcon className={classes.icon} />}
            title={"Finalizados"}
            itemCreator={item => this.onCreateItemCourse(item)}
            items={finished.items}
            itemClick={item => this.onItemClick(item)}
          />
        )}

        <Button
          className={classes.exploreButton}
          variant={"outlined"}
          onClick={() => onGoToExplore()}
        >
          {"EXPLORAR CURSOS"}
        </Button>
      </div>
    );
  }
}
export default withStyles(styles)(ContentHome);
