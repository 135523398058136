import {
  MY_COURSES_FINISHED_READ_END,
  MY_COURSES_FINISHED_READ_START,
  MY_COURSES_FINISHED_READ_FAILED
} from '../../actions';

let initialState = {
  error: null,
  loading: false,
  items: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MY_COURSES_FINISHED_READ_END:
      const { items } = action.payload;
      return {
        loading: false,
        items: items,
        error: null
      };
    case MY_COURSES_FINISHED_READ_START:
      return {
        items: [],
        error: null,
        loading: true
      };
    case MY_COURSES_FINISHED_READ_FAILED:
      const { message } = action.payload;
      return {
        items: [],
        error: message,
        loading: false
      };
    default:
      return { ...state, error: null };
  }
};
