import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {paginateCourseReviews} from "../../actions/courses";
import {init} from "../../actions/infinite_paginator";
import CourseReviewsDialog from '../../components/courses/CourseReviewsDialog';

class CourseReviewsDialogContainer extends Component {

  constructor(props){
    super(props);
    props.init(props.keyName);
  }


  onFetch = () =>{
    const {keyName,course,paginateCourseReviews,page} = this.props;
    paginateCourseReviews(course.id,keyName,page,20);
  };

  render() {
    const { open, onDismiss = () => {},headerColor, course,hasMore, loading, items } = this.props;
    return (
      <CourseReviewsDialog
        course={course}
        headerColor={headerColor}
        open={open}
        onDismiss={onDismiss}
        onFetchMore={this.onFetch}
        hasMore={hasMore}
        loading={loading}
        items={items}
      />
    );
  }
}

const mapStateToProps = (state,props) => {
  const infiniteState = state.infiniteScrollPaginatorReducer;
  return {
    ...infiniteState[props.keyName]
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({paginateCourseReviews,init}, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CourseReviewsDialogContainer)
);
