import React, { Component } from 'react';
import AutocompleteSearchSimpleObject from '../../components/ui/AutocompleteSearchSimpleObject';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EditInputObjectModel } from '../../config/constants';
import {
  searchTags,
  searchCategories,
  searchCourses,
  searchUsers,
  createTags,
  createCategories
} from '../../actions/autocomplete';

class AutocompleteSearchSimpleObjectContainer extends Component {
  getOnFetchHanlder = () => {
    switch (this.props.model) {
      case EditInputObjectModel.Tags:
        return this.props.searchTags;
      case EditInputObjectModel.Categories:
        return this.props.searchCategories;
      case EditInputObjectModel.Courses:
        return this.props.searchCourses;
      case EditInputObjectModel.Users:
        return this.props.searchUsers;
      default:
        return null;
    }
  };

  getOnCreateHanlder = () => {
    switch (this.props.model) {
      case EditInputObjectModel.Tags:
        return this.props.createTags;
      case EditInputObjectModel.Categories:
        return this.props.createCategories;
      default:
        return null;
    }
  };

  render() {
    const {
      keyName,
      onItemSelected,
      placeholder,
      value,
      creatable,
      autoFocus,
      displayAttribute
    } = this.props;
    return (
      <AutocompleteSearchSimpleObject
        keyName={keyName}
        onFetch={this.getOnFetchHanlder()}
        onCreate={creatable && this.getOnCreateHanlder()}
        placeholder={placeholder}
        onItemSelected={onItemSelected}
        value={value}
        autoFocus={autoFocus}
        displayAttribute={displayAttribute}
      />
    );
  }
}

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchTags,
      searchCategories,
      createTags,
      createCategories,
      searchCourses,
      searchUsers
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutocompleteSearchSimpleObjectContainer);
