import React, { Component } from 'react';
import AutocompleteSearch from '../../containers/ui/AutocompleteSearch';
import {MenuItem,Typography } from '@material-ui/core';

class AutocompleteSearchSimpleObject extends Component {
  getSuggestionValue = item => {
    const { displayAttribute } = this.props;
    return (displayAttribute ? (  typeof displayAttribute === "function" ? displayAttribute(item) : item[displayAttribute] ): item.name) || '';
  };

  renderSuggestion = item => {
    const styleName = {
      float: 'left',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      color: '#707070',
      fontSize: 12
    };

    const borderLine = {
      borderBottom: ' 0.5px solid rgba(112,112,112,0.24)'
    };
    return (
      <MenuItem style={borderLine} component="div">
        <div>
          <Typography style={styleName}>
            {this.getSuggestionValue(item)}
          </Typography>
        </div>
      </MenuItem>
    );
  };

  render() {
    const {
      keyName,
      onItemSelected,
      placeholder,
      onFetch,
      onCreate,
      value,
      autoFocus,
      displayAttribute
    } = this.props;
    return (
      <AutocompleteSearch
        keyName={keyName}
        onFetch={onFetch}
        onCreate={onCreate}
        placeholder={placeholder}
        handleSuggestionSelected={onItemSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        value={value}
        autoFocus={autoFocus}
        displayAttribute={displayAttribute || 'name'}
      />
    );
  }
}

export default AutocompleteSearchSimpleObject;
