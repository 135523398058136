import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';

const styles = () => ({
  zoomGrid: {
    gridColumn: '2 / 13',
    color: '#fff',
    paddingLeft: 60,
    paddingRight: 60,
    '&__heading': {
      paddingTop: 16,
      paddingLeft: 128
    },

    '&__container::-webkit-scrollbar': {
      display: 'none'
    },

    '&__container': {
      '&:hover &--item': {
        '&:hover': {
          transform: 'scale(1.25)',
          opacity: 1,
          zIndex: 1000
        }
      },
      '&--item': {
        cursor: 'pointer',
        transition: 'all 450ms',
        transform: 'center left',
        minHeight: 0,
        zIndex: 1,
        position: 'relative',
        padding: 4,
        '&-image': {
          height: '100%'
        }
      }
    }
  }
});

class ZoomGrid extends Component {
  getItems = items => {
    const {
      classes,
      itemCreator = i => null,
      itemClick = i => console.log('click on', i),
      itemWidth = 320,
      itemHeight = 180
    } = this.props;
    return items.map((i, index) => {
      return (
        <Grid item key={index} onClick={() => itemClick(i)}>
          <div
            className={classes.zoomGrid + '__container--item'}
            style={{ width: itemWidth, height: itemHeight }}
          >
            {itemCreator(i)}
          </div>
        </Grid>
      );
    });
  };

  render() {
    const { classes, items } = this.props;
    return (
      <div className={classes.zoomGrid}>
        <Grid
          justify={'center'}
          container
          className={classes.zoomGrid + '__container'}
        >
          {this.getItems(items)}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(ZoomGrid);
