import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResource } from '../../../actions/admin';
import AdminResourceView from '../../../components/admin/AdminResourceView';
import { goTo } from '../../../actions/navigator';
import {ADMIN_TAGS} from "../../../config/routes";

class AdminTagsViewContainer extends Component {
  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  getColumns = () => {
    return {
      keys: [{ title: 'ID', source: 'id' }, { title: 'Nombre', source: 'name' }]
    };
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_TAGS);
  };

  onEditItem = item => {
    console.log('onEditItem', item);
  };

  componentDidMount = () => {
    this.onLoadData();
  };

  render() {
    const { item, loading } = this.props;
    return (
      <AdminResourceView
        title={'ETIQUETA'}
        item={item}
        loading={loading}
        attributes={this.getColumns()}
        onGoBack={() => this.onGoBack()}

      />
    );
  }
}

AdminTagsViewContainer.defaultProps = {
  type: 'tags'
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    ...states[ownProps.keyName]
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResource, goTo }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTagsViewContainer);
