import ImageIcon from "@material-ui/icons/CropOriginal";
import TextIcon from "@material-ui/icons/Subject";
import SchoolIcon from "@material-ui/icons/School";
import PlayIcon from "@material-ui/icons/PlayCircleOutline";
import AudioIcon from "@material-ui/icons/Headset";
import QuestionIcon from "@material-ui/icons/HelpOutline";
import WorldIcon from "@material-ui/icons/Language";
import TimelineIcon from "@material-ui/icons/Timeline";
import SlideshowIcon from "@material-ui/icons/Dvr";
import FileIcon from "@material-ui/icons/Description";

export const LOG_NETWORK = String(process.env.REACT_APP_LOG_NETWORK) === "true";

export const Paginator = Object.freeze({
  PAGE_SIZE: 20
});

export const FrontNavBarActionTypes = Object.freeze({
  Home: "home",
  Search: "search",
  Profile: "profile",
  AdminHome: "admin-home",
  Explore: "explore"
});

export const AdminItemViewType = Object.freeze({
  Text: "text",
  Number: "number",
  Avatar: "avatar",
  Image: "image",
  Boolean: "boolean",
  Fixed: "fixed",
  Conditional: "conditional",
  Formatted: "formatted",
  Date: "date",
  Action: "action",
  Background: "background",
  Custom: "custom"
});

export const EditInputType = Object.freeze({
  Color: "input-color",
  Background: "input-background",
  Text: "input-text",
  Number: "input-number",
  Password: "input-password",
  Boolean: "input-boolean",
  Object: "input-object",
  Fixed: "input-fixed",
  Formatted: "input-formatted",
  Select: "input-select",
  MultipleSelect: "input-multiple-select",
  Date: "input-date",
  File: "input-file",
  Dropzone: "input-dropzone",
  TextEditor: "input-text-editor",
  RadioGroup: "input-radio-group",
  Switch: "input-switch",
  Custom: "input-custom"
});

export const EditInputObjectModel = Object.freeze({
  Tags: "model-tags",
  Categories: "model-categories",
  Users: "model-users",
  Courses: "model-courses"
});

export const SnackbarType = Object.freeze({
  Generic: 0,
  Error: 1,
  Warning: 2,
  Info: 3,
  Success: 4
});

export const FilterType = Object.freeze({
  Fixed: "fixed",
  Dynamic: "dynamic"
});

export const FiltersFormat = Object.freeze({
  Like: "like",
  Null: "null",
  Plain: "plain",
  In: "in",
  FullSearch: "full_search"
});

export const ActivityType = Object.freeze({
  Text: "Text",
  Image: "Image",
  External: "Extern",
  Scorm: "Scorm",
  Audio: "Audio",
  Video: "Video",
  Trivia: "Trivia",
  Timeline: "TimeLine",
  Presentation: "Presentation",
  File: "File"
});

export const ActivitiesTypesList = Object.freeze([
  {
    type: ActivityType.Text,
    description: "Texto",
    icon: TextIcon,
    title: "ACTIVIDAD DE LECTURA"
  },
  {
    type: ActivityType.Image,
    description: "Imagen",
    icon: ImageIcon,
    title: "ACTIVIDAD DE IMAGEN"
  },
  {
    type: ActivityType.External,
    description: "Externa",
    icon: WorldIcon,
    title: "ACTIVIDAD EXTERNA"
  },
  {
    type: ActivityType.Scorm,
    description: "SCORM",
    icon: SchoolIcon,
    title: "ACTIVIDAD SCORM"
  },
  {
    type: ActivityType.Audio,
    description: "Audio",
    icon: AudioIcon,
    title: "ACTIVIDAD DE AUDIO"
  },
  {
    type: ActivityType.Video,
    description: "Video",
    icon: PlayIcon,
    title: "ACTIVIDAD DE VIDEO"
  },
  {
    type: ActivityType.Trivia,
    description: "Cuestionario",
    icon: QuestionIcon,
    title: "ACTIVIDAD DE CUESTIONARIO"
  },
  {
    type: ActivityType.Presentation,
    description: "Presentación interactiva",
    icon: SlideshowIcon,
    title: "ACTIVIDAD DE PRESENTACIÓN"
  },
  {
    type: ActivityType.Timeline,
    description: "Línea temporal",
    icon: TimelineIcon,
    title: "ACTIVIDAD DE LÍNEA DE TIEMPO"
  }, {
    type: ActivityType.File,
    description: "Archivo",
    icon: FileIcon,
    title: "ACTIVIDAD DE ARCHIVO"
  }
]);

export const ActivityTypeToH5pLibrary = Object.freeze({
  [ActivityType.Audio]: "H5P.Audio 1.4",
  [ActivityType.Video]: "H5P.InteractiveVideo 1.22",
  [ActivityType.Trivia]: "H5P.QuestionSet 1.17",
  [ActivityType.Timeline]: "H5P.Timeline 1.1",
  [ActivityType.Presentation]: "H5P.CoursePresentation 1.22"
});

export const CourseStatus = Object.freeze({
  1: "Borrador",
  2: "Publicado"
});

export const CourseStatusValue = Object.freeze({
  Draft: 1,
  Published: 2
});

export const CourseProgress = Object.freeze({
  true: "Finalizado",
  false: "Iniciado"
});

export const CourseProgressValue = Object.freeze({
  Completed: true,
  Incomplete: false
});

export const ExternActivityTargetType = Object.freeze({
  Embedded: 1,
  FullScreen: 2,
  NewTab: 3
});

export const HorizontalSlideArrowType = Object.freeze({
  right: "right",
  left: "left"
});


export const States = Object.freeze([
  {id: '24', description: 'CABA'},
  {id: '1', description: 'Buenos Aires'},
  {id: '2', description: 'Catamarca'},
  {id: '3', description: 'Chaco'},
  {id: '4', description: 'Chubut'},
  {id: '5', description: 'Córdoba'},
  {id: '6', description: 'Corrientes'},
  {id: '7', description: 'Entre Ríos'},
  {id: '8', description: 'Formosa'},
  {id: '9', description: 'Jujuy'},
  {id: '10', description: 'La Pampa'},
  {id: '11', description: 'La Rioja'},
  {id: '12', description: 'Mendoza'},
  {id: '13', description: 'Misiones'},
  {id: '14', description: 'Neuquén'},
  {id: '15', description: 'Río Negro'},
  {id: '16', description: 'Salta'},
  {id: '17', description: 'San Juan'},
  {id: '18', description: 'San Luis'},
  {id: '19', description: 'Santa Cruz'},
  {id: '20', description: 'Santa Fe'},
  {id: '21', description: 'Santiago del Estero'},
  {id: '22', description: 'Tierra del Fuego, Antártida e Isla del Atlántico Sur'},
  {id: '23', description: 'Tucumán'},
]);


export const AgeRange = Object.freeze([
  {id: '1', description: '18 a 30 años'},
  {id: '2', description: '31 a 45 años'},
  {id: '3', description: '46 a 60 años'},
  {id: '4', description: '61 años en adelante'},
]);

export const OtherWorkAreaId = '0';

export const WorkAreas = Object.freeze([
  {id: '1', description: 'Clínica'},
  {id: '2', description: 'Hospital'},
  {id: '3', description: 'Prepaga'},
  {id: '4', description: 'Obra Social'},
  {id: '5', description: 'Servicio de emergencia'},
  {id: '6', description: 'Empresa de salud'},
  {id: OtherWorkAreaId, description: 'Otros'},
]);
