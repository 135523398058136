import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './ActivityModalStyles';
import AdminResourceForm from '../../AdminResourceForm';
import { EditInputType } from '../../../../config/constants';

const MAX_SIZE = 15;

const FORM_COLUMNS = [
  {
    title: 'Nombre',
    source: 'name',
    editable: true,
    editConfig: { type: EditInputType.Text, name: 'name' }
  },
  {
    title: 'Descripción',
    source: 'description',
    editable: true,
    editConfig: {
      type: EditInputType.Text,
      name: 'description',
      multiline: 4
    }
  },
  {
    source: 'url',
    editable: true,
    editConfig: {
      type: EditInputType.Dropzone,
      name: 'url',
      dropDetail: 'JPG o PNG',
      accept:
        'image/png, image/jpg, image/jpeg, application/pdf, application/x-pdf',
      multiple: false,
      maxSize: MAX_SIZE
    }
  }
];

const ActivityModalImageContent = ({
  activity,
  results,
  onChange,
  classes,
  onSubmit,
  actionButtons,
  onCancel,
  title
}) => {
  const onAttributeChange = async (name, value) => {
    onChange({
      ...activity,
      [name]: name === 'url' ? value.acceptedFiles[0] : value,
      fileError:
        name === 'url' && value && value.failedFiles && value.failedFiles.length
          ? `Debes subir un archivo menor a ${MAX_SIZE} mb`
          : undefined
    });
  };
  return (
    <AdminResourceForm
      title={title}
      keyName={'admin-activity-modal-image-form'}
      form={{
        ...activity,
        url: activity.url ? activity.url.preview : activity.url
      }}
      isValid={results && results.isValid}
      errors={results ? results.errors : []}
      loading={false}
      attributes={{ keys: FORM_COLUMNS }}
      onAttributeChange={onAttributeChange}
      customClasses={classes}
      onSubmit={onSubmit}
      onCancel={onCancel}
      actionButtons={actionButtons}
    />
  );
};

export default withStyles(styles)(ActivityModalImageContent);
