import ActivityModuleTextContent from './ActivityModuleTextContent';
import ActivityModuleImageContent from './ActivityModuleImageContent';
import ActivityModuleExternContent from './ActivityModuleExternContent';
import ActivityModuleScormContent from './ActivityModuleScormContent';
import ActivityModuleH5pContent from './ActivityModuleH5pContent';
import {ActivityType} from "../../../../config/constants";
import ActivityModuleFileContent from "./ActivityModuleFileContent";

export const ACTIVITY_TYPE_COMPONENTS = {
  [ActivityType.Text]: ActivityModuleTextContent,
  [ActivityType.Image]: ActivityModuleImageContent,
  [ActivityType.External]: ActivityModuleExternContent,
  [ActivityType.Scorm]: ActivityModuleScormContent,
  [ActivityType.Video]: ActivityModuleH5pContent,
  [ActivityType.Audio]: ActivityModuleH5pContent,
  [ActivityType.Presentation]: ActivityModuleH5pContent,
  [ActivityType.Trivia]: ActivityModuleH5pContent,
  [ActivityType.Timeline]: ActivityModuleH5pContent,
  [ActivityType.File]: ActivityModuleFileContent,

};
