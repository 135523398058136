import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {appLoadToken} from '../actions/app';
import {withRouter} from 'react-router-dom';
import Layout from '../components/Layout';
import AdminLayout from './admin/AdminLayout';
import * as ReactGA from 'react-ga';
import {LOG_NETWORK} from '../config/constants';
import {isAdminLocation, isAdmin} from '../utils/AdminHelper';
import {getCurrent} from '../actions/user';
import Loading from '../components/ui/Loading';

class AppContainer extends Component {
  state = {
    initialized: false,
  };

  componentDidMount() {
    this.props.appLoadToken().then((res) => {
      this.setState({initialized: true});
      if (res) {
        this.props.getCurrent();
      }
    });
  }

  render() {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
      debug: LOG_NETWORK,
    });

    const {location, user} = this.props;

    const recaptchaElement =
      window.top.document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElement.length) {
      if (user) recaptchaElement[0].style.visibility = 'hidden';
      else recaptchaElement[0].style.visibility = 'visible';
    }

    if (isAdminLocation(location) && !user)
      return <Loading dark={false} key={'loading-admin-index'} />;

    if (isAdminLocation(location) && isAdmin(user)) {
      if (this.state.initialized) {
        return <AdminLayout me={user} />;
      }
      return null;
    }

    return <Layout me={user} location={location} />;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.userReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({appLoadToken, getCurrent}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppContainer),
);
