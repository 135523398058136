import React, { Component } from 'react';
import MenuDrawer from '../menu/MenuDrawer';
import { IconButton, Button, Typography } from '@material-ui/core';
import {  withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from '@material-ui/icons/Close';
import { reorder, orderArrayBySort } from '../course/DraggableUtils';

const styles = {
  drawerContainer: {
    position: 'absolute',
    right: 0,
    top: 64,
    height: '100%'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1em',
    boxSizing: 'border-box'
  },
  activityTitle: {
    padding: '1em 0 1em 1em',
    boxSizing: 'border-box'
  },
  blockTitle: {
    fontWeight: 700,
    padding: '1em 0'
  },
  body: {
    padding: '0 1em',
    boxSizing: 'border-box',
    height: '100%'
  },
  drawerPaper: {
    width: 480,
    position: 'relative',
    height: '100%',
    minHeight: '100%',
    paddingTop: '16px'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #dedede'
  }
};

const DrawerCustomContent = ({
  classes,
  sortedCategories,
  categories,
  allActivities,
  showDrawer,
  handleSubmitOrder,
  onDragEnd
}) => (
  <React.Fragment>
    <div className={classes.toolbar}>
      <IconButton onClick={showDrawer}>
        <CloseIcon />
      </IconButton>
      <Button onClick={handleSubmitOrder}>GUARDAR</Button>
    </div>
    <div className={classes.body}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppableCategoriesContainer"
          style={{ height: '100%' }}
        >
          {(containerProvided, snapshot) => (
            <div ref={containerProvided.innerRef} style={{ height: '100%' }}>
              {sortedCategories &&
                sortedCategories.map((sortedCategory, index) => {
                  const category = categories.find(
                    item => item.id === sortedCategory
                  );
                  return (
                    <Draggable
                      draggableId={category.id}
                      index={index}
                      key={category.id}
                    >
                      {(categoryProvided, snapshot) => (
                        <div
                          className={classes.row}
                          ref={categoryProvided.innerRef}
                          {...categoryProvided.draggableProps}
                          {...categoryProvided.dragHandleProps}
                        >
                          <Typography className={classes.blockTitle}>
                            {category.name}
                          </Typography>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {containerProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  </React.Fragment>
);

const WrappedDrawerCustomContent = withStyles(styles)(DrawerCustomContent);

class CategoriesDrawer extends Component {
  state = {
    sortedCategories: []
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.categories && nextProps.categories) {
      const sortedCategories = nextProps.categories
        .sort(orderArrayBySort)
        .map(category => category.id);
      this.setState({ sortedCategories });
    }
  };

  handleSubmitOrder = () => {
    this.props.onSubmit(this.state.sortedCategories);
  };

  onDragEnd = ({ source, destination }) => {
    const { sortedCategories } = this.state;

    // dropped outside the list
    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const items = reorder(sortedCategories, source.index, destination.index);
      this.setState({ sortedCategories: items });
    }
    return;
  };

  render() {
    const { classes, drawer, showDrawer, categories } = this.props;
    const { sortedCategories } = this.state;

    return (
      <div>
        <MenuDrawer
          customClasses={classes}
          mobileOpen={drawer}
          customContent={
            <WrappedDrawerCustomContent
              sortedCategories={sortedCategories}
              categories={categories}
              showDrawer={showDrawer}
              handleSubmitOrder={this.handleSubmitOrder}
              onDragEnd={this.onDragEnd}
            />
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(CategoriesDrawer);
