import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";

const styles = theme => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);
  return {
    header: {
      backgroundColor: "#1ed1a8",
      minHeight: 200,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      padding: "16px",
      [mediaLg]: {
        marginTop: 16
      }
    },
    headerBox: {
      width: "60%",
      height: "100%",
      minHeight: 200,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row-reverse",
      [mediaLg]: {
        width: "100%"
      }
    },
    headerContent: {
      position: "relative",
      textAlign: "left",
      width: "100%",
      height: "100%",
      [mediaLg]: {
        minWidth: "unset",
        width: "90%"
      }
    },
    title: {
      fontSize: 32,
      fontWeight: 700,
      color: "#ffffff",
      marginBottom: 16,
      [mediaLg]: {
        fontSize: 24
      }
    },
    description: {
      fontSize: 12,
      color: "#ffffff",
    },
    icon: {
      fontSize: 150,
      lineHeight: "150px",
      color: "#ffffff",
      position: "relative",
      left: 0,
      opacity: 0.25,
      textAlign: "left",
      [mediaLg]: {
        width: "unset",
      }
    },
    // Block aligned to Right
    layout1: {
      textAlign: "right",
    },
    // Block centered
    layout2: {
      textAlign: "center",
      "&_icon": {
        textAlign: "center"
      },
    },
    // Block aligned to Left
    layout3: {
      textAlign: "left",
      "&_icon": {
        textAlign: "right"
      },
      "&_headerBox": {
        flexDirection: "row"
      }
    }
  };
};

class CourseBlockHeader extends Component {
  render() {
    const { classes, block } = this.props;
    const layoutClasses = [
      "",
      classes.layout1,
      classes.layout2,
      classes.layout3
    ];
    const layoutClass = layoutClasses[block.layout];

    return (
      <div className={classes.header} style={{ background: block.color }}>
        <div className={classNames(classes.headerBox, `${layoutClass}_headerBox`)}>
          <div className={classNames(classes.headerContent, layoutClass)}>
            <Typography className={classes.title}>{block.title}</Typography>
            <Typography
              className={classNames(classes.description, `${layoutClass}_description`)}
            >
              {block.description}
            </Typography>
          </div>
          <Typography
            className={classNames(classes.icon, `${layoutClass}_icon`)}
          >
            {block.sort}
          </Typography>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CourseBlockHeader);
