import {
  RESET_PASSWORD_NEXT,
  RESET_PASSWORD_BACK,
  RESET_PASSWORD_GET_TOKEN_START,
  RESET_PASSWORD_GET_TOKEN_END,
  RESET_PASSWORD_GET_TOKEN_FAILED,
  RESET_PASSWORD_START,
  RESET_PASSWORD_END,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_RESET,
} from "./index";
import api from '../api/api';

export function resetSteps() {
  return async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_RESET });
  };
}

export function nextStep() {
  return async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_NEXT });
  };
}

export function prevStep() {
  return async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_BACK });
  };
}

export function generateToken(email) {
  return async (dispatch) => {
    try {
      dispatch({type: RESET_PASSWORD_GET_TOKEN_START});
      await api.Auth.resetPassword(email);
      dispatch({type: RESET_PASSWORD_GET_TOKEN_END});
    }catch (e) {
      dispatch({type:   RESET_PASSWORD_GET_TOKEN_FAILED, payload: e});
    }
  };
}

export function resetPassword(email,code,password) {
  return async (dispatch) => {
    try {
      dispatch({type: RESET_PASSWORD_START});
      await api.Auth.changePasswordToken(email,code, password);
      dispatch({type: RESET_PASSWORD_END});
    }catch (e) {
      dispatch({type:   RESET_PASSWORD_FAILED, payload: e});
    }
  };
}
