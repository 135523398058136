import React, { Component } from 'react';
import GenericDialog from '../../components/ui/GenericDialog';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import InfiniteScroll from "react-infinite-scroller";
import Rating from "react-rating";
import SvgIcon from "../ui/SvgIcon";
import UserAvatar from "../ui/UserAvatar";

const styles = () => ({
  content: {
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    margin: 'auto',
    padding: '0 32px'
  },
  star: {
    fontSize: 14
  },

  row:{
    display:'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  leftCol:{
    display: 'flex',
    flexDirection: 'column',
    flex:1
  },
  rightCol:{
    display: 'flex',
    flexDirection: 'column'
  },
  comment:{
    fontSize: 12,
    color:'#707070',
    marginLeft:68
  },
  date:{
    fontSize: 12,
    color:'#9a9a9a',
    marginTop: 18
  },
  userInfo:{
    marginBottom: 8
  },
  userAvatar:{
    float:'left',
  },
  userName:{
    float:'left',
    marginLeft: 16,
    marginRight: 16,
    marginTop:14
  },
  rating:{
    marginTop:18
  },
  totals:{
    minHeight: 50,
    marginTop: 16
  },
  bigStars:{
    fontSize: 24,
    float:'left',
  },
  stars:{
    float:'left'
  },
  ratingsCount:{
    marginLeft: 16,
    color:'#a1a1a1',
    float:'left',
    fontSize: 16,
    marginTop:1
  },
  contentTitle:{
    color:'#777777',
    paddingBottom:11,
    borderBottom:'1px solid #a1a1a1',
    fontSize: 16,
    fontWeight: 700,
  }
});

const initialState = {
  stars: 1,
  comment: ''
};

class CreateReviewDialog extends Component {
  state = { ...initialState };

  resetDialog = () => {
    this.setState({ ...initialState });
  };


  renderReviewItem = (item) => {
    const {classes} = this.props;
    const {user} =  item;
    return <div key={item.id} className={classes.row}>
      <div className={classes.leftCol}>
        <div className={classes.userInfo}>
          <UserAvatar className={classes.userAvatar} mini={true} user={user}/>
          <Typography className={classes.userName}>{`${user.first_name} ${user.last_name}`}</Typography>
          <Rating
              className={classes.rating}
              emptySymbol={
                <SvgIcon
                    iconName={'ic_star_outlined_gray'}
                    className={classes.star}
                />
              }
              fullSymbol={
                <SvgIcon iconName={'ic_star_filled_gray'} className={classes.star} />
              }
              stop={5}
              readonly={true}
              initialRating={parseFloat(item.rating)}
          />
        </div>

        <Typography className={classes.comment}>{item.comment}</Typography>
      </div>
      <div className={classes.rightCol}>
        <Typography className={classes.date}>{item.created_at}</Typography>

      </div>
    </div>
  };

  renderContent = () => {
    const { classes,course, hasMore, loading,items=[],onFetchMore} = this.props;

    return (
      <div className={classes.content}>
        <Typography className={classes.contentTitle}>
          {'VALORACIONES DE LA COMUNIDAD'}
        </Typography>
        <div className={classes.totals}>
          <Rating
              className={classes.stars}
              emptySymbol={
                <SvgIcon
                    iconName={'ic_star_outlined_gray'}
                    className={classes.bigStars}
                />
              }
              fullSymbol={
                <SvgIcon
                    iconName={'ic_star_filled_gray'}
                    className={classes.bigStars}
                />
              }
              stop={5}
              readonly={true}
              initialRating={(parseFloat(course.rating_average))}
          />
          <Typography className={classes.ratingsCount} style={{fontWeight:700}}>
            {parseFloat(course.rating_average).toFixed(1)}
          </Typography>
          <Typography className={classes.ratingsCount}>
            {(course.rates_count === 1)?'1 valoración' : `${course.rates_count} valoraciones`}
          </Typography>

        </div>
        <InfiniteScroll
            pageStart={0}
            loadMore={onFetchMore}
            hasMore={hasMore && !loading}
            loader={<div className="loader" key={0}>Loading ...</div>}
        >
          {items.map((review) => this.renderReviewItem(review)) }
        </InfiniteScroll>
      </div>
    );
  };

  render() {
    const { open, onDismiss,headerColor } = this.props;
    return (
      <GenericDialog miniHeader={true}
        headerColor={headerColor}
        open={open}
        onDismiss={onDismiss}
        name={'VALORACIONES'}
        icon={null}
        title={null}
        onExit={this.resetDialog}
      >
        {this.renderContent()}
      </GenericDialog>
    );
  }
}

export default withStyles(styles)(CreateReviewDialog);
