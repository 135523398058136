import React, { Component } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  text: {
    marginLeft: 24,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontFamily:
      'Open Sans,sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
    fontWeight: 400
  }
};

export default withStyles(styles)(
  class Logout extends Component {
    render() {
      const { onLogoutClicked, text, classes } = this.props;
      return (
        <IconButton onClick={() => onLogoutClicked()} color="inherit">
          <PowerSettingsNew />
          {text && <Typography className={classes.text}>{text}</Typography>}
        </IconButton>
      );
    }
  }
);
