import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {rateCourse} from "../../actions/courses";
import {addSnackbar} from "../../actions/snackbar";
import CreateReviewDialog from '../../components/courses/CreateReviewDialog';
import {SnackbarType} from "../../config/constants";

class CreateReviewDialogContainer extends Component {
  handleSubmit = data => {
    const { onDismiss = () => {},rateCourse,course,addSnackbar} = this.props;
    rateCourse(course.id,data).then(( r)=>{
      addSnackbar("Valoración enviada",SnackbarType.Success);
    }).catch(e => {
    })
    onDismiss();
  };

  render() {
    const { open, onDismiss = () => {},initialRating,headerColor } = this.props;
    return (
      <CreateReviewDialog
          headerColor={headerColor}
        initialRating={initialRating}
        open={open}
        onDismiss={onDismiss}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({rateCourse,addSnackbar}, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateReviewDialogContainer)
);
