import {OCCUPATIONS_READ_START, OCCUPATIONS_READ_END, OCCUPATIONS_READ_FAILED} from "./index";
import api from "../api/api";

export function loadOccupations(){
    return async dispatch => {
        dispatch({ type: OCCUPATIONS_READ_START });
        try {
            const response = await api.withDispatch(dispatch).Occupations.all();
            dispatch({ type: OCCUPATIONS_READ_END, payload:{items: response.data} });
        }catch (e) {
            dispatch({ type: OCCUPATIONS_READ_FAILED, payload:{ message: e} });

        }
    }
}
