import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  createResource,
  updateResource,
  getResource
} from '../../../actions/admin';
import {
  EditInputType,
  SnackbarType
} from '../../../config/constants';
import AdminResourceForm from '../../../components/admin/AdminResourceForm';
import { addSnackbar } from '../../../actions/snackbar';
import {ADMIN_USERS} from '../../../config/routes';
import { goTo } from '../../../actions/navigator';
import validator from 'validator';

class AdminUsersFormContainer extends Component {
  state = {
    isValid: false,
    form: {
      first_name: '',
      last_name: '',
      document: '',
      new_password: null,
      new_password2: null,
    },
    errors: {
      first_name: '',
      last_name: '',
      document: '',
      new_password: '',
      new_password2: '',
    }
  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.onLoadData();
    }
  };

  onLoadData = () => {
    const { type, getResource, params, keyName } = this.props;
    getResource(keyName, type, params.id);
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.item && nextProps.item) {
      const {
        first_name,
        last_name,
        document
      } = nextProps.item;
      this.setState(
        {
          form: {
            first_name: first_name || '',
            last_name: last_name || '',
            document: document || '',
            new_password: '',
            new_password2: '',
          }
        },
        () => {
          this.validateForm();
        }
      );
    }
  };

  onAttributeChange = (name, value) => {
    this.setState({ form: { ...this.state.form, [name]: value } }, () =>
      this.validateForm()
    );
  };

  getColumns = () => {
    const columns = [
      {
        title: 'Nombre',
        source: 'first_name',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'first_name' }
      },
      {
        title: 'Apellido',
        source: 'last_name',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'last_name' }
      },
      {
        title: 'Documento',
        source: 'document',
        editable: true,
        editConfig: { type: EditInputType.Text, name: 'document' }
      },
        {
        title: 'Contraseña',
        source: '',
        editable: true,
        editConfig: { type: EditInputType.Password, name: 'new_password' }
      },
      {
        title: 'Repetir Contraseña',
        source: '',
        editable: true,
        editConfig: { type: EditInputType.Password, name: 'new_password2' }
      },
    ];

    return {
      keys: [
        ...columns,
      ]
    };
  };

  getRequestDataFromForm =  () => {
    const { form } = this.state;
    const data = {
      first_name: form.first_name.trim(),
      last_name: form.last_name.trim(),
      document: form.document.trim(),
      new_password: form.new_password.trim(),
      new_password2: form.new_password.trim(),
    };
    return data;
  };

  onSubmit =  () => {
    const {
      addSnackbar,
      goTo,
      updateResource,
      isEdit,
      item,
      type
    } = this.props;

    const data =  this.getRequestDataFromForm();
    if (isEdit) {
      updateResource(type, item.id, data)
        .then(() => {
          addSnackbar('Usuario editado exitosamente.', SnackbarType.Success);
          goTo(ADMIN_USERS);
        })
        .catch(err => {
          addSnackbar('Error: ' + err.message, SnackbarType.Error);
        });
    }
  };

  validateForm = () => {
    const form = this.state.form;
    let error = false;
    let errors = {
      first_name: '',
      last_name: '',
      document: '',
      new_password: '',
      new_password2: '',
    };

    if (validator.isEmpty(form.first_name.trim())) {
      errors.first_name = 'Debes completar este campo';
      error = true;
    } else if (!validator.isLength(form.first_name.trim(), { min: 3, max: 50 })) {
      errors.first_name = 'Debes completar entre 3 y 50 caracteres.';
      error = true;
    }
    if (validator.isEmpty(form.last_name.trim())) {
      errors.last_name = 'Debes completar este campo';
      error = true;
    } else if (!validator.isLength(form.last_name.trim(), { min: 3, max: 50 })) {
      errors.last_name = 'Debes completar entre 3 y 50 caracteres.';
      error = true;
    }

    if (!validator.isLength(form.document.trim(), { min: 5, max: 15 })) {
      errors.description = 'Debes completar entre 5 y 15 caracteres.';
      error = true;
    }

    if (!validator.isEmpty(form.new_password.trim()) && (form.new_password !== form.new_password2)) {
      errors.new_password2 = 'Las contraseñas no coinciden';
      error = true;
    }

    this.setState({ errors: errors, isValid: !error });
    return !error;
  };

  onGoBack = () => {
    this.props.goTo(ADMIN_USERS);
  };

  render() {
    const { loading, title } = this.props;
    return (
      <AdminResourceForm
        keyName={'admin-users-form'}
        title={title}
        form={this.state.form}
        isValid={this.state.isValid}
        errors={this.state.errors}
        loading={loading}
        attributes={this.getColumns()}
        onSubmit={this.onSubmit}
        onAttributeChange={this.onAttributeChange}
        onGoBack={this.onGoBack}
      />
    );
  }
}

AdminUsersFormContainer.defaultProps = {
  type: 'users'
};

const mapStateToProps = (state, ownProps) => {
  const states = state.admin.resource;
  return {
    ...states[ownProps.keyName]
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getResource, createResource, updateResource, addSnackbar, goTo },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersFormContainer);
