import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {Link} from '@material-ui/core';

const styles = theme => {
  const mediaSm = theme.breakpoints.down(600);
  return {
    linksContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 700,
      alignContent: 'center',
      margin: '16px  auto',
      [mediaSm]:{
        flexDirection: 'column'
      }
    },

    link: {
      fontSize: 13,
      marginRight: 16,
      marginLeft: 16,
      color: '#ffffff',
      wordBreak: 'keep-all',
      textAlign: 'center',
      [mediaSm]:{
        paddingBottom: 8
      }
    },
  };
};

class PreLoginLinks extends Component {
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.linksContainer}>
        <Link
          className={classes.link}
          target={'_blank'}
          rel={'noopener noreferrer'}
          href={
            process.env.REACT_APP_BASE_URL +
            'pdf/AcademiaSalud-PoliticaCookies.pdf'
          }>
          {'POLÍTICA DE COOKIES'}
        </Link>
        <Link
          className={classes.link}
          target={'_blank'}
          rel={'noopener noreferrer'}
          href={
            process.env.REACT_APP_BASE_URL +
            'pdf/AcademiaSalud-ProteccionDatos.pdf'
          }>
          {'POLÍTICA DE PROTECCIÓN DE DATOS'}
        </Link>
        <Link
          className={classes.link}
          target={'_blank'}
          rel={'noopener noreferrer'}
          href={
            process.env.REACT_APP_BASE_URL + 'pdf/AcademiaSalud-AvisoLegal.pdf'
          }>
          {'AVISO LEGAL'}
        </Link>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(PreLoginLinks));
