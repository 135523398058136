import {
  SEARCH_REQUEST_START,
  SEARCH_REQUEST_END,
  SEARCH_REQUEST_FAILED,
  SEARCH_REQUEST_CHANGE_QUERY,
  SEARCH_REQUEST_INIT,
  SEARCH_REQUEST_CLEAR,
  CREATE_REQUEST_START,
  CREATE_REQUEST_END,
  CREATE_REQUEST_FAILED
} from './index';
import api from '../api/api';

export function init(key) {
  return async dispatch => {
    dispatch({ type: SEARCH_REQUEST_INIT, key: key });
  };
}
export function clear(key) {
  return async dispatch => {
    dispatch({ type: SEARCH_REQUEST_CLEAR, key: key });
  };
}

export function changeQuery(key, query) {
  return async dispatch => {
    dispatch({
      type: SEARCH_REQUEST_CHANGE_QUERY,
      key: key,
      payload: { query: query }
    });
  };
}

function onFetch(key, query, request, min = 3, extraData = []) {
  return async dispatch => {
    dispatch({
      type: SEARCH_REQUEST_START,
      key: key,
      payload: { query: query }
    });
    try {
      let response = { data: [] };
      if (query && query.trim().length >= min) {
        response = await request(dispatch);
      }
      dispatch({
        type: SEARCH_REQUEST_END,
        key: key,
        payload: { items: [...extraData, ...response.data], query: query }
      });
    } catch (e) {
      dispatch({ type: SEARCH_REQUEST_FAILED, key: key, payload: e });
    }
  };
}

function onCreate(key, value, request, min = 3, extraData = []) {
  return async dispatch => {
    dispatch({
      type: CREATE_REQUEST_START,
      key: key,
      payload: { query: value }
    });
    try {
      let response = { data: [] };
      if (value && value.trim().length >= min) {
        response = await request(dispatch);
      }
      dispatch({
        type: CREATE_REQUEST_END,
        key: key,
        payload: { items: [...extraData, response.data], query: value }
      });
    } catch (e) {
      dispatch({ type: CREATE_REQUEST_FAILED, key: key, payload: e });
    }
  };
}

export function searchTags(key, query) {
  return onFetch(key, query, dispatch => {
    return api.withDispatch(dispatch).Filter.tags(query);
  });
}

export function searchCategories(key, query) {
  return onFetch(key, query, dispatch => {
    return api.withDispatch(dispatch).Filter.categories(query);
  });
}

export function searchUsers(key, query) {
  return onFetch(key, query, dispatch => {
    return api.withDispatch(dispatch).Filter.users(query);
  });
}

export function searchCourses(key, query) {
  return onFetch(key, query, dispatch => {
    return api.withDispatch(dispatch).Filter.courses(query);
  });
}

export function createTags(key, value) {
  return onCreate(key, value, dispatch =>
    api.withDispatch(dispatch).Create.tags(value)
  );
}

export function createCategories(key, value) {
  return onCreate(key, value, dispatch =>
    api.withDispatch(dispatch).Create.categories(value)
  );
}
