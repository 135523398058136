import React, { Component } from 'react';
import AdminContentWrapper from '../../containers/admin/AdminContentWrapperContainer';
import AdminResourceTable from './AdminResourceTable';
import AdminFilters from './inputs/AdminFilters';
import ResourcesPaginator from '../../containers/admin/ResourcesPaginator';
import Loading from '../ui/Loading';
import ColorButton from '../../components/ui/ColorButton';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/Reorder';
import DownloadIcon from '@material-ui/icons/GetApp';

class AdminResourceIndex extends Component {
  handleDownload = () => {
    const { onDownload, filters } = this.props;
    return filters ? onDownload(Object.keys(filters)) : filters;
  };

  render() {
    const {
      keyName,
      children,
      items,
      loading,
      getActions,
      columns,
      title,
      customTop,
      customBottom,
      onFetch,
      onFiltersChange,
      filters,
      getFilterConfig,
      onCreate = null,
      sort,
      forceNoActions,
      onDownload
    } = this.props;

    return (
      <AdminContentWrapper>
        <div style={{ minHeight: 45, display: 'flex' }}>
          <h1
            style={{
              float: 'left',
              marginLeft: 16,
              display: 'flex',
              flexGrow: 1
            }}
          >
            {title}
          </h1>
          {sort && (
            <div
              style={{ float: 'right', height: 56, margin: '24px 16px 0 0' }}
            >
              <ColorButton
                color="#868686"
                label="Ordenar"
                onClick={sort}
                extraView={
                  <ListIcon style={{ height: 20, width: 20, marginTop: 2 }} />
                }
              />
            </div>
          )}
          {onCreate != null ? (
            <div style={{ float: 'right', height: 56, marginTop: 24 }}>
              <ColorButton
                color={'#3abf91'}
                label={'Nuevo'}
                onClick={onCreate}
                extraView={
                  <AddIcon style={{ height: 20, width: 20, marginTop: 2 }} />
                }
              />
            </div>
          ) : null}
          {onDownload != null ? (
            <div style={{ float: 'right', height: 56, marginTop: 24 }}>
              <ColorButton
                color={'#3abf91'}
                label={'Descargar'}
                onClick={this.handleDownload}
                extraView={
                  <DownloadIcon
                    style={{ height: 20, width: 20, marginTop: 2 }}
                  />
                }
              />
            </div>
          ) : null}
        </div>
        {[
          <div key={'customTop'}>{customTop}</div>,
          <AdminFilters
            key={'filters'}
            keyName={keyName}
            filters={filters}
            onFiltersChange={onFiltersChange}
            getFilterConfig={getFilterConfig}
          />,
          loading ? (
            <Loading dark={true} key={'loading-admin-index'} />
          ) : (
            <AdminResourceTable
              key={'resource-table'}
              items={items}
              columns={columns}
              getActions={getActions}
              forceNoActions={forceNoActions}
            />
          ),
          <div key={'customBottom'}>{customBottom}</div>
        ]}
        {onFetch && <ResourcesPaginator keyName={keyName} onFetch={onFetch} />}
        {[children]}
      </AdminContentWrapper>
    );
  }
}

export default AdminResourceIndex;
