import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getResources, downloadReportSurveys } from '../../../actions/admin';
import AdminResourceIndex from '../AdminResourceIndex';
import {AgeRange, CourseProgress, EditInputType, FiltersFormat, States, WorkAreas} from "../../../config/constants";

const KEY_NAME = 'surveys';

class AdminSurveysReportsContainer extends Component {
  onFetch = (keyName, query, page, size) => {
    const { type, getResources } = this.props;
    getResources(type, keyName, query, page, size);
  };

  getColumns = () => {
    return [
      { title: 'Origen', source: 'state' },
      { title: 'Rango etario', source: 'age_range' },
      { title: 'Lugar de trabajo', source: 'work_areas' },
      { title: 'Temática  para aprender (1)', source: 'area_of_interest1' },
      { title: 'Temática  para aprender (2)', source: 'area_of_interest2' },
      { title: 'Temática  para aprender (3)', source: 'area_of_interest3' },
      { title: 'Fecha', source: 'created' },
    ];
  };

  getFilterConfig = () => {
    return [
      {
        type: EditInputType.Select,
        name: 'survey.state',
        placeholder: 'Origen',
        format: FiltersFormat.Like,
        options: States.map(s => ({
          id: s.description,
          description: s.description
        }))
      },
      {
        type: EditInputType.Select,
        name: 'survey.ageRange',
        placeholder: 'Rango etario',
        format: FiltersFormat.Like,
        options: AgeRange.map(s => ({
          id: s.description,
          description: s.description
        }))
      },
      {
        type: EditInputType.Text,
        name: 'survey.workAreas',
        placeholder: 'Lugar de trabajo',
        format: FiltersFormat.Like,
      },
      {
        type: EditInputType.Text,
        fields:['areaOfInterest1', 'areaOfInterest2', 'areaOfInterest3'],
        name: 'areaOfInterest',
        placeholder: 'Temática',
        fsModel: 'survey',
        format: FiltersFormat.FullSearch,
      }
    ];
  };

  onDownload = () => {
    const {
      downloadReportSurveys,
      paginator: { query }
    } = this.props;
    downloadReportSurveys(query);
  };

  render() {
    return (
      <AdminResourceIndex
        keyName={KEY_NAME}
        title={'REPORTES'}
        getFilterConfig={this.getFilterConfig}
        columns={this.getColumns()}
        onFetch={this.onFetch}
        forceNoActions
        onDownload={this.onDownload}
      />
    );
  }
}

AdminSurveysReportsContainer.defaultProps = {
  type: 'reports/surveys'
};

const mapStateToProps = (state, ownProps) => {
  const paginatorState = state.paginatorReducer;
  return {
    paginator: { ...paginatorState[KEY_NAME] }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getResources, downloadReportSurveys }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSurveysReportsContainer);
