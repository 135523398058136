import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import {withStyles} from '@material-ui/core/styles';
import validator from 'validator';
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';
import ViewSlider from 'react-view-slider';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles/index';
import {
  Button,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import config from '../../config/index';
import Loading from '../ui/Loading';
import PreLoginLinks from '../session/PreLoginLinks';
import {
  AgeRange,
  OtherWorkAreaId,
  States,
  WorkAreas,
} from '../../config/constants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#e2001a',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: 16,
        color: '#ffffff',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 400,
        color: '#ffffff',
      },

      focused: {
        color: '#ffffff',
      },
    },
    MuiInputBase: {
      input: {
        color: '#fff',
      },
      root: {
        color: '#fff',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 400,
        borderBottom: '1px solid rgba(255, 255, 255,0.7)',
      },
      disabled: {
        color: '#fff',
      },
      focused: {
        borderBottom: '1px solid rgba(255, 255, 255,0.7)',
      },
    },
    MuiIconButton: {
      root: {
        color: '#fff',
      },
    },
    MuiMobileStepper: {
      root: {
        flexDirection: 'column',
        backgroundColor: '#00000000',
        paddingTop: 50,
      },
      dotActive: {
        backgroundColor: '#fff',
        opacity: 1,
      },
      dot: {
        backgroundColor: '#fff',
        opacity: 0.44,
      },
    },
    MuiButton: {
      disabled: {
        color: '#ffffff55 !important',
      },
    },
  },
});

const styles = (theme) => {
  const mediaLg = theme.breakpoints.down(theme.mediaQueries.lg);

  return {
    root: {
      overflowY: 'auto',
      position: 'relative',
      height: '100vh',
      backgroundSize: 'cover',
      background: `no-repeat center url("${config.BASE_URL}img/ac_bg_home_short.jpg")`,
      minHeight: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoAc: {
      width: '70%',
      margin: 'auto',
      maxWidth: 357,
    },
    content: {
      width: '100%',
      height: '100%',
      margin: 'auto',
      maxWidth: 700,
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    lateralImage: {
      left: 0,
      position: 'absolute',
      minHeight: '100%',
    },

    title: {
      textAlign: 'center',
      paddingTop: 24,
      paddingBottom: 24,
      color: '#fff',
      fontFamily: 'Open Sans',
      fontSize: 34,
      fontWeight: 400,
    },
    subtitle: {
      wordBreak: 'break-word',
      width: '80%',
      textAlign: 'center',
      margin: 'auto',
      paddingBottom: 24,
      color: '#fff',
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 400,
      whiteSpace: 'normal',
    },

    buttons: {
      margin: 'auto',
      width: 300,
    },
    buttonRow: {
      height: 50,
      width: '100%',
      float: 'left',
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      color: '#ffffff',
      fontSize: 16,
      minWidth: 180,
      marginRight: theme.spacing.unit,
      marginTop: theme.spacing.unit,
    },
    backButton: {
      marginRight: theme.spacing.unit,
    },
    completed: {
      display: 'inline-block',
    },
    stepContent: {
      minHeight: '100%',
      margin: '32px 20%',
      textAlign: 'center',
    },
    instructions: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    formItem: {
      width: '100%',
    },
    center: {
      textAlign: 'center',
    },

    loader: {
      textAlign: 'center',
      minHeight: 250,
    },

    errors: {
      marginTop: 16,
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: '400',
      color: '#f90000',
    },

    slide: {
      width: '100%',
      height: '100%',
      maxWidth: 700,
    },
    exitButton: {
      position: 'absolute',
      right: 60,
      top: 30,
    },
    stepperContent: {
      marginTop: -30,
    },
  };
};

class SurveyStepper extends React.Component {
  initialState = {
    state: '',
    ageRange: '',
    workAreas: [],
    otherWorkArea: '',
    areaOfInterest1: '',
    areaOfInterest2: '',
    areaOfInterest3: '',
    canContinue: true,
    validForm: false,
    selectIsOpen: false,
    errors: {
      state: '',
      ageRange: '',
      workAreas: '',
      otherWorkArea: '',
      areaOfInterest1: '',
      areaOfInterest2: '',
      areaOfInterest3: '',
    },
  };

  state = this.initialState;

  getStepContent = (activeStep) => {
    const {classes, loading, error} = this.props;
    if (loading) {
      return (
        <div className={classes.loader}>
          <Loading height={40} />
        </div>
      );
    }
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.slide}>
            <Typography className={classes.title}>
              Ayudanos a conocerte mejor
            </Typography>
            <Typography className={classes.subtitle}>
              {
                'En Academia estamos diseñando nuevos modelos de aprendizaje. Por eso te pedimos 2 minutos para que nos ayudes a conocerte mejor y saber cuáles son las propuestas que pueden resultarte más interesantes e innovadoras.'
              }
            </Typography>
          </div>
        );
      case 1:
        return (
          <div className={classes.slide}>
            <form>
              {loading ? null : error && typeof error === 'string' ? (
                <Typography className={classes.errors}>{error}</Typography>
              ) : null}
              <FormGroup>
                <FormControl className={classes.formItem}>
                  <InputLabel htmlFor="state">Contanos de dónde sos</InputLabel>
                  <Select
                    name="state"
                    multiple={false}
                    value={this.state.state}
                    placeholder={''}
                    onChange={(e) => this.handleChange(e)}
                    options={States}
                    renderValue={(value) => {
                      return value != null && value !== '' ? value : '';
                    }}>
                    {States.map((option) => (
                      <MenuItem
                        key={'menu-option-' + option.id}
                        value={option.description}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={true}>
                    {this.state.errors.state}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formItem}>
                  <InputLabel htmlFor="ageRange">
                    ¿Cuál es tu rango etario?
                  </InputLabel>
                  <Select
                    name="ageRange"
                    multiple={false}
                    value={this.state.ageRange}
                    placeholder={''}
                    onChange={(e) => this.handleChange(e)}
                    options={AgeRange}
                    renderValue={(value) => {
                      return value != null && value !== '' ? value : '';
                    }}>
                    {AgeRange.map((option) => (
                      <MenuItem
                        key={'menu-option-' + option.id}
                        value={option.description}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={true}>
                    {this.state.errors.ageRange}
                  </FormHelperText>
                </FormControl>

                <FormControl className={classes.formItem}>
                  <InputLabel htmlFor="workAreas">
                    ¿Cuál es tu lugar de trabajo?
                  </InputLabel>
                  <Select
                    name="workAreas"
                    multiple={true}
                    value={this.state.workAreas}
                    placeholder={''}
                    onChange={(e) => this.handleChange(e)}
                    options={WorkAreas}
                    renderValue={(value) => {
                      return WorkAreas.filter((o) => value.includes(o.id))
                        .map((o) => o.description)
                        .join(', ');
                    }}
                    open={this.state.selectIsOpen}
                    onOpen={() => this.handleSelectIsOpen(true)}
                    onClose={() => this.handleSelectIsOpen(false)}>
                    {WorkAreas.map((option) => (
                      <MenuItem
                        key={'menu-option-' + option.id}
                        value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={true}>
                    {this.state.errors.workAreas}
                  </FormHelperText>
                </FormControl>
                {this.state.workAreas.includes(OtherWorkAreaId) && (
                  <FormControl className={classes.formItem}>
                    <InputLabel htmlFor="otherWorkArea">
                      Contanos cuál/es son los otros lugares de trabajo
                    </InputLabel>
                    <Input
                      name="otherWorkArea"
                      disableUnderline={true}
                      value={this.state.otherWorkArea}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <FormHelperText error={true}>
                      {this.state.errors.otherWorkArea}
                    </FormHelperText>
                  </FormControl>
                )}

                <Typography className={classes.subtitle}>
                  {
                    'Aprender es un valor que llevamos dentro. ¿Cuáles son las temáticas sobre las que te gustaría aprender?'
                  }
                </Typography>
                <FormControl className={classes.formItem}>
                  <InputLabel htmlFor="areaOfInterest1">Tema 1</InputLabel>
                  <Input
                    multiline={true}
                    rowsMax={5}
                    name="areaOfInterest1"
                    disableUnderline={true}
                    value={this.state.areaOfInterest1}
                    onChange={(e) => this.handleChange(e)}
                  />
                  <FormHelperText error={true}>
                    {this.state.errors.areaOfInterest1}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formItem}>
                  <InputLabel htmlFor="areaOfInterest2">Tema 2</InputLabel>
                  <Input
                    multiline={true}
                    rowsMax={5}
                    name="areaOfInterest2"
                    disableUnderline={true}
                    value={this.state.areaOfInterest2}
                    onChange={(e) => this.handleChange(e)}
                  />
                  <FormHelperText error={true}>
                    {this.state.errors.areaOfInterest2}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formItem}>
                  <InputLabel htmlFor="areaOfInterest3">Tema 3</InputLabel>
                  <Input
                    multiline={true}
                    rowsMax={5}
                    name="areaOfInterest3"
                    disableUnderline={true}
                    value={this.state.areaOfInterest3}
                    onChange={(e) => this.handleChange(e)}
                  />
                  <FormHelperText error={true}>
                    {this.state.errors.areaOfInterest3}
                  </FormHelperText>
                </FormControl>
              </FormGroup>
            </form>
          </div>
        );
      case 2:
        return error ? (
          <div className={classes.slide}>
            <Typography className={classes.title}>
              No hemos podido enviar tus respuestas
            </Typography>
            <Typography className={classes.subtitle}>
              {
                'Hubo un problema y no hemos podido completar tu encuesta, hacé clic en "Reintentar" para volver a comenzar.'
              }
            </Typography>
          </div>
        ) : (
          <div className={classes.slide}>
            <Typography className={classes.title}>¡Muchas gracias!</Typography>
            <Typography className={classes.subtitle}>
              {'Te invitamos a seguir aprendiendo en Academia Salud.'}
            </Typography>
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  handleSkip = () => {
    if (!this.props.loading) {
      this.props.onSkip();
    }
  };

  handleReset = () => {
    if (!this.props.loading) {
      this.setState(this.initialState, () => {
        this.props.onReset();
      });
    }
  };

  handleSendSurvey = () => {
    if (!this.props.loading) {
      const {
        state,
        ageRange,
        workAreas,
        otherWorkArea,
        areaOfInterest1,
        areaOfInterest2,
        areaOfInterest3,
      } = this.state;
      let workAreasArray = workAreas
        .filter((wa) => wa !== OtherWorkAreaId)
        .map((wa) => {
          return WorkAreas.find((w) => w.id === wa).description;
        });
      if (workAreas.includes(OtherWorkAreaId)) {
        workAreasArray.push(`Otros (${otherWorkArea})`);
      }

      this.props.onSaveSurvey({
        state,
        ageRange,
        workAreas: workAreasArray.join(', '),
        areaOfInterest1,
        areaOfInterest2,
        areaOfInterest3,
      });
    }
  };

  handleExit = () => {
    if (!this.props.loading) {
      this.props.onBackToHome();
    }
  };

  handleChange = (event) => {
    const newState = {[event.target.name]: event.target.value};
    if (event.target.name === 'workAreas') {
      if (!event.target.value.includes(OtherWorkAreaId)) {
        newState['otherWorkArea'] = '';
      } else {
        if (!this.state.workAreas.includes(OtherWorkAreaId)) {
          newState['selectIsOpen'] = false;
        }
      }
    }
    this.setState(newState, () => {
      this.validateForm();
    });
  };

  handleSelectIsOpen = (isOpen) => {
    this.setState({...this.state, selectIsOpen: isOpen});
  };

  validateForm = () => {
    let isValid = true;
    const errors = {...this.initialState.errors};
    const {
      state,
      ageRange,
      workAreas,
      otherWorkArea,
      areaOfInterest1,
      areaOfInterest2,
      areaOfInterest3,
    } = this.state;

    if (validator.isEmpty(state)) {
      isValid = false;
      errors.state = 'Debes seleccionar una opción';
    }
    if (validator.isEmpty(ageRange)) {
      isValid = false;
      errors.ageRange = 'Debes seleccionar una opción';
    }
    if (workAreas.length === 0) {
      isValid = false;
      errors.workAreas = 'Debes seleccionar una opción';
    } else if (workAreas.includes(OtherWorkAreaId)) {
      if (!validator.isLength(otherWorkArea, {min: 1, max: 256})) {
        isValid = false;
        errors.otherWorkArea =
          'Debes completar este campo. Máximo 256 caracteres.';
      }
    }

    if (!validator.isLength(areaOfInterest1, {min: 1, max: 500})) {
      isValid = false;
      errors.areaOfInterest1 =
        'Debes completar este campo. Máximo 500 caracteres.';
    }

    if (!validator.isLength(areaOfInterest2, {min: 0, max: 500})) {
      isValid = false;
      errors.areaOfInterest2 =
        'Debes completar este campo. Máximo 500 caracteres.';
    }

    if (!validator.isLength(areaOfInterest3, {min: 0, max: 500})) {
      isValid = false;
      errors.areaOfInterest3 =
        'Debes completar este campo. Máximo 500 caracteres.';
    }

    this.setState({validForm: isValid, errors: errors});
  };

  renderView = ({
    index,
    key,
    ref,
    style,
    className,
    active,
    transitionState,
  }) => {
    const {classes, error} = this.props;
    let activeStep = index;
    return (
      <div
        key={key}
        ref={ref}
        className={className}
        style={{...style, marginBottom: 100}}>
        <IconButton
          className={classes.exitButton}
          color={'primary'}
          onClick={() => this.handleExit()}
          label={'SALIR'}>
          <ClearIcon />
        </IconButton>
        <div>
          <div className={classes.stepContent}>
            {this.getStepContent(activeStep)}
          </div>
          {activeStep === 0 && (
            <div className={classes.buttons}>
              <div className={classes.buttonRow}>
                <Button
                  className={classes.button}
                  disabled={!this.state.canContinue}
                  onClick={() => this.handleSkip()}>
                  {'CONTINUAR'}
                </Button>
              </div>
            </div>
          )}
          {activeStep === 1 && (
            <div className={classes.buttons}>
              <div className={classes.buttonRow}>
                <Button
                  className={classes.button}
                  disabled={!this.state.validForm}
                  onClick={() => this.handleSendSurvey()}>
                  {'FINALIZAR'}
                </Button>
              </div>
            </div>
          )}
          {activeStep === 2 && (
            <div className={classes.buttons}>
              <div className={classes.buttonRow}>
                {error ? (
                  <Button
                    className={classes.button}
                    onClick={() => this.handleReset()}>
                    {'REINTENTAR'}
                  </Button>
                ) : (
                  <Button
                    className={classes.button}
                    onClick={() => this.handleExit()}>
                    {'CERRAR'}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {classes, activeStep} = this.props;
    return (
      <>
        <div className={classes.root}>
          <div className={classes.content}>
            <img
              src={config.BASE_URL + 'img/ic_banner_academia_2.png'}
              alt="logo banner"
              className={classes.logoAc}
            />
            <MuiThemeProvider theme={theme}>
              <MobileStepper
                activeStep={activeStep}
                steps={3}
                variant={'dots'}
                position={'static'}
                backButton={null}
                nextButton={null}
              />
              <div style={{height: '100%', paddingBottom: 100}}>
                <ViewSlider
                  renderView={this.renderView}
                  numViews={3}
                  activeView={activeStep}
                  animateHeight={false}
                  style={{position: 'relative'}}
                  className={classes.stepperContent}
                />
              </div>
            </MuiThemeProvider>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 10,
            width: '100%',
            textAlign: 'center',
          }}>
          <PreLoginLinks />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SurveyStepper);
