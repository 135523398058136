import {
    COURSE_READ_END,
    COURSE_READ_FAILED,
    COURSE_READ_START
} from '../actions';

let initialState = {
    error: null,
    loading: false,
    course: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COURSE_READ_END:
            const { course } = action.payload;
            return {
                loading: false,
                course: course,
                error: null
            };
        case COURSE_READ_START:
            return {
                course: null,
                error: null,
                loading: true
            };
        case COURSE_READ_FAILED:
            const { message } = action.payload;
            return {
                course: null,
                error: message,
                loading: false
            };
        default:
            return { ...state, error: null };
    }
};
