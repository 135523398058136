import {
  SEARCH_REQUEST_CLEAR,
  SEARCH_REQUEST_FAILED,
  SEARCH_REQUEST_START,
  SEARCH_REQUEST_END,
  SEARCH_REQUEST_CHANGE_QUERY,
  SEARCH_REQUEST_INIT
} from '../actions';

let initialState = {
  query: '',
  items: [],
  key: 'default'
};

let states = {};

function getStateFor(key) {
  if (states[key] !== undefined) {
    return states[key];
  } else {
    states[key] = { ...initialState, key: key };
    return states[key];
  }
}

export default (state = initialState, action) => {
  const key = action.key || state.key || 'default';
  const currentState = getStateFor(key);
  switch (action.type) {
    case SEARCH_REQUEST_INIT:
      states[key] = {
        ...initialState,
        key: key
      };
      return states;
    case SEARCH_REQUEST_CLEAR:
      states[key] = {
        ...currentState,
        query: '',
        items: []
      };
      return states;
    case SEARCH_REQUEST_FAILED:
      states[key] = {
        ...currentState,
        items: [],
        query: ''
      };
      return states;
    case SEARCH_REQUEST_START:
      const { query } = action.payload;
      states[key] = {
        ...currentState,
        items: [],
        query: query
      };
      return states;

    case SEARCH_REQUEST_CHANGE_QUERY: {
      const { query } = action.payload;
      states[key] = {
        ...currentState,
        items: [],
        query: query,
        error: null,
        loading: false
      };
      return states;
    }
    case SEARCH_REQUEST_END: {
      const { query, items } = action.payload;
      if (query === state[key].query) {
        states[key] = {
          ...currentState,
          items: items
        };
      } else {
        states[key] = { ...currentState };
      }
      return states;
    }
    default:
      states[key] = { ...currentState };
      return states;
  }
};
