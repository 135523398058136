import React, { Component } from 'react';
import {Dialog,AppBar,Toolbar,IconButton,Slide} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  appBar: {
    position: 'relative'
  },
  close: {
    position: 'absolute',
    right: 16
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class FullScreenDialog extends Component {
  render() {
    const { children, onClose, open, classes } = this.props;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              className={classes.close}
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {children}
      </Dialog>
    );
  }
}

export default withStyles(styles)(FullScreenDialog);
