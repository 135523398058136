import { combineReducers } from 'redux';
import userReducer from './userReducer';
import courseReducer from './courseReducer';
import categoryReducer from './categoryReducer';
import menuDrawerReducer from './menuDrawerReducer';
import editRowReducer from './editRowReducer';
import paginatorReducer from './paginatorReducer';
import infiniteScrollPaginatorReducer from './infiniteScrollPaginatorReducer';
import lazySelectInputReducer from './lazySelectInputReducer';
import snackbarMessageReducer from './snackbarMessageReducer';
import createRequestReducer from './createRequestReducer';
import searchRequestReducer from './searchRequestReducer';
import adminResourceReducer from './admin/adminResourceReducer';
import recommendedCoursesReducer from './courses/recommendedCoursesReducer';
import requiredCoursesReducer from './courses/requiredCoursesReducer';
import searchCoursesReducer from './courses/searchCoursesReducer';
import categoriesReducer from './categories/categoriesReducer';
import featuredCategoriesReducer from './categories/featuredCategoriesReducer';
import myFinishedCoursesReducer from './me/myFinishedCoursesReducer';
import myInProgressCoursesReducer from './me/myInProgressCoursesReducer';
import resetPasswordReducer from './resetPasswordReducer';
import registerUserReducer from './registerUserReducer';
import occupationsReducer from './occupationsReducer';
import surveyReducer from './surveyReducer';

// Hey, add all application reducers here!
const mainReducer = combineReducers({
  userReducer,
  courseReducer,
  categoryReducer,
  featuredCategoriesReducer,
  categoriesReducer,
  menuDrawerReducer,
  editRowReducer,
  paginatorReducer,
  lazySelectInputReducer,
  snackbarMessageReducer,
  searchRequestReducer,
  createRequestReducer,
  resetPasswordReducer,
  registerUserReducer,
  infiniteScrollPaginatorReducer,
  occupationsReducer,
  surveyReducer,
  courses: combineReducers({
    required: requiredCoursesReducer,
    recommended: recommendedCoursesReducer,
    search: searchCoursesReducer
  }),
  admin: combineReducers({ resource: adminResourceReducer }),
  me: combineReducers({
    inProgress: myInProgressCoursesReducer,
    finished: myFinishedCoursesReducer
  })
});

export default mainReducer;
