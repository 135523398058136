import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import api from "../../../api/api";

const warningColor = '#ffcdcd';

const styles = {
  container: {
    height: '16em',
    minWidth: '16em',
    background: '#efefef',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1em',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      background: '#dcdcdc'
    }
  },
  mainImage: {
    height: '100%',
    minWidth: '100%',
    background: '#efefef',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '&:hover': {
      background: '#dcdcdc'
    }
  },
  dropzone: {
    width: '100%',
    height: '100%'
  },
  thumbsContainer: {
    justifyContent: 'center',
    marginTop: '1em',
    display: 'flex',
    cursor: 'default',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%'
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 5,
    border: '1px solid #dcdcdc',
    background: '#fff',
    marginBottom: '1em',
    marginRight: '1em',
    width: '8em',
    height: '8em',
    padding: '.4em',
    boxSizing: 'border-box',
    boxShadow: '2px 2px 5px #dedede',
    position: 'relative'
  },
  handleRemoveFile: {
    position: 'absolute',
    height: '2em',
    width: '2em',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '.5em',
    top: '.5em',
    fontSize: '1em',
    cursor: 'pointer',
    color: '#fff'
  },
  thumbInner: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '5px',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
};

const generatePreviewProps = file => {
  return typeof file === 'string'
    ? { src: file, name: file }
    : { ...file, src: URL.createObjectURL(file) };
};

const VideoPreview = ({ controls, height, width, src }) => (
  <video
    preload="metadata"
    controls={controls || false}
    width={width || '200'}
    height={height || '200'}
  >
    <source src={src} />
  </video>
);

const AudioPreview = ({ controls, src }) => (
  <audio preload="metadata" controls={controls || false}>
    <source src={src} />
  </audio>
);

const ImagePreview = withStyles(styles)(({ src, classes }) => (
  <div
    className={classes.thumbInner}
    style={{ backgroundImage: `url(${src})` }}
  />
));



export const Preview = withStyles(styles)(
  ({ remove, contentFile, classes, mainImage,...mediaProps }) => {
    const file = { ...generatePreviewProps(contentFile) };
    //TODO: REVISAR ESTO, contentFile.type siempre undefined, resuelve por el último else
    const PreviewComponent = /^video\/+/g.test(contentFile.type)
      ? VideoPreview
      : /^audio\/+/g.test(contentFile.type)
      ? AudioPreview
      : ImagePreview;
    return (
      <div className={mainImage ? classes.mainImage : classes.thumb}>
        <PreviewComponent {...mediaProps} src={api.signUrl(file.src)} />
        <CloseIcon className={classes.handleRemoveFile} onClick={() => remove(file)} />
      </div>
    );
  }
);

export const ThumbsList = withStyles(styles)(
  ({ classes, handleRemoveFile, files }) => (
    <div className={classes.thumbsContainer}>
      {files ? (
        <React.Fragment>
          {Array.isArray(files) ? (
            files.map(file => (
              <Preview
                contentFile={file}
                remove={handleRemoveFile}
                key={file.name}
              />
            ))
          ) : (
            <Preview
              contentFile={files}
              remove={handleRemoveFile}
              key={files.name}
            />
          )}
        </React.Fragment>
      ) : null}
    </div>
  )
);

const Dropzone = ({
  dropDetail,
  text,
  accept,
  classes,
  customClasses,
  icon,
  onDrop,
  onRemove,
  maxSize,
  currentFiles,
  multiple
}) => {
  const dropzoneProps = {
    onDrop,
    onRemove,
    accept: accept ? accept : ['*/*'],
    maxSize: 1048576 * (maxSize ? maxSize : 5), // 5MB by default
    multiple: multiple
  };
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone(
    dropzoneProps
  );

  return (
    <React.Fragment>
      {currentFiles ? (
        <div
          className={`${classes.container}
          ${customClasses &&
            customClasses.dropzoneContainer &&
            customClasses.dropzoneContainer}`
          }
        >
          <Preview
            contentFile={ Array.isArray(currentFiles) ? currentFiles[0] : currentFiles }
            remove={onRemove}
            mainImage
          />
        </div>
      ) : (
        <div
          style={{ backgroundColor: isDragReject && warningColor }}
          className={`${classes.container}
          ${customClasses &&
            customClasses.dropzoneContainer &&
            customClasses.dropzoneContainer}`}
          {...getRootProps()}
        >
          <React.Fragment>
            <input className={classes.dropzone} {...getInputProps()} />
            {isDragActive ? (
                isDragReject
                ? <React.Fragment>
                    <WarningIcon />
                    <Typography>Formato de archivo no permitido</Typography>
                  </React.Fragment>
                : <Typography>Suelte aquí sus archivos...</Typography>
            ) : (
              <React.Fragment>
                {icon && icon}
                <Typography>
                  {text
                    ? text
                    : `Arrastra una foto hasta aquí o haz click para subir`}
                </Typography>
                <Typography>{dropDetail ? dropDetail : ''}</Typography>
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      )}
      {currentFiles && Array.isArray(currentFiles) && <ThumbsList files={currentFiles} handleRemoveFile={onRemove} />}
    </React.Fragment>
  );
};

export default withStyles(styles)(Dropzone);
